import { ClassConstructor, Expose, Type } from 'class-transformer';

export interface PropertyItemMeta {
    readonly key?: PropertyKey;
    name?: string;
    description?: string;
    type?: 'string' | 'boolean' | 'number' | 'array' | 'options' | 'object';
    required?: boolean;
    options?: string[];
    optionsKey?: string;
    arrayType?: ClassConstructor<any>,
}

export const Meta = (itemMeta: PropertyItemMeta) =>
    (target: any, key: PropertyKey) => {
        (itemMeta as any).key = key;
        if (!itemMeta.type) {
            itemMeta.type = 'string';
        }

        let __meta__ = target.__meta__;
        if (!__meta__) {
            __meta__ = {};
            target.__meta__ = __meta__;
        } else if (target.__proto__.__meta__ === __meta__) {
            const subMeta = Object.create(Object.getPrototypeOf(__meta__));
            Object.getOwnPropertyNames(__meta__).forEach(name => {
                Object.defineProperty(subMeta, name, Object.getOwnPropertyDescriptor(__meta__, name));
            });
            __meta__ = subMeta;
            target.__meta__ = __meta__;
        }

        Object.defineProperty(__meta__, key, {
            enumerable: true,
            get: () => itemMeta,
            set: (v) => itemMeta = v
        });
    };

export class OptionItem {
    constructor(
        public title: string,
        public value: string,
    ) { }
}

export function isString(value: any): boolean {
    return typeof value === 'string' || value instanceof String;
}

export function isNumber(value: any): boolean {
    return typeof value === 'number' || value instanceof Number;
}

export class Remark {
    @Meta({
        description: 'Идентификатор',
        required: true,
    }) @Expose()
    id: string = '';
    @Meta({
        description: 'Значение',
        required: true,
    }) @Expose()
    text: string = '';
}

export class Instruction {
    @Meta({
        description: 'Текстовый идентификатор в рамках данного шаблона',
        required: true,
    }) @Expose()
    id: string = '';
    @Meta({
        description: 'Название',
        required: true,
    }) @Expose()
    title: string = '';
    @Meta({
        description: 'Уникальный идентификатор',
        required: true,
    }) @Expose()
    url: string = '';
}

export class UserSuggestion {
    @Expose()
    title?: string;
    @Expose()
    value?: string;
    @Expose() @Type(() => File)
    images: File[] = [];
}

export class Section {
    @Expose()
    type: string;
    @Meta({
        description: 'Название секции',
        required: true,
    }) @Expose()
    title: string = '';
}

export class Employee {
    @Expose()
    firstName?: string;
    @Expose()
    lastName?: string;
    @Expose()
    middleName?: string;
    @Expose()
    company?: string;
    @Expose()
    position?: string;
    @Expose()
    phone?: string;
    @Expose()
    email?: string;
}

export class MembersSection extends Section {
    constructor() {
        super();
        this.type = 'Members';
        this.title = 'Участники осмотра';
    }

    @Expose() @Type(() => Employee)
    employees: Employee[];
}

export class Field {
    @Expose()
    type: string;
    @Meta({}) @Expose()
    key?: string;
    @Meta({
        description: 'Заголовок поля',
        required: true,
    }) @Expose()
    title: string = '';
    @Meta({
        description: 'Описание поля. Отображается только на форме редактирования документа под основным элементом управления как комментарий к полю',
    }) @Expose()
    description?: string;
    @Meta({
        description: 'Признак обязательности для заполнения',
        type: 'boolean',
    }) @Expose()
    required?: boolean = false;
    @Meta({
        description: 'Признак недоступности для изменения',
        type: 'boolean',
    }) @Expose()
    readOnly?: boolean = false;
    @Expose()
    order?: number;
    @Meta({
        description: 'Признак отображения заголовка у поля',
        type: 'boolean',
    }) @Expose()
    showTitle?: boolean = true;
}

export class DateField extends Field {
    constructor() {
        super();
        this.type = 'Date';
    }

    @Meta({
        description: 'Минимальное значение. Допускается значение текущей даты - Now',
    }) @Expose()
    min?: string;
    @Meta({
        description: 'Максимальное значение. Допускается значение текущей даты - Now',
    }) @Expose()
    max?: string;
    @Expose()
    value?: string;
}

export class NumberField extends Field {
    constructor() {
        super();
        this.type = 'Number';
    }

    @Meta({
        description: 'Минимальное значение',
        required: true,
        type: 'number',
    }) @Expose()
    min: number = 0;
    @Meta({
        description: 'Максимальное значение',
        required: true,
        type: 'number',
    }) @Expose()
    max: number = 10000000;
    @Meta({
        description: 'Единицы измерения',
    }) @Expose()
    units?: string;
    @Meta({
        description: 'Количество знаков после запятой',
        type: 'number',
    }) @Expose()
    decimalPlaces?: number = 0;
    @Expose()
    value?: number;
}

export class TextField extends Field {
    constructor() {
        super();
        this.type = 'Text';
    }

    @Meta({
        description: 'Признак отображения поля в многострочном виде',
        type: 'boolean',
    }) @Expose()
    multiLine?: boolean = false;
    @Meta({
        description: 'Максимальное количество символов для значения поля',
        type: 'number',
    }) @Expose()
    length?: number = 4096;
    @Expose()
    value?: string;
}

export class FileField extends Field {
    constructor() {
        super();
        this.type = 'Image';
    }

    @Meta({
        description: 'Максимальное количество изображений',
        required: true,
        type: 'number',
    }) @Expose()
    max: number = 4;
    @Expose() @Type(() => File)
    images: File[] = [];
}

export class VariantsField extends Field {
    constructor() {
        super();
        this.type = 'Variants';
    }
}

export class SelectField extends Field {
    constructor() {
        super();
        this.type = 'Select';
    }

    @Meta({
        description: 'Список доступных опций для выбора',
        type: 'array',
        required: true,
    }) @Expose()
    options: string[] = [];
    @Expose()
    value?: string;
}

export class CheckboxField extends Field {
    constructor() {
        super();
        this.type = 'Checkbox';
    }

    @Expose()
    value?: boolean;
}

export class FieldsSection extends Section {
    constructor() {
        super();
        this.type = 'Fields';
        this.title = 'Параметры конструкции';
    }

    @Expose() @Type(() => Field, {
        discriminator: {
            property: 'type',
            subTypes: [
                { value: DateField, name: 'Date' },
                { value: NumberField, name: 'Number' },
                { value: TextField, name: 'Text' },
                { value: FileField, name: 'Image' },
                { value: VariantsField, name: 'Variants' },
                { value: SelectField, name: 'Select' },
                { value: CheckboxField, name: 'Checkbox' },
            ],
        },
        keepDiscriminatorProperty: true,
    })
    fields: Field[] = [];
}

export class TotalAttribute {
    @Meta({
        description: 'Процент дефекта (значение от 0 до 100)',
        type: 'number',
        required: true,
    }) @Expose()
    percent: number = 0.0;
    @Meta({
        description: 'Цвет',
        required: true,
    }) @Expose()
    color: string = '';
    @Meta({
        description: 'Список итоговых рекомендаций',
        type: 'array',
        required: true,
    }) @Expose()
    suggestions: string[] = [];
}

export class Option {
    @Meta({
        description: 'Тип опции',
        required: true,
    }) @Expose()
    type: string = '';
    @Meta({
        description: 'Цвет кнопки для данной оценки в редакторе документа',
        required: true,
    }) @Expose()
    color: string = '';
    @Meta({
        description: 'Процент дефекта (значение от 0 до 100)',
        type: 'number',
    }) @Expose()
    percent?: number = 0.0;
    @Meta({
        description: 'Текстовое описание опции',
        required: true,
    }) @Expose()
    value: string = '';
    @Meta({
        type: 'array',
    }) @Expose()
    suggestions?: string[] = [];
}

export class Requirement {
    @Meta({
        description: 'Список идентификаторов материалов',
        type: 'array',
    }) @Expose()
    materials?: string[] = [];
    @Meta({
        description: 'Ключевые слова для поиска по наименованию материала, разделённые запятой',
    }) @Expose()
    materialBrands?: string;
    @Meta({
        description: 'Диапазон месяцев, например 06-08 (лето) или 12-02 (зима)',
    }) @Expose()
    months?: string;
}

export class Link {
    @Meta({
        description: 'Путь к инструкции',
    }) @Expose()
    url?: string;
    @Meta({
        description: 'Номер страницы',
        type: 'number',
    }) @Expose()
    page?: number = 0;
    @Meta({
        description: 'Название',
        required: true,
    }) @Expose()
    title: string = '';
    @Meta({
        description: 'Текстовый идентификатор инструкции',
        type: 'options',
        optionsKey: 'refs',
    }) @Expose()
    ref?: string;
}

enum FileType {
    image,
    video,
    pdf
}

export class File {
    @Expose()
    type?: FileType = FileType.image;
    @Expose()
    source?: string;
    @Expose()
    name?: string;
}

export class ImageFile extends File { }

export class VideoFile extends File { }

export class PdfFile extends File { }

export class Variant {
    @Meta({
        description: 'Заголовок',
        required: true,
    }) @Expose()
    title: string = '';
    @Meta({
        description: 'Описание',
        required: true,
    }) @Expose()
    description: string = '';
    @Meta({
        description: 'Имя файла иллюстрации',
    }) @Expose()
    illustration?: string;
    @Expose()
    selected: boolean = false;
    @Meta({
        description: 'Относительные веса групп пунктов осмотра (значение от 0 до 1)',
        type: 'array',
        arrayType: Number,
    }) @Expose()
    weights: number[] = [];
}

export class Suggestion {
    @Meta({
        description: 'Текстовое значение рекомендации',
        required: true,
    }) @Expose()
    value: string;
    @Expose()
    selected?: boolean;
}

export class Comment {
    @Meta({
        description: 'Текстовое значение автоматического комментария',
        required: true,
    }) @Expose()
    value: string;
    @Expose()
    selected?: boolean;
    @Meta({
        description: 'Список рекомендаций к пункту осмотра относительно выбранного комментария',
        type: 'array',
        arrayType: Suggestion,
    }) @Expose() @Type(() => Suggestion)
    suggestions: Suggestion[] = [];
}

export class OptionAttribute {
    @Meta({
        description: 'Тип опции',
        required: true,
        type: 'options',
        optionsKey: 'checkListOptions',
    }) @Expose()
    option: string = '';
    @Meta({
        description: 'Относительный вес пункта осмотра в случае выбора данной опции (значение от 0 до 1)',
        type: 'number',
    }) @Expose()
    weight?: number;
    @Meta({
        description: 'Список рекомендаций',
        type: 'array',
    }) @Expose()
    suggestions: string[] = [];
    @Meta({
        description: 'Список автоматических комментариев',
        type: 'array',
        arrayType: Comment,
    }) @Expose() @Type(() => Comment)
    comments: Comment[] = [];
}

export class Parameter {
    @Meta({
        description: 'Заголовок',
        required: true,
    }) @Expose()
    title: string = '';
    @Meta({
        description: 'Минимальное значение',
        type: 'number',
    }) @Expose()
    min?: number = 0;
    @Meta({
        description: 'Максимальное значение',
        type: 'number',
    }) @Expose()
    max?: number = 0;
    @Meta({
        description: 'Единицы измерения',
    }) @Expose()
    units?: string;
    @Meta({
        description: 'Количество знаков после запятой',
        type: 'number',
    }) @Expose()
    decimalPlaces?: number = 0;
    @Expose()
    value?: number;
}

export class Position {
    @Meta({
        description: 'Название пункта осмотра',
        required: true,
    }) @Expose()
    title: string = '';
    @Meta({
        description: 'Относительный вес пункта осмотра (значение от 0 до 1)',
        type: 'number',
    }) @Expose()
    weight?: number;
    @Expose()
    option?: Option;
    @Meta({
        description: 'Требования для отображения данного пункта осмотра',
        type: 'object',
        arrayType: Requirement,
    }) @Expose() @Type(() => Requirement)
    requirement?: Requirement;
    @Meta({
        description: 'Требования к текушему пункту осмотра',
        type: 'array',
    }) @Expose()
    notes: string[] = [];
    @Meta({
        description: 'Список справочных материалов',
        type: 'array',
        arrayType: Link,
    }) @Expose() @Type(() => Link)
    links: Link[] = [];
    @Expose() @Type(() => File)
    images: File[] = [];
    @Meta({
        description: 'Список иллюстраций',
        type: 'array',
    }) @Expose()
    illustrations: string[] = [];
    @Meta({
        description: 'Список вводимых пользователем значений',
        type: 'array',
        arrayType: Parameter,
    }) @Expose() @Type(() => Parameter)
    parameters: Parameter[] = [];
    @Expose()
    comment?: string;
    @Expose()
    suggestion?: string;
    @Meta({
        description: 'Справочный материал по конкретным опциям',
        type: 'array',
        arrayType: OptionAttribute,
    }) @Expose() @Type(() => OptionAttribute)
    optionAttributes: OptionAttribute[] = [];
}

export class Group {
    @Meta({
        description: 'Заголовок',
        required: true,
    }) @Expose()
    title: string = '';
    @Expose() @Type(() => Position)
    positions: Position[] = [];
}

export class CheckListSection extends Section {
    constructor() {
        super();
        this.type = 'CheckList';
        this.title = 'Осмотр объекта';
    }

    @Meta({
        description: 'Справочный материал по диапазонам износа',
        type: 'array',
        arrayType: TotalAttribute,
    }) @Expose() @Type(() => TotalAttribute)
    totalAttributes: TotalAttribute[] = [];
    @Meta({
        description: 'Заголовок для итогов износа в отчёте',
    }) @Expose()
    totalTitle?: string;
    @Meta({
        description: 'Максимальное количество изображений, которое можно прикрепить к каждому пункту осмотра',
        type: 'number',
    }) @Expose()
    maxFiles?: number = 4;
    @Meta({
        description: 'Варианты оценки',
        type: 'array',
        arrayType: Option,
    }) @Expose() @Type(() => Option)
    options: Option[] = [];
    @Meta({
        description: 'Список вариантов конструкции',
        type: 'array',
        arrayType: Variant,
    }) @Expose() @Type(() => Variant)
    variants: Variant[] = [];
    @Expose() @Type(() => Group)
    groups: Group[] = [];
    @Expose() @Type(() => Position)
    positions: Position[] = [];
}

export class Template {
    @Meta({
        description: 'Название типа документа, созданного на основе этого шаблона',
        required: true,
    }) @Expose()
    name: string;
    @Meta({
        description: 'Название документа, созданного на основе этого шаблона',
        required: true,
    }) @Expose()
    title: string;
    @Meta({
        description: 'Тип создаваемого документа',
        required: true,
        type: 'options',
        options: [
            "InspectionReport",
            "DefectReport",
        ],
    }) @Expose()
    type: string = 'InspectionReport';
    @Meta({
        description: 'Примечания',
        type: 'array',
        arrayType: Remark,
    }) @Expose() @Type(() => Remark)
    remarks: Remark[] = [];
    @Meta({
        description: 'Список инструкций',
        type: 'array',
        arrayType: Instruction,
    }) @Expose() @Type(() => Instruction)
    instructions: Instruction[] = [];
    @Expose() @Type(() => Section, {
        discriminator: {
            property: 'type',
            subTypes: [
                { value: MembersSection, name: 'Members' },
                { value: FieldsSection, name: 'Fields' },
                { value: CheckListSection, name: 'CheckList' },
            ],
        },
        keepDiscriminatorProperty: true,
    })
    sections: Section[] = [];
}