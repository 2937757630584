import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, SortDirection} from '@angular/material';

import {catchError, debounceTime, distinctUntilChanged, first, switchMap, takeUntil, tap} from 'rxjs/operators';
import {fromEvent, merge, of, Subject} from 'rxjs';

import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {TemplateService} from '../../../../../api/services';
import {TemplateDataSource} from '../../data-sources';
import {PersistentComponent, RestoredState, SavingState, StateService} from '../../../../../common/data/state.service';

@Component({
    selector: 'kt-template-list',
    templateUrl: './template-list.component.html',
    styleUrls: ['./template-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateListComponent implements OnInit, AfterViewInit, OnDestroy, PersistentComponent {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    dataSource: TemplateDataSource;
    displayedColumns = ['cover', 'title', 'documentType', 'version', 'isEngineering', 'isDeleted', 'created', 'modified', 'actions'];
    isDeleted = 'false';
    sortActive = 'title';
    sortDirection: SortDirection = 'asc';
    pageIndex = 0;
    pageSize = 10;
    private destroy$ = new Subject<void>();


    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private templateService: TemplateService,
        private stateService: StateService) {
    }

    ngOnInit() {
        this.dataSource = new TemplateDataSource(this.templateService);

        this.stateService.restore(this);

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.load()),
                takeUntil(this.destroy$)
            ).subscribe();

        fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => this.filter()),
                takeUntil(this.destroy$)
            ).subscribe();
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.stateService.save(this);
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        this.dataSource.load(
            this.searchInput.nativeElement.value,
            null,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    delete(templateId: string) {
        const dialogRef = this.layoutUtilsService.deleteElement(
            'Удаление',
            'Вы действительно хотите удалить шаблон?',
            'Удаление шаблона...');

        dialogRef.afterClosed()
            .pipe(
                first(),
                switchMap(res => {
                    if (res) {
                        return this.templateService.delete(templateId);
                    }

                    return of();
                }),
                tap(() => {
                    this.layoutUtilsService.showActionNotification('Шаблон был удалён.',
                        MessageType.Delete,
                        3000,
                        true,
                        false);
                    this.load();
                }),
                catchError(() => {
                    this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении шаблона.',
                        MessageType.Update,
                        3000,
                        true,
                        false);
                    return of();
                }))
            .subscribe();

    }

    restore(templateId: string) {
        this.templateService.restore(templateId)
            .pipe(
                first(),
                tap(() => {
                    this.layoutUtilsService.showActionNotification(
                        'Документ был восстановлен.',
                        MessageType.Update,
                        3000,
                        true,
                        false);
                    this.load();
                }),
                catchError(response => {
                    this.layoutUtilsService.showActionNotification(
                        'Произошла ошибка при восстановлении документа.',
                        MessageType.Update,
                        3000,
                        true,
                        false);
                    return of(response);
                }))
            .subscribe();
    }

    saveState(state: SavingState) {
        state
            .set('title', this.searchInput.nativeElement.value)
            .set('isDeleted', this.isDeleted)
            .set('sortActive', this.sort.active)
            .set('sortDirection', this.sort.direction)
            .set('pageIndex', this.paginator.pageIndex)
            .set('pageSize', this.paginator.pageSize);
    }

    restoreState(state: RestoredState) {
        this.searchInput.nativeElement.value = state.get('title');
        this.isDeleted = state.get('isDeleted');
        this.sortActive = state.get('sortActive');
        this.sortDirection = state.get('sortDirection');
        this.pageIndex = state.get('pageIndex');
        this.pageSize = state.get('pageSize');
    }
}
