// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';
import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {AuthGuard} from './core/auth';
import {DirectionListComponent} from './views/pages/dictionaries/directions/direction-list/direction-list.component';
import {LanguageListComponent} from './views/pages/dictionaries/languages/language-list/language-list.component';
import {UnitListComponent} from './views/pages/dictionaries/units/unit-list/unit-list.component';
import {MaterialListComponent} from './views/pages/products/materials/material-list/material-list.component';
import {ProductListComponent} from './views/pages/products/products/product-list/product-list.component';
import {ProductEditComponent} from './views/pages/products/products/product-edit/product-edit.component';
import {CountryListComponent} from './views/pages/support/countries/country-list/country-list.component';
import {
    AdministrativeAreaListComponent
} from './views/pages/support/administrative-areas/administrative-area-list/administrative-area-list.component';
import {ContactListComponent} from './views/pages/support/contacts/contact-list/contact-list.component';
import {ContactEditComponent} from './views/pages/support/contacts/contact-edit/contact-edit.component';
import {TemplateListComponent} from './views/pages/products/templates/template-list/template-list.component';
import {TemplateEditComponent} from './views/pages/products/templates/template-edit/template-edit.component';
import {TemplateContentEditComponent} from './views/pages/products/templates/template-content-edit/template-content-edit.component';
import {InstructionListComponent} from './views/pages/products/instructions/instruction-list/instruction-list.component';
import {InstructionEditComponent} from './views/pages/products/instructions/instruction-edit/instruction-edit.component';
import {DocumentListComponent} from './views/pages/documents/documents/document-list/document-list.component';
import {ProjectListComponent} from './views/pages/projects/projects/project-list/project-list.component';
import {UserListComponent} from './views/pages/users/users/user-list/user-list.component';
import {UserEditComponent} from './views/pages/users/users/user-edit/user-edit.component';
import {DocumentTypeListComponent} from './views/pages/dictionaries/document-types/document-type-list/document-type-list.component';
import {ServiceListComponent} from './views/pages/services/services/service-list/service-list.component';
import {ServiceEditComponent} from './views/pages/services/services/service-edit/service-edit.component';
import {StoryListComponent} from './views/pages/stories/stories/story-list/story-list.component';
import {StoryEditComponent} from './views/pages/stories/stories/story-edit/story-edit.component';
import {AdministratorListComponent} from './views/pages/users/administrators/administrator-list/administrator-list.component';
import {ProfileEditComponent} from './views/pages/users/profiles/profile-edit/profile-edit.component';
import {SuggestionListComponent} from './views/pages/suggestions/suggestions/suggestion-list/suggestion-list.component';
import {SuggestionDetailComponent} from './views/pages/suggestions/suggestions/suggestion-detail/suggestion-detail.component';
import {PretensionListComponent} from './views/pages/pretensions/pretensions/pretension-list/pretension-list.component';
import {PretensionDetailComponent} from './views/pages/pretensions/pretensions/pretension-detail/pretension-detail.component';
import {PromoCodeListComponent} from './views/pages/users/promo-codes/promo-code-list/promo-code-list.component';
import {ConversationListComponent} from './views/pages/conversations/components/conversation-list/conversation-list.component';
import {AccessTokenListComponent} from './views/pages/dictionaries/access-tokens/access-token-list/access-token-list.component';
import {TemplateProductsListComponent} from './views/pages/products/templates/template-products-list/template-products-list.component';
import {TemplateProductsAddComponent} from './views/pages/products/templates/template-products-add/template-products-add.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('app/views/pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: '',
        component: BaseComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('app/views/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'dictionaries/directions',
                component: DirectionListComponent
            },
            {
                path: 'dictionaries/languages',
                component: LanguageListComponent
            },
            {
                path: 'dictionaries/units',
                component: UnitListComponent
            },
            {
                path: 'dictionaries/document-types',
                component: DocumentTypeListComponent
            },
            {
                path: 'dictionaries/access-tokens',
                component: AccessTokenListComponent
            },
            {
                path: 'materials',
                component: MaterialListComponent
            },
            {
                path: 'products',
                component: ProductListComponent
            },
            {
                path: 'products/:productId',
                component: ProductEditComponent
            },
            {
                path: 'support/countries',
                component: CountryListComponent
            },
            {
                path: 'support/administrative-areas',
                component: AdministrativeAreaListComponent
            },
            {
                path: 'support/contacts',
                component: ContactListComponent
            },
            {
                path: 'support/contacts/:contactId',
                component: ContactEditComponent
            },
            {
                path: 'templates',
                component: TemplateListComponent
            },
            {
                path: 'templates/:templateId',
                component: TemplateEditComponent
            },
            {
                path: 'templates/:templateId/products',
                component: TemplateProductsListComponent
            },
            {
                path: 'templates/:templateId/products/add',
                component: TemplateProductsAddComponent
            },
            {
                path: 'templates/:templateId/contents/:contentId',
                component: TemplateContentEditComponent
            },
            {
                path: 'instructions',
                component: InstructionListComponent
            },
            {
                path: 'instructions/:instructionId',
                component: InstructionEditComponent
            },
            {
                path: 'documents',
                component: DocumentListComponent
            },
            {
                path: 'conversations',
                component: ConversationListComponent
            },
            {
                path: 'projects',
                component: ProjectListComponent
            },
            {
                path: 'users',
                component: UserListComponent
            },
            {
                path: 'users/:userId',
                component: UserEditComponent
            },
            {
                path: 'promo-codes',
                component: PromoCodeListComponent
            },
            {
                path: 'services',
                component: ServiceListComponent
            },
            {
                path: 'services/:serviceId',
                component: ServiceEditComponent
            },
            {
                path: 'suggestions',
                component: SuggestionListComponent
            },
            {
                path: 'suggestions/:suggestionId',
                component: SuggestionDetailComponent
            },
            {
                path: 'pretensions',
                component: PretensionListComponent
            },
            {
                path: 'pretensions/:pretensionId',
                component: PretensionDetailComponent
            },
            {
                path: 'stories',
                component: StoryListComponent
            },
            {
                path: 'stories/:storyId',
                component: StoryEditComponent
            },
            {
                path: 'administrators',
                component: AdministratorListComponent
            },
            {
                path: 'profile',
                component: ProfileEditComponent
            },
            {
                path: 'error/403',
                component: ErrorPageComponent,
                data: {
                    type: 'error-v6',
                    code: 403,
                    title: '403... Access forbidden',
                    desc: 'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
                },
            },
            {path: 'error/:type', component: ErrorPageComponent},
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
        ],
    },
    {path: '**', redirectTo: 'error/403', pathMatch: 'full'},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
