import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { find } from 'lodash';

import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { DictionaryService, StoryService } from '../../../../../api/services';
import { StoryDataSource } from '../../data-sources';
import { PersistentComponent, RestoredState, SavingState, StateService } from '../../../../../common/data/state.service';

@Component({
  selector: 'kt-story-list',
  templateUrl: './story-list.component.html',
  styleUrls: ['./story-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoryListComponent implements OnInit, AfterViewInit, OnDestroy, PersistentComponent {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private storyService: StoryService,
    private dictionaryService: DictionaryService,
    private stateService: StateService) { }

  private subscriptions: Subscription[] = [];

  dataSource: StoryDataSource;
  displayedColumns = ['image', 'title', 'description', '_language', 'isPublished', 'isDeleted', 'created', 'modified', 'actions'];
  languages: ListItem[] = [];
  languageId = 1;
  isPublished = '';
  isDeleted = 'false';
  sortActive = 'title';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 10;

  ngOnInit() {
    this.dataSource = new StoryDataSource(this.storyService);

    this.stateService.restore(this);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);

    this.dictionaryService.getLanguages()
      .subscribe(languages => {
        this.languages = languages;
      });
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.stateService.save(this);
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.languageId,
      this.isPublished === 'true' ? true : this.isPublished === 'false' ? false : null,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  restore(storyId: string) {
    this.storyService.restore(storyId)
      .subscribe(
        response => {
          this.layoutUtilsService.showActionNotification('История была восстановлена.', MessageType.Update, 3000, true, false);
          this.load();
        },
        error => {
          this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении истории.', MessageType.Update, 3000, true, false);
        }
      );
  }

  delete(storyId: string) {
    const dialogRef = this.layoutUtilsService.deleteElement('Удаление', 'Вы действительно хотите удалить историю?', 'Удаление истории...');
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.storyService.delete(storyId)
          .subscribe(
            response => {
              this.layoutUtilsService.showActionNotification('История была удалена.', MessageType.Delete, 3000, true, false);
              this.load();
            },
            error => {
              this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении истории.', MessageType.Update, 3000, true, false);
            }
          );
      });
  }

  getLanguageStr(languageId: number): string {
    const language = find(this.languages, (language: ListItem) => language.id === languageId);
    if (language) {
      return language.title;
    } else {
      return '';
    }
  }

  saveState(state: SavingState) {
    state
      .set('title', this.searchInput.nativeElement.value)
      .set('languageId', this.languageId)
      .set('isPublished', this.isPublished)
      .set('isDeleted', this.isDeleted)
      .set('sortActive', this.sort.active)
      .set('sortDirection', this.sort.direction)
      .set('pageIndex', this.paginator.pageIndex)
      .set('pageSize', this.paginator.pageSize);
  }

  restoreState(state: RestoredState) {
    this.searchInput.nativeElement.value = state.get('title');
    this.languageId = state.get('languageId');
    this.isPublished = state.get('isPublished');
    this.isDeleted = state.get('isDeleted');
    this.sortActive = state.get('sortActive');
    this.sortDirection = state.get('sortDirection');
    this.pageIndex = state.get('pageIndex');
    this.pageSize = state.get('pageSize');
  }
}
