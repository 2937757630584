import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';

import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {fromEvent, merge, Subject} from 'rxjs';

import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {UserService} from '../../../../../api/services';
import {UserDataSource} from '../../data-sources';
import {PersistentComponent, RestoredState, SavingState, StateService} from '../../../../../common/data/state.service';
import {SortDirection} from '@angular/material/sort/typings/sort-direction';
import {UserTypes} from '../../user-types';
import {UserListItem, UserType} from '../../../../../api/models/users';

@Component({
    selector: 'kt-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent implements OnInit, AfterViewInit, OnDestroy, PersistentComponent {
    private readonly destroy$ = new Subject<void>();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    protected readonly dataSource: UserDataSource;
    protected readonly displayedColumns: Array<keyof UserListItem | 'actions'> = [
        'lastName',
        'firstName',
        'middleName',
        'company',
        'position',
        'language',
        'phoneNumber',
        'email',
        'type',
        'isEmailVerified',
        'isDeleted',
        'isRemoved',
        'isTestingAllowed',
        'isEngineer',
        'isBlocked',
        'created',
        'modified',
        'actions'
    ];

    protected isDeleted = 'false';
    protected sortActive = 'modified';
    protected sortDirection: SortDirection = 'desc';
    protected pageIndex = 0;
    protected pageSize = 10;

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private userService: UserService,
        private stateService: StateService
    ) {
        this.dataSource = new UserDataSource(this.userService);
    }

    ngOnInit() {
        this.stateService.restore(this);

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.load());

        fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(150),
                distinctUntilChanged()
            ).subscribe(() => this.filter());
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy(): void {
        this.stateService.save(this);

        this.destroy$.complete();
        this.destroy$.next();
    }

    getUserTypeTitle(type: UserType) {
        return UserTypes.find(t => t.value === type).title;
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        this.dataSource.load(
            this.searchInput.nativeElement.value,
            undefined,
            undefined,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : undefined,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    unblock(userId: string) {
        this.userService.unblock(userId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                    this.layoutUtilsService.showActionNotification(
                        'Пользователь был разблокирован.',
                        MessageType.Update,
                        3000,
                        true,
                        false);

                    this.load();
                }, () => {
                    this.layoutUtilsService.showActionNotification(
                        'Произошла ошибка при разблокировке пользователя.',
                        MessageType.Update,
                        3000,
                        true,
                        false);
                }
            );
    }

    block(userId: string) {
        const dialogRef = this.layoutUtilsService.confirmAction(
            'Блокировка',
            'Вы действительно хотите заблокировать пользователя?',
            'Блокировка пользователя...',
            'Заблокировать');

        dialogRef.afterClosed().pipe(
            takeUntil(this.destroy$),
            filter(dialogResult => !!dialogResult)
        ).subscribe(() => {
            this.userService.block(userId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                        this.layoutUtilsService.showActionNotification(
                            'Пользователь был заблокирован.',
                            MessageType.Update,
                            3000,
                            true,
                            false);

                        this.load();
                    }, () => {
                        this.layoutUtilsService.showActionNotification(
                            'Произошла ошибка при блокировке пользователя.',
                            MessageType.Update,
                            3000,
                            true,
                            false);
                    }
                );
        });
    }

    restore(userId: string) {
        const dialogRef = this.layoutUtilsService.confirmAction(
            'Восстановление',
            'Вы действительно хотите восстановить пользователя?',
            'Восстановление пользователя...',
            'Восстановить');

        dialogRef.afterClosed().pipe(
            takeUntil(this.destroy$),
            filter(dialogResult => !!dialogResult)
        ).subscribe(() => {
            this.userService.restore(userId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                        this.layoutUtilsService.showActionNotification(
                            'Пользователь был восстановлен.',
                            MessageType.Update,
                            3000,
                            true,
                            false);

                        this.load();
                    }, () => {
                        this.layoutUtilsService.showActionNotification(
                            'Произошла ошибка при восстановлении пользователя.',
                            MessageType.Update,
                            3000,
                            true,
                            false);
                    }
                );
        });
    }

    delete(userId: string) {
        const dialogRef = this.layoutUtilsService.deleteElement(
            'Удаление',
            'Вы действительно хотите удалить пользователя?',
            'Удаление пользователя...');

        dialogRef.afterClosed().pipe(
            takeUntil(this.destroy$),
            filter(dialogResult => !!dialogResult)
        ).subscribe(() => {
            this.userService.delete(userId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                        this.layoutUtilsService.showActionNotification(
                            'Пользователь был удалён.',
                            MessageType.Delete,
                            3000,
                            true,
                            false);

                        this.load();
                    }, () => {
                        this.layoutUtilsService.showActionNotification(
                            'Произошла ошибка при удалении пользователя.',
                            MessageType.Update,
                            3000,
                            true,
                            false);
                    }
                );
        });
    }

    saveState(state: SavingState) {
        state
            .set('name', this.searchInput.nativeElement.value)
            .set('isDeleted', this.isDeleted)
            .set('sortActive', this.sort.active)
            .set('sortDirection', this.sort.direction)
            .set('pageIndex', this.paginator.pageIndex)
            .set('pageSize', this.paginator.pageSize);
    }

    restoreState(state: RestoredState) {
        this.searchInput.nativeElement.value = state.get('name');
        this.isDeleted = state.get('isDeleted');
        this.sortActive = state.get('sortActive');
        this.sortDirection = state.get('sortDirection');
        this.pageIndex = state.get('pageIndex');
        this.pageSize = state.get('pageSize');
    }
}
