import {of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {BaseDataSource} from '../../../../common/data/base-data-source';

import {UserListItem} from '../../../../api/models/users';
import {UserService} from '../../../../api/services';
import {PagedResponse} from '../../../../api/models/paged-response.interface';

export class UserDataSource extends BaseDataSource<UserListItem> {

    constructor(private userService: UserService) {
        super();
    }

    load(searchValue: string,
         isTestingAllowed: boolean | undefined,
         isEngineer: boolean | undefined,
         isDeleted: boolean | undefined,
         sortField: string,
         sortDirection: string,
         pageIndex: number,
         pageSize: number) {
        this.loadingSubject.next(true);
        this.userService.get(searchValue, isTestingAllowed, isEngineer, isDeleted, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<UserListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
