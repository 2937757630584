import {Pipe, PipeTransform} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SafeUrl} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Pipe({
    name: 'slideImage'
})
export class SlideImagePipe implements PipeTransform {

    constructor(private http: HttpClient) {
    }

    transform(url: string): Observable<SafeUrl> {
        return this.http.get(url, {responseType: 'blob'})
            .pipe(
                map(val => {
                    if (val.type.startsWith('video')) {
                        return '../../../../../../assets/media/misc/video-icon.png';
                    }

                    return url;
                })
            );
    }

}
