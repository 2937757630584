import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { forkJoin } from 'rxjs';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { CountryPhoneCodeListItem } from '../models/country-phone-code-list-item.interface';
import { Country, CountryPhoneCode } from '../../../../../api/models/countries';
import { CountryPhoneCodeService, CountryService, DictionaryService } from '../../../../../api/services';

@Component({
  selector: 'kt-country-edit',
  templateUrl: './country-edit.component.html',
  styleUrls: ['./country-edit.component.scss']
})
export class CountryEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CountryEditComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private countryPhoneCodeService: CountryPhoneCodeService,
    private countryService: CountryService,
    private dictionaryService: DictionaryService) {
  }

  private country: Country;
  private languages: ListItem[];
  private countryPhoneCodes: CountryPhoneCode[];

  countryId: number;
  phoneCodes: CountryPhoneCodeListItem[] = [];
  form: FormGroup;
  contents = new FormArray([]);
  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  ngOnInit() {
    this.countryId = this.data.countryId;
    this.createForm();
    this.load();
  }

  createForm() {
    this.form = this.fb.group({
      code: [null, Validators.compose([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2)]
      )],
      languageId: [null, Validators.required],
      contents: this.contents
    });
  }

  addContents() {
    if (this.country) {
      this.form.controls.code.setValue(this.country.code);
      this.form.controls.languageId.setValue(this.country.languageId);
    }

    if (this.countryPhoneCodes) {
      const phoneCodes: CountryPhoneCodeListItem[] = [];
      this.countryPhoneCodes.forEach((countryPhoneCode) => {
        if (countryPhoneCode.isDeleted) return;

        phoneCodes.push({
          id: countryPhoneCode.id,
          code: countryPhoneCode.code,
          isDeleted: countryPhoneCode.isDeleted
        });
      });
      this.phoneCodes = phoneCodes;
    }

    this.languages.forEach(language => {
      let content = null;
      if (this.country) {
        content = this.country.contents.filter(o => o.languageId === language.id)[0];
      }
      this.contents.push(this.fb.group({
        language: [language],
        name: [content ? content.name : '', Validators.compose([
          Validators.required,
          Validators.maxLength(50)]
        )]
      }))
    });
  }

  load() {
    this.viewLoading = true;
    if (this.countryId) {
      forkJoin([
        this.dictionaryService.getLanguages(),
        this.countryService.getById(this.countryId),
        this.countryService.getPhoneCodes(this.countryId),
      ]).subscribe(result => {
        this.languages = result[0];
        this.country = result[1];
        this.countryPhoneCodes = result[2];
        this.addContents();
        this.viewLoading = false;
      });
    }
    else {
      this.dictionaryService.getLanguages()
        .subscribe(languages => {
          this.languages = languages;
          this.addContents();
          this.viewLoading = false;
        });
    }
  }

  addPhoneCode(phoneCode: string) {
    this.phoneCodes.push({
      id: null,
      code: phoneCode,
      isDeleted: false,
    });
  }

  deletePhoneCode(phoneCode: CountryPhoneCodeListItem) {
    phoneCode.isDeleted = true;
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    const country = this.prepare();
    if (country.id) {
      this.update(country);
    } else {
      this.create(country);
    }
  }

  private prepare(): Country {
    const controls = this.form.controls;
    const contents = [];
    this.contents.controls.forEach((fromGroup: FormGroup) => {
      contents.push({
        languageId: fromGroup.controls.language.value.id,
        name: fromGroup.controls.name.value
      })
    })
    return {
      id: this.countryId,
      code: controls.code.value,
      languageId: controls.languageId.value,
      contents: contents,
      isDeleted: null,
      created: null,
      modified: null
    };
  }

  private create(country: Country) {
    this.viewLoading = true;
    this.countryService.add(country)
      .subscribe(
        country => {
          this.updatePhoneCodes(country.id);
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  private update(country: Country) {
    this.viewLoading = true;
    this.countryService.update(country)
      .subscribe(
        response => {
          this.updatePhoneCodes(country.id);
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  private updatePhoneCodes(countryId: number) {
    const items: any[] = [];

    const phoneCodesToDelete: CountryPhoneCodeListItem[] = [];
    const phoneCodesToAdd: CountryPhoneCodeListItem[] = [];
    this.phoneCodes.forEach((phoneCode) => {
      if (phoneCode.isDeleted) {
        if (phoneCode.id === null) return;

        phoneCodesToDelete.push(phoneCode);
      } else {
        if (phoneCode.id !== null) return;

        phoneCodesToAdd.push(phoneCode);
      }
    });

    phoneCodesToDelete.forEach((phoneCode) => {
      items.push(this.countryPhoneCodeService.delete(phoneCode.id));
    });
    phoneCodesToAdd.forEach((phoneCode) => {
      items.push(this.countryPhoneCodeService.add({
        id: null,
        code: phoneCode.code,
        countryId: countryId,
        isDeleted: null,
        created: null,
        modified: null
      }));
    });

    forkJoin(items)
      .subscribe({
        complete: () => {
          this.viewLoading = false;
          this.dialogRef.close({ countryId, isEdit: true });
        }
      });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(this.form, controlName, validationType);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.errorMessage = '';
  }
}
