import {getErrorCodeMessage} from './error-codes';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';

export const GUID_PATTERN = '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$';

export function hasErrorCode(response: any) {
    return response.error && response.error.code;
}

export function getErrorMessage(response: any) {
    return getErrorCodeMessage(response.error.code);
}

export function setFormError(form: FormGroup, response: any) {
    Object.keys(response.error).forEach(field => {
        const groups = field.match(/(\w+)\[(\d+)\]\.(\w+)/);

        let formControl = null;

        if (groups && groups.length === 4) {
            const array = form.get(groups[1]) as FormArray;
            formControl = array.controls[Number(groups[2])].get(groups[3]);
        } else {
            formControl = form.get(field);
        }
        if (formControl) {
            formControl.setErrors({
                serverError: response.error[field]
            });
        }
    });
}

// the function above looks incorrect. at least for user-edit form.
// made new one for user-edit form. Will watch
export function setUserFormError(form: FormGroup, response: any) {
    const errors = response.error.errors;
    if (!!errors) {
        Object.keys(errors).forEach(field => {
            const groups = field.match(/(\w+)\[(\d+)]\.(\w+)/);
            const control: AbstractControl = groups && groups.length === 4
                ? form.get([groups[1], Number(groups[2]), groups[3]])
                : form.get(field);

            control.setErrors({
                serverError: Array.isArray(errors[field])
                    ? errors[field].join(', ')
                    : errors[field]
            });
        });
    }
}

export function markFormGroupTouched(formGroup: FormGroup) {
    (<any> Object).values(formGroup.controls).forEach(control => {
        control.markAsTouched();
        if (control.controls) {
            markFormGroupTouched(control);
        }
    });
}

export function isFormGroupControlHasError(formGroup: FormGroup, controlName: string, validationType: string): boolean {
    const control = formGroup.controls[controlName];
    return control && control.hasError(validationType) && (control.dirty || control.touched);
}
