import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { PromoCode, PromoCodeType } from '../../../../../api/models/promo-codes';
import { PromoCodeService } from '../../../../../api/services';


@Component({
  selector: 'kt-promo-code-upload',
  templateUrl: './promo-code-upload.component.html',
  styleUrls: ['./promo-code-upload.component.scss']
})
export class PromoCodeUploadComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PromoCodeUploadComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private promoCodeService: PromoCodeService
  ) { }

  minExpirationDate: Date;
  maxExpirationDate: Date;

  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  types: any[] = [
    { id: PromoCodeType.Registration, title: 'Регистрация' },
    { id: PromoCodeType.Documents, title: 'Первые 10 документов' }
  ];

  form: FormGroup;

  hasContent = false;
  contentFile: File = null;
  contentHasError = false;

  ngOnInit() {
    this.minExpirationDate = new Date();
    this.maxExpirationDate = new Date(this.minExpirationDate.getFullYear() + 10, 11, 31);

    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      type: [null, Validators.compose([
        Validators.required]
      )],
      expirationDate: [{ value: Date.now() }, Validators.compose([
        Validators.required]
      )]
    });
  }

  onContentChanged(files: FileList) {
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (file.type.match(/text\/*/) == null) {
      return;
    }

    this.contentFile = file;
    this.contentHasError = false;
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    if (!this.contentFile) {
      this.hasFormErrors = true;
      this.errorMessage = 'Файл не выбран';
      return;
    }

    const expirationDate = new Date(this.form.controls.expirationDate.value);

    expirationDate.setHours(23, 59, 59);

    this.viewLoading = true;
    this.promoCodeService.upload(this.form.controls.type.value, expirationDate, this.contentFile)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ isEdit: false });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(this.form, controlName, validationType);
  }
}
