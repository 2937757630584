import {Configuration} from '../../api/models/configurations';

export class MenuConfig {
    public defaults: any;

    constructor(configuration: Configuration) {
        this.defaults = {
            header: {
                self: {},
                items: []
            },
            aside: {
                self: {},
                items: [
                    {
                        title: 'Главная',
                        root: true,
                        icon: 'flaticon-grid-menu',
                        page: '/dashboard',
                        bullet: 'dot'
                    },
                    {section: 'Мониторинг'},
                    {
                        title: 'Объекты',
                        root: true,
                        icon: 'flaticon2-architecture-and-city',
                        page: '/projects',
                        bullet: 'dot'
                    },
                    {
                        title: 'Документы',
                        root: true,
                        icon: 'flaticon2-checking',
                        page: '/documents',
                        bullet: 'dot'
                    },
                    {
                        title: 'Пользователи',
                        root: true,
                        icon: 'flaticon-users-1',
                        page: '/users',
                        bullet: 'dot'
                    },
                    {
                        title: 'Промокоды',
                        root: true,
                        icon: 'flaticon-price-tag',
                        page: '/promo-codes',
                        bullet: 'dot'
                    },
                    {
                        title: 'Администраторы',
                        root: true,
                        icon: 'flaticon2-safe',
                        page: '/administrators',
                        bullet: 'dot'
                    },
                    {
                        title: 'Истории',
                        root: true,
                        icon: 'flaticon-instagram-logo',
                        page: '/stories',
                        bullet: 'dot'
                    },
                    {
                        title: 'Услуги',
                        root: true,
                        icon: 'flaticon2-notepad',
                        page: '/services',
                        bullet: 'dot'
                    },
                    {
                        title: 'Идеи',
                        root: true,
                        icon: 'flaticon-light',
                        page: '/suggestions',
                        bullet: 'dot'
                    },
                    {
                        title: 'Претензии',
                        root: true,
                        icon: 'flaticon2-writing',
                        page: '/pretensions',
                        bullet: 'dot'
                    },
                    {
                        title: 'Чаты',
                        root: true,
                        icon: 'flaticon2-writing',
                        page: '/conversations',
                        bullet: 'dot'
                    },
                    {section: 'Продукты'},
                    {
                        title: 'Материалы',
                        root: true,
                        icon: 'flaticon-add-label-button',
                        page: '/materials',
                        bullet: 'dot'
                    },
                    {
                        title: 'Системы',
                        root: true,
                        icon: 'flaticon2-layers',
                        page: '/products',
                        bullet: 'dot'
                    },
                    {
                        title: 'Шаблоны',
                        root: true,
                        icon: 'flaticon2-website',
                        page: '/templates',
                        bullet: 'dot'
                    },
                    {
                        title: 'Руководства и инструкции',
                        root: true,
                        icon: 'flaticon2-folder',
                        page: '/instructions',
                        bullet: 'dot'
                    },
                    {section: 'Поддержка'},
                    {
                        title: 'Регионы',
                        root: true,
                        icon: 'flaticon-placeholder-3',
                        page: '/support/administrative-areas',
                        bullet: 'dot'
                    },
                    {
                        title: 'Контакты',
                        root: true,
                        icon: 'flaticon2-email',
                        page: '/support/contacts',
                        bullet: 'dot'
                    },
                    {section: 'Справочники'},
                    {
                        title: 'Типы документов',
                        root: true,
                        icon: 'flaticon-doc',
                        page: '/dictionaries/document-types',
                        bullet: 'dot'
                    },
                    {
                        title: 'Направления',
                        root: true,
                        icon: 'flaticon2-rhombus',
                        page: '/dictionaries/directions',
                        bullet: 'dot'
                    },
                    {
                        title: 'Единицы измерения',
                        root: true,
                        icon: 'flaticon-infinity',
                        page: '/dictionaries/units',
                        bullet: 'dot'
                    },
                    {
                        title: 'Страны',
                        root: true,
                        icon: 'flaticon-earth-globe',
                        page: '/support/countries',
                        bullet: 'dot'
                    },
                    {
                        title: 'Языки',
                        root: true,
                        icon: 'flaticon2-world',
                        page: '/dictionaries/languages',
                        bullet: 'dot'
                    },
                    {
                        title: 'Токены для отчетов',
                        root: true,
                        icon: 'flaticon-security',
                        page: '/dictionaries/access-tokens',
                        bullet: 'dot',
                    },
                    {
                        title: 'Логи',
                        root: true,
                        icon: 'flaticon-folder-1',
                        page: configuration.seqLogsUrl,
                        bullet: 'dot',
                        absoluteLink: true
                    },
                ]
            }
        };
    }

    public get configs(): any {
        return this.defaults;
    }
}
