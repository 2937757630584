import {of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {BaseDataSource} from '../../../../common/data/base-data-source';

import {ProjectListItem} from '../../../../api/models/projects';
import {ProjectService} from '../../../../api/services';
import {PagedResponse} from '../../../../api/models/paged-response.interface';

export class ProjectDataSource extends BaseDataSource<ProjectListItem> {

    constructor(private projectService: ProjectService) {
        super();
    }

    load(searchValue: string,
         userPhoneNumber: string,
         userId: string,
         isDeleted: boolean,
         sortField: string,
         sortProject: string,
         pageIndex: number,
         pageSize: number) {
        this.loadingSubject.next(true);

        this.projectService.get(searchValue, userPhoneNumber, userId, isDeleted, sortField, sortProject, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<ProjectListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
