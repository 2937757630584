import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ProjectListItem} from '../models/projects';
import {PagedResponse} from '../models/paged-response.interface';

const API_URL = 'api/projects';

@Injectable()
export class ProjectService {
    constructor(private http: HttpClient) {
    }

    get(searchValue: string,
        userPhoneNumber: string,
        userId: string,
        isDeleted: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('userPhoneNumber', userPhoneNumber)
            .set('userId', userId ? userId : '')
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<ProjectListItem>>(API_URL, {params});
    }

    restore(projectId: string) {
        return this.http.patch(`${API_URL}/${projectId}/restore`, {});
    }

    delete(projectId: string) {
        return this.http.delete(`${API_URL}/${projectId}`);
    }
}
