import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {FormControl, Validators} from '@angular/forms';
import {GUID_PATTERN} from '../../../../../common/validation/validation-utils';
import {merge, Subject} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';
import {debounceTime, distinctUntilChanged, first, takeUntil, tap} from 'rxjs/operators';
import {ConversationService} from '../../../../../api/services';
import {ConversationDataSource} from '../../data-sources/conversation-data-source';
import {ConversationListItemModel} from '../../../../../api/models/conversations';
import {ConversationAssignSpecialistComponent} from '../conversation-assign-specialist/conversation-assign-specialist.component';

@Component({
    selector: 'kt-conversation-list',
    templateUrl: './conversation-list.component.html',
    styleUrls: ['./conversation-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    dataSource: ConversationDataSource;
    displayedColumns = [
        'id',
        'documentId',
        'title',
        'project',
        'product',
        'isEngineering',
        'author',
        'specialist',
        'status',
        'isResolved',
        'isDeleted',
        'createdAt',
        'updatedAt',
        'actions'
    ];
    documentId = new FormControl('', Validators.pattern(GUID_PATTERN));
    isDeleted = 'false';
    private destroy$ = new Subject<void>();

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private conversationService: ConversationService) {
    }

    ngOnInit() {
        this.dataSource = new ConversationDataSource(this.conversationService);

        merge(this.sort.sortChange, this.paginator.page).pipe(
            takeUntil(this.destroy$),
            tap(() => this.load())
        ).subscribe();

        this.documentId.valueChanges.pipe(
            takeUntil(this.destroy$),
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => {
                if (this.documentId.invalid) {
                    this.documentId.markAsTouched();
                    return;
                }

                this.filter();
            })
        ).subscribe();
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        const documentId = this.documentId.valid ? this.documentId.value : '';
        this.dataSource.load(
            documentId,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    close(conversation: ConversationListItemModel) {
        const dialogRef = this.layoutUtilsService.confirmAction('Закрытие',
            'Вы действительно хотите закрыть чат?', 'Закрытие чата...', 'Закрыть чат');
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.conversationService.close(conversation.id)
                    .pipe(first())
                    .subscribe(
                        () => {
                            this.layoutUtilsService.showActionNotification('Чат был закрыт.',
                                MessageType.Delete, 3000, true, false);
                            this.load();
                        },
                        () => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при закрытии чата.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });
    }

    assignSpecialist(conversation: ConversationListItemModel) {
        const saveMessage = conversation.specialist ? 'Новый специалист назначен' : 'Специалист назначен';
        const dialogRef = this.dialog.open(ConversationAssignSpecialistComponent, {data: {conversation}, width: '600px'});

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 1000, true, false);
            this.load();
        });
    }

    restore(id: number) {
        this.conversationService.restore(id)
            .pipe(first())
            .subscribe(
                () => {
                    this.layoutUtilsService.showActionNotification('Чат был восстановлен.',
                        MessageType.Update, 3000, true, false);
                    this.load();
                },
                () => {
                    this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении чата.',
                        MessageType.Update, 3000, true, false);
                }
            );
    }

    delete(id: number) {
        const dialogRef = this.layoutUtilsService.deleteElement('Удаление',
            'Вы действительно хотите удалить чат?', 'Удаление чата...');
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.conversationService.delete(id)
                    .pipe(first())
                    .subscribe(
                        () => {
                            this.layoutUtilsService.showActionNotification('Чат был удалён.',
                                MessageType.Delete, 3000, true, false);
                            this.load();
                        },
                        () => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении чата.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });
    }
}
