import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Service, ServiceListItem } from '../models/services';
import { PagedResponse } from '../models/paged-response.interface';

const API_URL = 'api/services';

@Injectable()
export class ServiceService {
    constructor(private http: HttpClient) { }

    get(
		searchValue: string,
		languageId: number,
		isPublished: boolean,
		isDeleted: boolean,
		sortField: string,
		sortDirection: string,
		pageIndex: number,
		pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('languageId', languageId.toString())
            .set('isPublished', isPublished === true ? 'true' : isPublished === false ? 'false' : '')
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<ServiceListItem>>(API_URL, { params: params });
    }

    getById(serviceId: string) {
        return this.http.get<Service>(`${API_URL}/${serviceId}`);
    }

    restore(serviceId: string) {
        return this.http.patch(`${API_URL}/${serviceId}/restore`, {});
    }

    delete(serviceId: string) {
        return this.http.delete(`${API_URL}/${serviceId}`);
    }

    add(title: string, description: string, languageId: number, url: string, orderIndex: number) {
        return this.http.post<Service>(`${API_URL}/`, {
            title,
            description,
            languageId,
			url,
			orderIndex
        });
    }

    update(serviceId: string, title: string, description: string, languageId: number, url: string, orderIndex: number) {
        return this.http.put(`${API_URL}`, {
            id: serviceId,
            title,
            description,
            languageId,
			url,
			orderIndex
        });
    }

    updateCover(serviceId: string, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put(`${API_URL}/${serviceId}/cover`, formData);
    }

    publish(serviceId: string) {
        return this.http.patch(`${API_URL}/${serviceId}/publish`, {});
    }

    unpublish(serviceId: string) {
        return this.http.patch(`${API_URL}/${serviceId}/unpublish`, {});
    }
}
