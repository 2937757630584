import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { PromoCodeService } from '../../../../../api/services';

@Component({
  selector: 'kt-promo-code-edit',
  templateUrl: './promo-code-edit.component.html',
  styleUrls: ['./promo-code-edit.component.scss']
})
export class PromoCodeEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PromoCodeEditComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private promoCodeService: PromoCodeService
  ) { }

  minExpirationDate: Date;
  maxExpirationDate: Date;
  
  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  promoCodeId: string;
  promoCode: string;

  form: FormGroup;

  ngOnInit() {
    this.minExpirationDate = new Date();
    this.maxExpirationDate = new Date(this.minExpirationDate.getFullYear() + 10, 11, 31);

    this.promoCodeId = this.data.promoCodeId;
    this.createForm();
    this.load();
  }

  createForm() {
    this.form = this.fb.group({
      isBlocked: [false, Validators.compose([]
      )],
      expirationDate: [{ value: Date.now() }, Validators.compose([
        Validators.required]
      )]
    });
  }

  private load() {
    this.viewLoading = true;

    this.promoCodeService.getById(this.promoCodeId)
      .subscribe(promoCode => {
        this.promoCode = promoCode.code;
        this.form.controls.expirationDate.setValue(promoCode.expirationDate);
        this.form.controls.isBlocked.setValue(promoCode.isBlocked);

        this.viewLoading = false;
      });
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    const expirationDate = new Date(this.form.controls.expirationDate.value);

    expirationDate.setHours(23, 59, 59);

    this.viewLoading = true;
    this.promoCodeService.update(this.promoCodeId, expirationDate, this.form.controls.isBlocked.value)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ isEdit: false });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(this.form, controlName, validationType);
  }
}
