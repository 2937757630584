import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
import { find } from 'lodash';

import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { AdministrativeArea } from '../../../../../api/models/administrative-areas/administrative-area.interface';
import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { AdministrativeAreaService, DictionaryService } from '../../../../../api/services';
import { AdministrativeAreaDataSource } from '../../data-sources';

import { AdministrativeAreaEditComponent } from '../administrative-area-edit/administrative-area-edit.component';

@Component({
  selector: 'kt-administrative-area-list',
  templateUrl: './administrative-area-list.component.html',
  styleUrls: ['./administrative-area-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrativeAreaListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private administrativeAreaService: AdministrativeAreaService,
    private dictionaryService: DictionaryService) { }

  private subscriptions: Subscription[] = [];

  countries: ListItem[];

  dataSource: AdministrativeAreaDataSource;
  displayedColumns = ['title', '_country', 'isDeleted', 'created', 'modified', 'actions'];

  isDeleted = 'false';

  ngOnInit() {
    this.dataSource = new AdministrativeAreaDataSource(this.administrativeAreaService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);

    this.dictionaryService.getCountries()
      .subscribe(countries => {
        this.countries = countries;
      });
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  add() {
    this.edit({
      id: null,
      name: null,
      title: null,
      countryId: null,
      isDeleted: null,
      created: null,
      modified: null
    });
  }

  edit(administrativeArea: AdministrativeArea) {
    const saveMessage = administrativeArea.id ? 'Регион обновлён' : 'Регион добавлен';
    const messageType = administrativeArea.id ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(AdministrativeAreaEditComponent, { data: { administrativeArea: administrativeArea }, width: '600px' });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.layoutUtilsService.showActionNotification(saveMessage, messageType, 1000, true, false);
      this.load();
    });
  }


  restore(administrativeArea: AdministrativeArea) {
    this.administrativeAreaService.restore(administrativeArea.id)
      .subscribe(
        response => {
          this.layoutUtilsService.showActionNotification('Регион был восстановлен.', MessageType.Update, 3000, true, false);
          this.load();
        },
        error => {
          this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении региона.', MessageType.Update, 3000, true, false);
        }
      );
  }

  delete(administrativeArea: AdministrativeArea) {
    const dialogRef = this.layoutUtilsService.deleteElement('Удаление', 'Вы действительно хотите удалить регион?', 'Удаление региона...');
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.administrativeAreaService.delete(administrativeArea.id)
          .subscribe(
            response => {
              this.layoutUtilsService.showActionNotification('Регион был удалён.', MessageType.Delete, 3000, true, false);
              this.load();
            },
            error => {
              this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении региона.', MessageType.Update, 3000, true, false);
            }
          );
      });
  }

  getCountryStr(administrativeArea: AdministrativeArea): string {
    const _country = find(this.countries, (country: ListItem) => country.id === administrativeArea.countryId);
    if (_country) {
      return _country.title;
    } else {
      return '';
    }
  }
}
