import {of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {PagedResponse} from '../../../../api/models/paged-response.interface';
import {BaseDataSource} from '../../../../common/data/base-data-source';
import {AccessTokenService} from '../../../../api/services/access-token.service';
import {AccessTokenListItemModel} from '../../../../api/models/access-tokens';

export class AccessTokenDataSource extends BaseDataSource<AccessTokenListItemModel> {

    constructor(private accessTokenService: AccessTokenService) {
        super();
    }

    load(searchValue: string,
         isDeleted: boolean,
         sortField: string,
         sortDirection: string,
         pageIndex: number,
         pageSize: number) {
        this.loadingSubject.next(true);

        const subscription = this.accessTokenService.get(searchValue, isDeleted, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<AccessTokenListItemModel>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
        this.subscriptions.push(subscription);
    }
}
