import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PromoCodeListItem, PromoCode, PromoCodeType } from '../models/promo-codes';
import { PagedResponse } from '../models/paged-response.interface';

const API_URL = 'api/promo-codes';

@Injectable()
export class PromoCodeService {
    constructor(private http: HttpClient) { }

    get(searchValue: string, userId: string, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        const params = new HttpParams()
            .set('code', searchValue)
            .set('userId', userId ? userId : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<PromoCodeListItem>>(API_URL, { params: params });
    }

    getById(promoCodeId: string) {
        return this.http.get<PromoCode>(`${API_URL}/${promoCodeId}`);
    }

    upload(type: PromoCodeType, expirationDate: Date, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('expirationDate', expirationDate.toUTCString());
        return this.http.post(`${API_URL}/${type}`, formData);
    }

    update(promoCodeId: string, expirationDate: Date, isBlocked: boolean) {
        return this.http.put(API_URL, {
            id: promoCodeId,
            expirationDate: expirationDate,
            isBlocked: isBlocked
        });
    }
}
