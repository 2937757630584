import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
import { find } from 'lodash';

import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { AdministratorService, DictionaryService } from '../../../../../api/services';
import { AdministratorDataSource } from '../../data-sources';

@Component({
  selector: 'kt-administrator-list',
  templateUrl: './administrator-list.component.html',
  styleUrls: ['./administrator-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministratorListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private administratorService: AdministratorService,
    private dictionaryService: DictionaryService) { }

  private subscriptions: Subscription[] = [];

  dataSource: AdministratorDataSource;
  displayedColumns = [
    'lastName',
    'firstName',
    'middleName',
    'position',
    '_language',
    'phoneNumber',
    'email',
    'isBlocked',
    'isDeleted',
    'created',
    'modified',
    'actions'
  ];
  private languages: ListItem[] = [];
  isDeleted = 'false';

  ngOnInit() {
    this.dataSource = new AdministratorDataSource(this.administratorService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);

    this.dictionaryService.getLanguages()
      .subscribe(languages => {
        this.languages = languages;
      });
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  unblock(administratorId: string) {
    this.administratorService.unblock(administratorId)
      .subscribe(
        response => {
          this.layoutUtilsService.showActionNotification('Администратор был разблокирован.', MessageType.Update, 3000, true, false);
          this.load();
        },
        error => {
          this.layoutUtilsService.showActionNotification('Произошла ошибка при разблокировке администратора.', MessageType.Update, 3000, true, false);
        }
      );
  }

  block(administratorId: string) {
    const dialogRef = this.layoutUtilsService.confirmAction('Блокировка', 'Вы действительно хотите заблокировать администратора?', 'Блокировка администратора...', 'Заблокировать');
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.administratorService.block(administratorId)
          .subscribe(
            response => {
              this.layoutUtilsService.showActionNotification('Администратор был заблокирован.', MessageType.Update, 3000, true, false);
              this.load();
            },
            error => {
              this.layoutUtilsService.showActionNotification('Произошла ошибка при блокировке администратора.', MessageType.Update, 3000, true, false);
            }
          );
      });
  }

  restore(administratorId: string) {
    this.administratorService.restore(administratorId)
      .subscribe(
        response => {
          this.layoutUtilsService.showActionNotification('Администратор был восстановлен.', MessageType.Update, 3000, true, false);
          this.load();
        },
        error => {
          this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении администратора.', MessageType.Update, 3000, true, false);
        }
      );
  }

  delete(administratorId: string) {
    const dialogRef = this.layoutUtilsService.deleteElement('Удаление', 'Вы действительно хотите удалить администратора?', 'Удаление администратора...');
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.administratorService.delete(administratorId)
          .subscribe(
            response => {
              this.layoutUtilsService.showActionNotification('Администратор был удалён.', MessageType.Delete, 3000, true, false);
              this.load();
            },
            error => {
              this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении администратора.', MessageType.Update, 3000, true, false);
            }
          );
      });
  }

  getLanguageStr(languageId: number): string {
    const language = find(this.languages, (language: ListItem) => language.id === languageId);
    if (language) {
      return language.title;
    } else {
      return '';
    }
  }
}
