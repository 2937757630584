// Angular
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'kt-confirmation-dialog',
	templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {

	constructor(
		public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) { }

	/**
	   * Close dialog with false result
	   */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		this.dialogRef.close(true);
	}
}
