import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';

import { GUID_PATTERN } from '../../../../../common/validation/validation-utils';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { PromoCodeType } from '../../../../../api/models/promo-codes';
import { PromoCodeService } from '../../../../../api/services';
import { PromoCodeDataSource } from '../../data-sources';

import { PromoCodeEditComponent } from '../promo-code-edit/promo-code-edit.component';
import { PromoCodeUploadComponent } from '../promo-code-upload/promo-code-upload.component';

@Component({
  selector: 'kt-promo-code-list',
  templateUrl: './promo-code-list.component.html',
  styleUrls: ['./promo-code-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromoCodeListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private promoCodeService: PromoCodeService) { }

  private subscriptions: Subscription[] = [];

  dataSource: PromoCodeDataSource;
  displayedColumns = ['code', 'type', 'userPhoneNumber', 'isBlocked', 'expirationDate', 'created', 'modified', 'actions'];

  ngOnInit() {
    this.dataSource = new PromoCodeDataSource(this.promoCodeService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  getPromoCodeTypeTitle(type: PromoCodeType): string {
    switch (type) {
      case PromoCodeType.Registration:
        return 'Регистрация';
      case PromoCodeType.Documents:
        return 'Первые 10 документов';
      default:
        return '';
    }
  }

  add() {
    const saveMessage = 'Промокоды загружены';
    const messageType = MessageType.Create;
    const dialogRef = this.dialog.open(PromoCodeUploadComponent, { data: {}, width: '600px' });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.layoutUtilsService.showActionNotification(saveMessage, messageType, 1000, true, false);
      this.load();
    });
  }

  edit(promoCodeId: string): void {
    const saveMessage = 'Промокоды обновлён';
    const messageType = MessageType.Create;
    const dialogRef = this.dialog.open(PromoCodeEditComponent, { data: {promoCodeId: promoCodeId}, width: '600px' });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.layoutUtilsService.showActionNotification(saveMessage, messageType, 1000, true, false);
      this.load();
    });
  }
}
