import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';

import {Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';

import {BehaviorSubject, Observable} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {
    getErrorMessage,
    hasErrorCode,
    isFormGroupControlHasError,
    markFormGroupTouched,
    setFormError
} from '../../../../../common/validation/validation-utils';

import {ProfilePasswordEditComponent} from '../profile-password-edit/profile-password-edit.component';
import {AuthService, User, UserRequested} from '../../../../../core/auth';

@Component({
    selector: 'kt-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

    form: FormGroup;
    hasFormErrors = false;
    errorMessage = '';
    loading$: Observable<boolean>;
    private user: User = null;
    private loadingSubject = new BehaviorSubject<boolean>(true);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private fb: FormBuilder,
        private store: Store<AppState>,
        private cdr: ChangeDetectorRef,
        private layoutUtilsService: LayoutUtilsService,
        private authService: AuthService) {
    }

    ngOnInit() {
        this.createForm();
        this.load();
    }

    save() {
        this.hasFormErrors = false;

        if (this.form.invalid) {
            markFormGroupTouched(this.form);
            return;
        }

        const user = this.prepare();
        this.update(user);
    }

    changePassword() {
        const dialogRef = this.dialog.open(ProfilePasswordEditComponent, {width: '600px'});

        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }
            this.layoutUtilsService.showActionNotification('Пароль успешно изменён', MessageType.Update, 1000, true, false);
            this.load();
        });
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        return isFormGroupControlHasError(this.form, controlName, validationType);
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
        this.errorMessage = '';
    }

    private createForm() {
        this.form = this.fb.group({
            lastName: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(50)]
            )],
            firstName: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(50)]
            )],
            middleName: [null, Validators.maxLength(50)],
            position: [null, Validators.maxLength(250)],
            phoneNumber: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(20)]
            )],
            email: [null],
        });
    }

    private load() {
        this.loading$ = this.loadingSubject.asObservable();
        this.loadingSubject.next(true);
        this.authService.getUserByToken().subscribe(user => {
            this.user = user;
            this.fill();
            this.loadingSubject.next(false);
        });
    }

    private fill() {
        const controls = this.form.controls;
        controls.lastName.setValue(this.user.lastName);
        controls.firstName.setValue(this.user.firstName);
        controls.middleName.setValue(this.user.middleName);
        controls.position.setValue(this.user.position);
        controls.phoneNumber.setValue(this.user.phoneNumber);
        controls.email.setValue(this.user.email);
        controls.email.disable();

        this.cdr.markForCheck();
    }

    private prepare(): User {
        const controls = this.form.controls;
        return {
            id: this.user.id,
            username: this.user.username,
            fullname: this.user.fullname,
            firstName: controls.firstName.value,
            lastName: controls.lastName.value,
            middleName: controls.middleName.value,
            position: controls.position.value,
            phoneNumber: controls.phoneNumber.value,
            email: this.user.email,
        };
    }

    private update(user: User) {
        this.loadingSubject.next(true);
        this.authService.updateUser(user)
            .subscribe(
                response => {
                    this.store.dispatch(new UserRequested());
                    this.loadingSubject.next(false);
                    this.redirect();
                },
                errorResponse => {
                    this.loadingSubject.next(false);
                    if (hasErrorCode(errorResponse)) {
                        this.hasFormErrors = true;
                        this.errorMessage = getErrorMessage(errorResponse);
                    } else {
                        setFormError(this.form, errorResponse);
                    }
                    this.cdr.markForCheck();
                }
            );
    }

    private redirect() {
        this.layoutUtilsService.showActionNotification('Профиль обновлён', MessageType.Update, 3000, true, false);
        this.router.navigate(['../'], {relativeTo: this.route});
    }
}

