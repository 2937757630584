import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { LayoutUtilsService } from '../../../../../core/_base/crud';

import { Suggestion } from '../../../../../api/models/suggestions';
import { SuggestionService, DictionaryService } from '../../../../../api/services';

@Component({
  selector: 'kt-suggestion-detail',
  templateUrl: './suggestion-detail.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./suggestion-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionDetailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private suggestionService: SuggestionService
  ) { }

  loading$: Observable<boolean>;
  selectedTabIndex = 0;
  comment: string;
  authorName: string;
  isDeleted: boolean;
  imagesUrls: string[];

  private subscriptions: Subscription[] = [];
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private suggestionId: string = null;
  private suggestion: Suggestion = null;

  ngOnInit() {
    const routeSubscription = this.route.params.subscribe(params => {
      const suggestionId = params['suggestionId'];
      if (suggestionId !== 'new')
        this.suggestionId = suggestionId;
      this.load();
    });

    this.subscriptions.push(routeSubscription);
  }

  openImageInNewTab(image: string) {
	const image_window = window.open("", "_blank");
	image_window.document.write(`
		<html>
			<body>
				<img src="${window.location.origin + image}" alt="image" width="100%">
			</body>
		</html>
	`);
  }

  private load() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);
    if (this.suggestionId) {
      forkJoin([
        this.suggestionService.getById(this.suggestionId),
      ])
        .subscribe(data => {
          this.suggestion = data[0];
          this.fill();
          this.loadingSubject.next(false);
        })
    }
  }

  private fill() {
    if (!this.suggestion) {
      return;
    }

	this.comment = this.suggestion.comment;
	this.authorName = this.suggestion.authorName;
	this.isDeleted = this.suggestion.isDeleted;
	this.imagesUrls = this.suggestion.imagesUrls;

    this.cdr.markForCheck();
  }
}
