// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//Modules
import { ApiModule } from '../../../api/api.module';
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { MaterialModule } from '../../../common/modules/material.module';
// Services
import {
  HttpUtilsService,
  TypesUtilsService,
  LayoutUtilsService
} from '../../../core/_base/crud';
import {
  ActionNotificationComponent,
  ConfirmationDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent
} from '../../../views/partials/content/crud';
// Components
import { DocumentListComponent } from './documents/document-list/document-list.component';

@NgModule({
  declarations: [DocumentListComponent],
  exports: [],
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    FormsModule,

    ApiModule,
    CoreModule,
    PartialsModule,

    MaterialModule
  ],
  providers: [
    TypesUtilsService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService
  ],
  entryComponents: [
    ActionNotificationComponent,
    ConfirmationDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
  ]
})
export class DocumentsModule { }
