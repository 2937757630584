import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
import { each, find } from 'lodash';

import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { Contact } from '../../../../../api/models/contacts/contact.interface';
import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { ContactService, DictionaryService } from '../../../../../api/services';
import { ContactDataSource } from '../../data-sources';
import { PersistentComponent, RestoredState, SavingState, StateService } from '../../../../../common/data/state.service';

@Component({
  selector: 'kt-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactListComponent implements OnInit, AfterViewInit, OnDestroy, PersistentComponent {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private contactService: ContactService,
    private dictionaryService: DictionaryService,
    private stateService: StateService) { }

  private subscriptions: Subscription[] = [];

  dataSource: ContactDataSource;
  displayedColumns = [
    '_photo',
    'lastName',
    'company',
    'position',
    'phoneNumber',
    'email',
    '_country',
    '_administrativeAreas',
    'isDeleted',
    'created',
    'modified',
    'actions'
  ];

  countries: ListItem[];
  countryId: number = null;
  administrativeAreas: ListItem[];
  administrativeAreaId: string = null;
  isDeleted = 'false';
  sortActive = 'lastName';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 10;

  ngOnInit() {
    this.dataSource = new ContactDataSource(this.contactService);

    this.stateService.restore(this);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);

    this.dictionaryService.getCountries()
      .subscribe(countries => {
        this.countries = countries;
      });

    this.dictionaryService.getAdministrativeAreas()
      .subscribe(administrativeAreas => {
        this.administrativeAreas = administrativeAreas;
      });
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.stateService.save(this);
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  private load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.countryId,
      this.administrativeAreaId,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  restore(contactId: number) {
    this.contactService.restore(contactId)
      .subscribe(
        response => {
          this.layoutUtilsService.showActionNotification('Контакт был восстановлен.', MessageType.Update, 3000, true, false);
          this.load();
        },
        error => {
          this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении контакта.', MessageType.Update, 3000, true, false);
        }
      );
  }

  delete(contactId: number) {
    const dialogRef = this.layoutUtilsService.deleteElement('Удаление', 'Вы действительно хотите удалить контакт?', 'Удаление контакта...');
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.contactService.delete(contactId)
          .subscribe(
            response => {
              this.layoutUtilsService.showActionNotification('Контакт был удалён.', MessageType.Delete, 3000, true, false);
              this.load();
            },
            error => {
              this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении контакта.', MessageType.Update, 3000, true, false);
            }
          );
      });
  }

  getFullNameStr(contact: Contact): string {
    var nameParts: string[] = [];
    if (contact.lastName) {
      nameParts.push(contact.lastName);
    }
    if (contact.firstName) {
      nameParts.push(contact.firstName);
    }
    if (contact.middleName) {
      nameParts.push(contact.middleName);
    }
    const fullName = nameParts.join(' ');
    return fullName;
  }

  getCountryStr(contact: Contact): string {
    const contactCountryId = contact.countryIdentifiers[0];
    const _country = find(this.countries, (country: ListItem) => country.id === contactCountryId);
    if (_country) {
      return _country.title;
    } else {
      return '';
    }
  }

  getAdministrativeAreasStr(contact: Contact): string {
    const titles: string[] = [];
    each(contact.administrativeAreaIdentifiers, (administrativeAreaId: number) => {
      const _administrativeArea = find(this.administrativeAreas, (administrativeArea: ListItem) => administrativeArea.id === administrativeAreaId);
      if (_administrativeArea) {
        titles.push(_administrativeArea.title);
      }
    });
    return titles.join('\n');
  }

  saveState(state: SavingState) {
    state
      .set('name', this.searchInput.nativeElement.value)
      .set('countryId', this.countryId)
      .set('administrativeAreaId', this.administrativeAreaId)
      .set('isDeleted', this.isDeleted)
      .set('sortActive', this.sort.active)
      .set('sortDirection', this.sort.direction)
      .set('pageIndex', this.paginator.pageIndex)
      .set('pageSize', this.paginator.pageSize);
  }

  restoreState(state: RestoredState) {
    this.searchInput.nativeElement.value = state.get('name');
    this.countryId = state.get('countryId');
    this.administrativeAreaId = state.get('administrativeAreaId');
    this.isDeleted = state.get('isDeleted');
    this.sortActive = state.get('sortActive');
    this.sortDirection = state.get('sortDirection');
    this.pageIndex = state.get('pageIndex');
    this.pageSize = state.get('pageSize');
  }
}
