import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PersistentComponent, RestoredState, SavingState, StateService} from '../../../../../common/data/state.service';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {ProductDataSource} from '../../data-sources';
import {ListItem} from '../../../../../api/models/dictionaries/list-item.interface';
import {Template} from '../../../../../api/models/templates';
import {BehaviorSubject, forkJoin, fromEvent, merge, of, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';
import {DictionaryService, ProductService, TemplateService} from '../../../../../api/services';
import {catchError, debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {SelectionModel} from '@angular/cdk/collections';
import {ProductListItem} from '../../../../../api/models/products';

@Component({
    selector: 'kt-template-products-add',
    templateUrl: './template-products-add.component.html',
    styleUrls: ['./template-products-add.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateProductsAddComponent implements OnInit, AfterViewInit, OnDestroy, PersistentComponent {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    dataSource: ProductDataSource;
    selection = new SelectionModel<ProductListItem>(true, []);
    displayedColumns = ['select', 'image', 'name', 'direction', 'isEnabled', 'isDeleted', 'created', 'modified'];
    languages: ListItem[] = [];
    directions: ListItem[] = [];
    languageId = 1;
    directionId: number = null;
    isEnabled = '';
    isDeleted = 'false';
    sortActive = 'modified';
    sortDirection: 'asc' | 'desc' = 'desc';
    pageIndex = 0;
    pageSize = 10;
    protected template: Template;
    private destroy$ = new Subject<void>();
    private templateId: string;

    // private readonly loadingSubject = new BehaviorSubject<boolean>(true);
    // protected loading$ = this.loadingSubject.asObservable();

    private readonly savingSubject = new BehaviorSubject<boolean>(false);
    protected saving$ = this.savingSubject.asObservable();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private productService: ProductService,
        private templateService: TemplateService,
        private dictionaryService: DictionaryService,
        private stateService: StateService) {
    }

    ngOnInit() {
        this.templateId = this.route.snapshot.params.templateId;
        this.dataSource = new ProductDataSource(this.productService);

        this.stateService.restore(this);

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.load());

        fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(150),
                distinctUntilChanged(),
            ).subscribe(() => this.filter());

        this.dictionaryService.getLanguages()
            .pipe(takeUntil(this.destroy$))
            .subscribe(languages => {
                this.languages = languages;
            });

        this.dictionaryService.getDirections()
            .pipe(takeUntil(this.destroy$))
            .subscribe(directions => {
                this.directions = directions;
            });

        this.templateService.getById(this.templateId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(template => {
                this.template = template;
            });
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy() {
        this.stateService.save(this);
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        this.dataSource.load(
            this.searchInput.nativeElement.value,
            this.languageId,
            this.directionId,
            this.templateId,
            this.isEnabled === 'true' ? true : this.isEnabled === 'false' ? false : null,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
    }

    add() {
        this.savingSubject.next(true);

        forkJoin(
            this.selection.selected.map(product =>
                this.templateService.addProduct(this.templateId, product.id)
                    .pipe(
                        takeUntil(this.destroy$),
                        map(_ => {
                            return {err: null, id: product.id};
                        }),
                        catchError((err, _) => {
                            return of({err, id: product.id});
                        }),
                    )
            )
        ).subscribe((results) => {
            this.savingSubject.next(false);

            const errors = results.filter(r => r.err != null);

            const added = this.selection.selected.length - errors.length;
            this.layoutUtilsService.showActionNotification(`Добавлено ${added} систем из ${this.selection.selected.length}`,
                MessageType.Create,
                3000,
                true,
                false);
            this.router.navigate(['../'], {relativeTo: this.route}).then();
        });


        // this.loadingSubject.next(true);
    }

    saveState(state: SavingState) {
        state
            .set('name', this.searchInput.nativeElement.value)
            .set('languageId', this.languageId)
            .set('directionId', this.directionId)
            .set('isEnabled', this.isEnabled)
            .set('isDeleted', this.isDeleted)
            .set('sortActive', this.sort.active)
            .set('sortDirection', this.sort.direction)
            .set('pageIndex', this.paginator.pageIndex)
            .set('pageSize', this.paginator.pageSize);
    }

    restoreState(state: RestoredState) {
        this.searchInput.nativeElement.value = state.get('name');
        this.languageId = state.get('languageId');
        this.directionId = state.get('directionId');
        this.isEnabled = state.get('isEnabled');
        this.isDeleted = state.get('isDeleted');
        this.sortActive = state.get('sortActive');
        this.sortDirection = state.get('sortDirection');
        this.pageIndex = state.get('pageIndex');
        this.pageSize = state.get('pageSize');
    }

}
