// Angular
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// Modules
import {ApiModule} from '../../../api/api.module';
import {CoreModule} from '../../../core/core.module';
import {PartialsModule} from '../../partials/partials.module';
import {MaterialModule} from '../../../common/modules/material.module';
// Services
import {HttpUtilsService, LayoutUtilsService, TypesUtilsService} from '../../../core/_base/crud';
import {
    ActionNotificationComponent,
    ConfirmationDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../partials/content/crud';
// Components
import {MaterialListComponent} from './materials/material-list/material-list.component';
import {MaterialEditComponent} from './materials/material-edit/material-edit.component';
import {ProductListComponent} from './products/product-list/product-list.component';
import {ProductEditComponent} from './products/product-edit/product-edit.component';
import {ProductContentEditComponent} from './products/product-content-edit/product-content-edit.component';
import {MaterialSearchComponent} from './products/material-search/material-search.component';
import {TemplateSearchComponent} from './products/template-search/template-search.component';
import {TemplateListComponent} from './templates/template-list/template-list.component';
import {TemplateEditComponent} from './templates/template-edit/template-edit.component';
import {InstructionListComponent} from './instructions/instruction-list/instruction-list.component';
import {InstructionEditComponent} from './instructions/instruction-edit/instruction-edit.component';
import {TemplateContentEditComponent} from './templates/template-content-edit/template-content-edit.component';
import {PropertyListComponent} from './templates/template-content-edit/property-list.component';
import {TemplateProductsListComponent} from './templates/template-products-list/template-products-list.component';
import { TemplateProductsAddComponent } from './templates/template-products-add/template-products-add.component';

@NgModule({
    declarations: [
        MaterialListComponent,
        MaterialEditComponent,
        ProductListComponent,
        ProductEditComponent,
        ProductContentEditComponent,
        MaterialSearchComponent,
        TemplateSearchComponent,
        TemplateListComponent,
        TemplateProductsListComponent,
        TemplateEditComponent,
        InstructionListComponent,
        InstructionEditComponent,
        TemplateContentEditComponent,
        PropertyListComponent,
        TemplateProductsAddComponent
    ],
    exports: [],
    imports: [
        RouterModule,
        CommonModule,
        HttpClientModule,
        FormsModule,

        ApiModule,
        CoreModule,
        PartialsModule,

        MaterialModule
    ],
    providers: [
        TypesUtilsService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService
    ],
    entryComponents: [
        ActionNotificationComponent,
        ConfirmationDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,

        MaterialListComponent,
        MaterialEditComponent,
        ProductContentEditComponent,
        MaterialSearchComponent,
        TemplateSearchComponent
    ]
})
export class ProductsModule {
}
