import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { AdministrativeArea } from '../../../../../api/models/administrative-areas/administrative-area.interface';
import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { AdministrativeAreaService, DictionaryService } from '../../../../../api/services';

@Component({
  selector: 'kt-administrative-area-edit',
  templateUrl: './administrative-area-edit.component.html',
  styleUrls: ['./administrative-area-edit.component.scss']
})
export class AdministrativeAreaEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdministrativeAreaEditComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private administrativeAreaService: AdministrativeAreaService,
    private dictionaryService: DictionaryService) {
  }

  countries: ListItem[];

  administrativeArea: AdministrativeArea;
  form: FormGroup;
  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  ngOnInit() {
    this.administrativeArea = this.data.administrativeArea;
    this.createForm();
    this.load();
  }

  createForm() {
    this.form = this.fb.group({
      title: [this.administrativeArea.title, Validators.compose([
        Validators.required,
        Validators.maxLength(100)]
      )],
      countryId: [this.administrativeArea.countryId, Validators.required],
      name: [this.administrativeArea.name, Validators.compose([
        Validators.required,
        Validators.maxLength(1000)]
      )],
    });
  }

  load() {
    this.viewLoading = true;
    this.dictionaryService.getCountries().subscribe(countries => {
      this.countries = countries;
      this.viewLoading = false;
    });
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    const administrativeArea = this.prepare();
    if (administrativeArea.id) {
      this.update(administrativeArea);
    } else {
      this.create(administrativeArea);
    }
  }

  prepare(): AdministrativeArea {
    const controls = this.form.controls;
    return {
      id: this.administrativeArea.id,
      name: controls.name.value,
      title: controls.title.value,
      countryId: controls.countryId.value,
      isDeleted: null,
      created: null,
      modified: null,
    };
  }

  create(administrativeArea: AdministrativeArea) {
    this.viewLoading = true;
    this.administrativeAreaService.add(administrativeArea)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ administrativeArea, isEdit: true });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  update(administrativeArea: AdministrativeArea) {
    this.viewLoading = true;
    this.administrativeAreaService.update(administrativeArea)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ administrativeArea, isEdit: true });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(this.form, controlName, validationType);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.errorMessage = '';
  }
}
