import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material';

import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {ListItem} from '../../../../../api/models/dictionaries/list-item.interface';
import {Instruction} from '../../../../../api/models/instructions';
import {DictionaryService, InstructionService} from '../../../../../api/services';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'kt-instruction-edit',
    templateUrl: './instruction-edit.component.html',
    styleUrls: ['./instruction-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstructionEditComponent implements OnInit, OnDestroy {
    loading$: Observable<boolean>;
    instructionId: string = null;
    nameFormControl = new FormControl('', [
        Validators.required,
        Validators.maxLength(250),
    ]);
    languages: ListItem[] = [];
    languageId = 1;
    directions: ListItem[];
    directionId: number | '' = '';
    coverUrl: any;
    coverHasError = false;
    hasContent = false;
    contentType: 'pdf' | 'video';
    contentFile: File = null;
    contentHasError = false;
    contentSizeHasError = false;
    isEnabled = false;
    private readonly destroy$ = new Subject<void>();
    private loadingSubject = new BehaviorSubject<boolean>(true);
    private instruction: Instruction = null;
    private coverFile: File = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private layoutUtilsService: LayoutUtilsService,
        private instructionService: InstructionService,
        private dictionaryService: DictionaryService
    ) {
    }

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(params => {
                if (params.instructionId !== 'new') {
                    this.instructionId = params.instructionId;
                }
                this.load();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onCoverChanged(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const file = files[0];

        if (file.type.match(/image\/*/) == null) {
            return;
        }

        this.coverFile = file;
        this.coverHasError = false;

        const reader: FileReader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_) => {
            this.coverUrl = reader.result;
            this.cdr.markForCheck();
        };
    }

    onContentChanged(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const file = files[0];

        if (file.type.match(/pdf|video\/*/) == null) {
            return;
        }

        this.contentFile = file;
        this.contentType = file.type.match(/pdf\/*/) != null ? 'pdf' : 'video';
        this.contentHasError = false;
        this.contentSizeHasError = file.size / 1024 / 1024 > 20;
    }

    downloadContent() {
        window.open(`/api/instructions/${this.instructionId}/content`, '_blank');
    }

    save() {
        if (this.nameFormControl.invalid) {
            this.nameFormControl.markAsTouched();
            return;
        }

        if (!this.coverUrl && !this.coverFile) {
            this.coverHasError = true;
            return;
        }

        if (this.contentSizeHasError) {
            return;
        }

        if (!this.hasContent && !this.contentFile) {
            this.contentHasError = true;
            return;
        }

        if (this.instructionId) {
            this.update();
        } else {
            this.create();
        }
    }

    private load() {
        this.loading$ = this.loadingSubject.asObservable();
        this.loadingSubject.next(true);
        forkJoin([
            this.instructionId
                ? this.instructionService.getById(this.instructionId).pipe(takeUntil(this.destroy$))
                : of<Instruction>(null),
            this.dictionaryService.getLanguages().pipe(takeUntil(this.destroy$)),
            this.dictionaryService.getDirections().pipe(takeUntil(this.destroy$))
        ]).subscribe(data => {
            this.instruction = data[0];
            this.languages = data[1];
            this.directions = data[2];
            this.fill();
            this.loadingSubject.next(false);
        });
    }

    private fill() {
        if (this.instructionId) {
            this.nameFormControl.setValue(this.instruction.name);
            this.languageId = this.instruction.languageId;
            this.directionId = this.instruction.directionId;
            this.coverUrl = this.instruction.cover ? `/api/instructions/${this.instructionId}/cover` : null;
            this.hasContent = !!this.instruction.content;
            this.contentType = this.instruction.type;
            this.isEnabled = this.instruction.isEnabled;
        }

        this.cdr.markForCheck();
    }

    private create() {
        this.loadingSubject.next(true);
        this.instructionService.add(this.nameFormControl.value, this.languageId, this.directionId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(instruction => {
                this.updateFiles(instruction.id);
            });
    }

    private update() {
        this.loadingSubject.next(true);
        this.instructionService.update(this.instruction.id, this.nameFormControl.value, this.languageId, this.directionId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.updateFiles(this.instruction.id);
            });
    }

    private updateFiles(instructionId: string) {
        const items: any[] = [];

        if (this.coverFile) {
            items.push(this.instructionService.updateCover(instructionId, this.coverFile).pipe(takeUntil(this.destroy$)));
        }

        if (this.contentFile) {
            items.push(this.instructionService.updateContent(instructionId, this.contentFile).pipe(takeUntil(this.destroy$)));
        }

        if (this.instruction && this.instruction.isEnabled !== this.isEnabled) {
            if (this.isEnabled) {
                items.push(this.instructionService.enable(instructionId).pipe(takeUntil(this.destroy$)));
            } else {
                items.push(this.instructionService.disable(instructionId).pipe(takeUntil(this.destroy$)));
            }
        }
        forkJoin(items)
            .subscribe({
                complete: () => {
                    this.loadingSubject.next(false);
                    this.reset();
                    this.redirect(instructionId);
                }
            });
    }

    private reset() {
        this.coverFile = null;
        this.contentFile = null;
    }

    private redirect(instructionId: string) {
        if (!this.instruction) {
            this.layoutUtilsService.showActionNotification('Руководство добавлено', MessageType.Create, 3000, true, false);
            this.router.navigate(['../', instructionId], {relativeTo: this.route}).then();
        } else {
            this.layoutUtilsService.showActionNotification('Руководство обновлено', MessageType.Update, 3000, true, false);
            this.router.navigate(['../'], {relativeTo: this.route}).then();
        }
    }
}
