export function getErrorCodeMessage(errorCode: string): string {
    switch (errorCode) {
        case 'DuplicateLanguageIsoCode':
            return 'Язык с таким кодом уже существует';
        case 'PhoneNumberAlreadyExists':
            return 'Такой номер уже существует в системе';
        case 'EmailAlreadyExists':
            return 'Такой Email уже существует в системе';
        case 'InvalidParameters':
            return 'Неверно заданы параметры';
        default:
            return 'Неизвестная ошибка';
    }
}
