import {of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {BaseDataSource} from '../../../../common/data/base-data-source';

import {ProductListItem} from '../../../../api/models/products';
import {ProductService} from '../../../../api/services';
import {PagedResponse} from '../../../../api/models/paged-response.interface';

export class ProductDataSource extends BaseDataSource<ProductListItem> {

    constructor(private productService: ProductService) {
        super();
    }

    load(searchValue: string,
         languageId: number,
         directionId: number,
         excludeTemplateId: string,
         isEnabled: boolean,
         isDeleted: boolean,
         sortField: string,
         sortDirection: string,
         pageIndex: number,
         pageSize: number) {
        this.loadingSubject.next(true);
        this.subscriptions.push(this.productService.get(searchValue,
            languageId,
            directionId,
            excludeTemplateId,
            isEnabled,
            isDeleted,
            sortField,
            sortDirection,
            pageIndex,
            pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<ProductListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            }));
    }
}
