import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { Template, TemplateContent } from '../../../../../api/models/templates';
import { DictionaryService, TemplateService } from '../../../../../api/services';
import { TemplateContentGroup } from '../models/template-content-group.interface';

@Component({
  selector: 'kt-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateEditComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private templateService: TemplateService,
    private dictionaryService: DictionaryService
  ) { }

  loading$: Observable<boolean>;
  titleFormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(100),
  ]);
  templateId: string = null;
  documentTypeId = 1;
  isEngineering: boolean = false;
  coverUrl: any;
  coverHasError = false;
  hasIllustrations = false;
  illustrationsFile: File = null;
  contentGroups: TemplateContentGroup[] = [];
  contentColumns = ['version', 'status', 'created', 'modified', 'actions'];

  private subscriptions: Subscription[] = [];
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private languages: ListItem[] = [];
  private template: Template = null;
  private coverFile: File = null;

  ngOnInit() {
    const routeSubscription = this.route.params.subscribe(params => {
      const templateId = params['templateId'];
      if (templateId !== 'new')
        this.templateId = templateId;
      this.load();
    });

    this.subscriptions.push(routeSubscription);
  }

  private load() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);
    if (this.templateId) {
      forkJoin([
        this.templateService.getById(this.templateId),
        this.dictionaryService.getLanguages()
      ])
        .subscribe(data => {
          this.template = data[0];
          this.languages = data[1];
          this.fill();
          this.loadingSubject.next(false);
        })
    }
    else {
      this.dictionaryService.getLanguages()
        .subscribe(languages => {
          this.languages = languages;
          this.fill();
          this.loadingSubject.next(false);
        });
    }
  }

  private fill() {
    if (this.templateId) {
      this.titleFormControl.setValue(this.template.title);
      this.documentTypeId = this.template.documentTypeId;
      this.isEngineering = this.template.isEngineering;
      this.coverUrl = this.template.coverUrl;
      this.hasIllustrations = this.template.illustrationsUrl ? true : false;
      this.fillContents(this.template.contents);
    }

    this.cdr.markForCheck();
  }

  private fillContents(contents: TemplateContent[]) {
    const contentGroups: TemplateContentGroup[] = [];
    this.languages.forEach(language => {
      const languageGroup = this.contentGroups.filter(group => group.language.id === language.id)[0];
      const items = contents.filter(content => content.languageId === language.id);
      items.sort((a, b) => b.version - a.version);
      const previousStatuses = new Set<string>();
      items.forEach(content => {
        const status = content.status;
        if (previousStatuses.has(status)) {
          content.status = 'deleted';
        } else {
          previousStatuses.add(status);
          previousStatuses.add('new');
        }
      });
      contentGroups.push({
        language: language,
        items: items,
        isExpanded: languageGroup ? languageGroup.isExpanded : false,
      });
    });
    this.contentGroups = contentGroups;
  }

  private refreshContents() {
    this.loadingSubject.next(true);
    this.templateService.getContents(this.templateId).subscribe(data => {
      this.fillContents(data);
      this.loadingSubject.next(false);
      this.cdr.markForCheck();
    });
  }

  onCoverChanged(files: FileList) {
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (file.type.match(/image\/*/) == null) {
      return;
    }

    this.coverFile = file;
    this.coverHasError = false;

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.coverUrl = reader.result;
      this.cdr.markForCheck();
    }
  }

  onIllustrationsChanged(files: FileList) {
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (file.type.match(/zip\/*/) == null) {
      return;
    }

    this.illustrationsFile = file;
  }

  save() {
    if (this.titleFormControl.invalid) {
      this.titleFormControl.markAsTouched();
      return;
    }

    if (!this.coverUrl && !this.coverFile) {
      this.coverHasError = true;
      return;
    }

    if (this.templateId) {
      this.update();
    } else {
      this.create();
    }
  }

  private create() {
    this.loadingSubject.next(true);
    this.templateService.add(this.titleFormControl.value, this.documentTypeId, this.isEngineering)
      .subscribe(template => {
        this.updateFiles(template.id);
      });
  }

  private update() {
    this.loadingSubject.next(true);
    this.templateService.update(this.template.id, this.titleFormControl.value, this.documentTypeId, this.isEngineering)
      .subscribe(() => {
        this.updateFiles(this.template.id);
      });
  }

  private updateFiles(templateId: string) {
    const items: any[] = [];

    if (this.coverFile) {
      items.push(this.templateService.updateCover(templateId, this.coverFile));
    }

    if (this.illustrationsFile) {
      items.push(this.templateService.updateIllustrations(templateId, this.illustrationsFile));
    }

    forkJoin(items)
      .subscribe({
        complete: () => {
          this.loadingSubject.next(false);
          this.reset();
          this.redirect(templateId);
        }
      });
  }

  downloadIllustrations() {
    window.open(`/api/templates/${this.templateId}/illustrations`, '_blank');
  }

  addContent(languageId: number, files: FileList) {
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (file.type.match(/json\/*/) == null) {
      return;
    }

    this.loadingSubject.next(true);
    this.templateService.addContent(this.templateId, languageId, file)
      .subscribe(
        response => {
          this.loadingSubject.next(false);
          this.layoutUtilsService.showActionNotification('Файл шаблона был добавлен.', MessageType.Update, 3000, true, false);
          this.refreshContents();
        },
        error => {
          this.loadingSubject.next(false);
          this.layoutUtilsService.showActionNotification('Произошла ошибка при добавлении файла шаблона.', MessageType.Update, 3000, true, false);
        }
      );
  }

  createContent(languageId: number) {
    this.router.navigate(['./contents/new'], { relativeTo: this.route, queryParams: { languageId } });
  }

  downloadContent(contentId: string) {
    window.open(`/api/templates/${this.templateId}/contents/${contentId}`, '_blank');
  }

  editContent(contentId: string, languageId: number) {
    this.router.navigate(['./contents/', contentId], { relativeTo: this.route, queryParams: { languageId } });
  }

  publishContent(contentId: string) {
    this.templateService.publishContent(this.templateId, contentId)
      .subscribe(
        response => {
          this.loadingSubject.next(false);
          this.layoutUtilsService.showActionNotification('Файл шаблона был опубликован.', MessageType.Update, 3000, true, false);
          this.refreshContents();
        },
        error => {
          this.loadingSubject.next(false);
          this.layoutUtilsService.showActionNotification('Произошла ошибка при публикации файла шаблона.', MessageType.Update, 3000, true, false);
        }
      );
  }

  allowContentTesting(contentId: string) {
    this.templateService.allowContentTesting(this.templateId, contentId)
      .subscribe(
        response => {
          this.loadingSubject.next(false);
          this.layoutUtilsService.showActionNotification('Файл шаблона был отправлен на тестирование.', MessageType.Update, 3000, true, false);
          this.refreshContents();
        },
        error => {
          this.loadingSubject.next(false);
          this.layoutUtilsService.showActionNotification('Произошла ошибка при отправке файла шаблона на тестирование.', MessageType.Update, 3000, true, false);
        }
      );
  }

  getStatusString(status: string): string {
    switch (status) {
      case 'new':
        return 'Новый';
      case 'testing':
        return 'На тестировании';
      case 'published':
        return 'Опубликован';
      case 'deleted':
        return 'Удалён';
      default:
        return '';
    }
  }

  private reset() {
    this.coverFile = null;
    this.illustrationsFile = null;
  }

  private redirect(templateId: string) {
    if (!this.template) {
      this.layoutUtilsService.showActionNotification('Шаблон добавлен', MessageType.Create, 3000, true, false);
      this.router.navigate(['../', templateId], { relativeTo: this.route });
    } else {
      this.layoutUtilsService.showActionNotification('Шаблон обновлён', MessageType.Update, 3000, true, false);
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
}
