import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';

import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {fromEvent, merge, Subject} from 'rxjs';

import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {ListItem} from '../../../../../api/models/dictionaries/list-item.interface';
import {DictionaryService, TemplateService} from '../../../../../api/services';
import {PersistentComponent, RestoredState, SavingState, StateService} from '../../../../../common/data/state.service';
import {TemplateProductDataSource} from '../../data-sources';
import {ActivatedRoute} from '@angular/router';
import {Template} from '../../../../../api/models/templates';

@Component({
    selector: 'kt-template-products-list',
    templateUrl: './template-products-list.component.html',
    styleUrls: ['./template-products-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateProductsListComponent implements OnInit, AfterViewInit, OnDestroy, PersistentComponent {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    dataSource: TemplateProductDataSource;
    displayedColumns = ['image', 'name', 'direction', 'isEnabled', 'isDeleted', 'created', 'modified', 'actions'];
    languages: ListItem[] = [];
    directions: ListItem[] = [];
    languageId = 1;
    directionId: number = null;
    isEnabled = '';
    isDeleted = 'false';
    sortActive = 'modified';
    sortDirection: 'asc' | 'desc' | '' = 'desc';
    pageIndex = 0;
    pageSize = 10;
    protected template: Template;
    private destroy$ = new Subject<void>();
    private templateId: string;

    constructor(
        private route: ActivatedRoute,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private templateService: TemplateService,
        private dictionaryService: DictionaryService,
        private stateService: StateService) {
    }

    ngOnInit() {
        this.templateId = this.route.snapshot.params.templateId;
        this.dataSource = new TemplateProductDataSource(this.templateService);

        this.stateService.restore(this);

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.load());

        fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(150),
                distinctUntilChanged(),
            ).subscribe(() => this.filter());

        this.dictionaryService.getLanguages()
            .pipe(takeUntil(this.destroy$))
            .subscribe(languages => {
                this.languages = languages;
            });

        this.dictionaryService.getDirections()
            .pipe(takeUntil(this.destroy$))
            .subscribe(directions => {
                this.directions = directions;
            });

        this.templateService.getById(this.templateId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(template => {
                this.template = template;
            });
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy() {
        this.stateService.save(this);
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        this.dataSource.load(
            this.templateId,
            this.searchInput.nativeElement.value,
            this.languageId,
            this.directionId,
            this.isEnabled === 'true' ? true : this.isEnabled === 'false' ? false : null,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
    }

    remove(productId: string) {
        const dialogRef = this.layoutUtilsService.deleteElement('Удаление',
            'Вы действительно хотите удалить систему из шаблона?', 'Удаление системы из шаблона...');
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.templateService.removeProduct(this.templateId, productId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        _ => {
                            this.layoutUtilsService.showActionNotification('Система была удалена.',
                                MessageType.Delete, 3000, true, false);
                            this.load();
                        },
                        _ => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении системы.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });
    }

    saveState(state: SavingState) {
        state
            .set('name', this.searchInput.nativeElement.value)
            .set('languageId', this.languageId)
            .set('directionId', this.directionId)
            .set('isEnabled', this.isEnabled)
            .set('isDeleted', this.isDeleted)
            .set('sortActive', this.sort.active)
            .set('sortDirection', this.sort.direction)
            .set('pageIndex', this.paginator.pageIndex)
            .set('pageSize', this.paginator.pageSize);
    }

    restoreState(state: RestoredState) {
        this.searchInput.nativeElement.value = state.get('name');
        this.languageId = state.get('languageId');
        this.directionId = state.get('directionId');
        this.isEnabled = state.get('isEnabled');
        this.isDeleted = state.get('isDeleted');
        this.sortActive = state.get('sortActive');
        this.sortDirection = state.get('sortDirection');
        this.pageIndex = state.get('pageIndex');
        this.pageSize = state.get('pageSize');
    }
}
