import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { forkJoin } from 'rxjs';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { Material } from '../../../../../api/models/materials';
import { MaterialService, DictionaryService } from '../../../../../api/services';

@Component({
  selector: 'kt-material-edit',
  templateUrl: './material-edit.component.html',
  styleUrls: ['./material-edit.component.scss']
})
export class MaterialEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MaterialEditComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private materialService: MaterialService,
    private dictionaryService: DictionaryService) {
  }

  private defaultLanguageId = 1;
  private material: Material;
  private languages: ListItem[];

  units: ListItem[];

  materialId: string;
  form: FormGroup;
  contents = new FormArray([]);
  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  ngOnInit() {
    this.materialId = this.data.materialId;
    this.createForm();
    this.load();
  }

  createForm() {
    this.form = this.fb.group({
      unitId: [null],
      contents: this.contents
    });
  }

  addContents() {
    if (this.material) {
      this.form.controls.unitId.setValue(this.material.unitId);
    }

    this.languages.forEach(language => {
      let content = null;
      if (this.material) {
        content = this.material.contents.filter(o => o.languageId === language.id)[0];
      }

      const validators = language.id === this.defaultLanguageId
        ? Validators.compose([Validators.required, Validators.maxLength(200)])
        : Validators.compose([Validators.maxLength(200)]);

      this.contents.push(this.fb.group({
        language: [language],
        name: [content ? content.name : '', validators],
        description: [content ? content.description : '', Validators.compose([
          Validators.maxLength(1024)
        ])]
      }))
    });
  }

  load() {
    this.viewLoading = true;
    if (this.materialId) {
      forkJoin([
        this.dictionaryService.getLanguages(),
        this.dictionaryService.getUnits(),
        this.materialService.getById(this.materialId)
      ]).subscribe(result => {
        this.languages = result[0];
        this.units = result[1];
        this.material = result[2];
        this.addContents();
        this.viewLoading = false;
      });
    }
    else {
      forkJoin([
        this.dictionaryService.getLanguages(),
        this.dictionaryService.getUnits()
      ]).subscribe(result => {
        this.languages = result[0];
        this.units = result[1];
        this.addContents();
        this.viewLoading = false;
      });
    }
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    const material = this.prepare();
    if (material.id) {
      this.update(material);
    } else {
      this.create(material);
    }
  }

  private prepare(): Material {
    const contents = [];
    this.contents.controls.forEach((fromGroup: FormGroup) => {
      const name = fromGroup.controls.name.value;
      if (name) {
        contents.push({
          languageId: fromGroup.controls.language.value.id,
          name: fromGroup.controls.name.value,
          description: fromGroup.controls.description.value
        })
      }
    })
    return {
      id: this.materialId,
      unitId: this.form.controls.unitId.value,
      contents: contents,
      isDeleted: null,
      created: null,
      modified: null
    };
  }

  private create(material: Material) {
    this.viewLoading = true;
    this.materialService.add(material)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ material, isEdit: true });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  private update(material: Material) {
    this.viewLoading = true;
    this.materialService.update(material)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ material, isEdit: true });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  isControlHasError(formGroup: FormGroup, controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(formGroup, controlName, validationType);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.errorMessage = '';
  }
}
