import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { AuthService } from '../../../../../core/auth/';
import { ConfirmPasswordValidator } from '../../../auth/register/confirm-password.validator';

@Component({
  selector: 'kt-profile-password-edit',
  templateUrl: './profile-password-edit.component.html',
  styleUrls: ['./profile-password-edit.component.scss']
})
export class ProfilePasswordEditComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProfilePasswordEditComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authService: AuthService) {
  }

  form: FormGroup;
  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.form = this.fb.group({
      oldPassword: [null, Validators.required],
      password: [null, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)]
      )],
      confirmPassword: [null, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(100)]
      )]
    }, {
      validator: ConfirmPasswordValidator.MatchPassword
    });
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    this.viewLoading = true;

    const controls = this.form.controls;
    const oldPassword = controls.oldPassword.value;
    const newPassword = controls.password.value;
    this.authService.updatePassword(oldPassword, newPassword)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close(true);
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          } else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(this.form, controlName, validationType);
  }
}
