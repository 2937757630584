import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConversationService, UserService} from '../../../../../api/services';
import {
    getErrorMessage,
    hasErrorCode,
    isFormGroupControlHasError,
    markFormGroupTouched,
    setFormError
} from '../../../../../common/validation/validation-utils';
import {AssignSpecialistModel} from '../../../../../api/models/conversations';
import {first} from 'rxjs/operators';
import {UserListItem} from '../../../../../api/models/users';

@Component({
    selector: 'kt-conversation-assign-specialist',
    templateUrl: './conversation-assign-specialist.component.html',
    styleUrls: ['./conversation-assign-specialist.component.scss']
})
export class ConversationAssignSpecialistComponent implements OnInit {

    specialists: UserListItem[];
    conversation: { id: number, specialist: string };
    form: FormGroup;
    hasFormErrors = false;
    errorMessage = '';
    viewLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConversationAssignSpecialistComponent>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private conversationService: ConversationService,
        private userService: UserService) {
    }

    ngOnInit() {
        this.conversation = this.data.conversation;
        this.createForm();
        this.load();
    }

    createForm() {
        this.form = this.fb.group({
            specialistId: [null, Validators.required],
        });
    }

    load() {
        this.viewLoading = true;
        this.userService.get('', undefined, true, false, 'modified', 'desc', 0, 100)
            .pipe(first())
            .subscribe(response => {
                this.specialists = response.items;
                this.viewLoading = false;
            });
    }

    onSubmit() {
        this.hasFormErrors = false;

        if (this.form.invalid) {
            markFormGroupTouched(this.form);
            return;
        }

        const model: AssignSpecialistModel = {specialistId: this.form.controls.specialistId.value};
        this.conversationService.assign(this.conversation.id, model)
            .subscribe(
                () => {
                    this.viewLoading = false;
                    this.dialogRef.close({model});
                },
                errorResponse => {
                    this.viewLoading = false;
                    if (hasErrorCode(errorResponse)) {
                        this.hasFormErrors = true;
                        this.errorMessage = getErrorMessage(errorResponse);
                    } else {
                        setFormError(this.form, errorResponse);
                    }
                    this.cdr.markForCheck();
                }
            );
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        return isFormGroupControlHasError(this.form, controlName, validationType);
    }

    onAlertClose() {
        this.hasFormErrors = false;
        this.errorMessage = '';
    }
}
