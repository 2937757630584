import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { UserService } from '../../../../../api/services';

@Component({
  selector: 'kt-user-phone-edit',
  templateUrl: './user-phone-edit.component.html',
  styleUrls: ['./user-phone-edit.component.scss']
})
export class UserPhoneEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserPhoneEditComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private userService: UserService) {
  }

  private userId: string;
  form: FormGroup;
  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  ngOnInit() {
    this.userId = this.data.userId;
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      phoneNumber: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(20)]
      )],
    });
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    const controls = this.form.controls;
    const phoneNumber = controls.phoneNumber.value;
    this.updatePhone(this.userId, phoneNumber);
  }

  private updatePhone(userId: string, phoneNumber: string) {
    this.viewLoading = true;
    this.userService.updatePhone(userId, phoneNumber)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ userId, isEdit: true });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(this.form, controlName, validationType);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.errorMessage = '';
  }
}

