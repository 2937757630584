import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BaseDataSource } from '../../../../common/data/base-data-source';

import { AdministratorListItem } from '../../../../api/models/administrators'
import { AdministratorService } from '../../../../api/services';
import { PagedResponse } from '../../../../api/models/paged-response.interface';

export class AdministratorDataSource extends BaseDataSource<AdministratorListItem> {

    constructor(private administratorService: AdministratorService) {
        super();
    }

    load(searchValue: string, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.administratorService.get(searchValue, isDeleted, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<AdministratorListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
