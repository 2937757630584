import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { Language } from '../../../../../api/models/languages/language.interface';
import { LanguageService } from '../../../../../api/services';

@Component({
  selector: 'kt-language-edit',
  templateUrl: './language-edit.component.html',
  styleUrls: ['./language-edit.component.scss']
})
export class LanguageEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LanguageEditComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private languageService: LanguageService) {
  }

  language: Language;
  form: FormGroup;
  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  ngOnInit() {
    this.language = this.data.language;
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: [this.language.name, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(21)]
      )],
      isoCode: [this.language.isoCode, Validators.compose([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5)]
      )],
    });
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    const language = this.prepare();
    if (language.id) {
      this.update(language);
    } else {
      this.create(language);
    }
  }

  prepare(): Language {
    const controls = this.form.controls;
    return {
      id: this.language.id,
      name: controls.name.value,
      isoCode: controls.isoCode.value,
      isDeleted: null,
      created: null,
      modified: null,
    };
  }

  create(language: Language) {
    this.viewLoading = true;
    this.languageService.add(language)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ language, isEdit: true });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  update(language: Language) {
    this.viewLoading = true;
    this.languageService.update(language)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ language, isEdit: true });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(this.form, controlName, validationType);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.errorMessage = '';
  }
}
