import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BaseDataSource } from '../../../../common/data/base-data-source';

import { ServiceListItem } from '../../../../api/models/services'
import { ServiceService } from '../../../../api/services';
import { PagedResponse } from '../../../../api/models/paged-response.interface';

export class ServiceDataSource extends BaseDataSource<ServiceListItem> {

    constructor(private serviceService: ServiceService) {
        super();
    }

    load(searchValue: string, languageId: number, isPublished: boolean, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.serviceService.get(searchValue, languageId, isPublished, isDeleted, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<ServiceListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
