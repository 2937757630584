import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

export class SavingState {
    private map = new Map<string, any>();

    set(key: string, value: any): SavingState {
        this.map.set(key, value);
        return this;
    }

    toRestored(): RestoredState {
        return new RestoredState(this.map);
    }
}

export class RestoredState {
    constructor(private map: Map<string, any>) { }

    get(key: string): any {
        return this.map.get(key);
    }
}

export interface PersistentComponent {
    saveState(state: SavingState): void;
    restoreState(state: RestoredState): void;
}

@Injectable({
    providedIn: 'root'
})
export class StateService {
    constructor(router: Router) {
        this.url = router.url;
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const newUrl = event.url;
                if (this.url) {
                    const isNavigatingBack = this.url.startsWith(newUrl) === true
                        && this.url.substring(newUrl.length).lastIndexOf('/') === 0;
                    if (!isNavigatingBack && this.state && this.stateUrl != this.url && !this.url.endsWith('/new')) {
                        this.state = null;
                    }
                }

                this.url = newUrl;
            }
        });
    }

    private url: string;
    private state: RestoredState;
    private stateUrl: string;
    private stateComponentName: string;

    save(component: PersistentComponent) {
        const state = new SavingState()
        component.saveState(state);
        this.state = state.toRestored();
        this.stateUrl = this.url;
        this.stateComponentName = component.constructor.name;
    }

    restore(component: PersistentComponent) {
        if (!this.state) return;

        if (this.url !== this.stateUrl) return;

        const componentName = component.constructor.name;
        if (this.stateComponentName !== componentName) return;

        component.restoreState(this.state);
    }
}