import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';

import { GUID_PATTERN } from '../../../../../common/validation/validation-utils';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { MaterialService, DictionaryService } from '../../../../../api/services';
import { MaterialDataSource } from '../../data-sources';

import { MaterialEditComponent } from '../material-edit/material-edit.component';

@Component({
  selector: 'kt-material-list',
  templateUrl: './material-list.component.html',
  styleUrls: ['./material-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private materialService: MaterialService,
    private dictionaryService: DictionaryService) { }

  private subscriptions: Subscription[] = [];

  dataSource: MaterialDataSource;
  displayedColumns = ['id', 'name', 'unit', 'isDeleted', 'created', 'modified', 'actions'];

  materialId = new FormControl('', Validators.pattern(GUID_PATTERN));
  units: ListItem[] = [];
  unitId = null;
  isDeleted = 'false';

  ngOnInit() {
    this.dataSource = new MaterialDataSource(this.materialService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);

    const materialIdSubscription = this.materialId.valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          if (this.materialId.invalid) {
            this.materialId.markAsTouched();
            return;
          }

          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(materialIdSubscription);

    this.dictionaryService.getUnits()
      .subscribe(units => {
        this.units = units;
      });
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    const materialId = this.materialId.valid ? this.materialId.value : '';
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      materialId,
      this.unitId,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  add() {
    this.edit(null);
  }

  edit(materialId: number) {
    const saveMessage = materialId ? 'Материал обновлён' : 'Материал добавлен';
    const messageType = materialId ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(MaterialEditComponent, { data: { materialId }, width: '600px' });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.layoutUtilsService.showActionNotification(saveMessage, messageType, 1000, true, false);
      this.load();
    });
  }

  restore(materialId: number) {
    this.materialService.restore(materialId)
      .subscribe(
        response => {
          this.layoutUtilsService.showActionNotification('Материал был восстановлен.', MessageType.Update, 3000, true, false);
          this.load();
        },
        error => {
          this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении материала.', MessageType.Update, 3000, true, false);
        }
      );
  }

  delete(materialId: number) {
    const dialogRef = this.layoutUtilsService.deleteElement('Удаление', 'Вы действительно хотите удалить материал?', 'Удаление материала...');
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.materialService.delete(materialId)
          .subscribe(
            response => {
              this.layoutUtilsService.showActionNotification('Материал был удалён.', MessageType.Delete, 3000, true, false);
              this.load();
            },
            error => {
              this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении материала.', MessageType.Update, 3000, true, false);
            }
          );
      });
  }
}
