import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PagedResponse} from '../models/paged-response.interface';
import {
    AccessTokenCreateModel,
    AccessTokenCreateResponseModel,
    AccessTokenListItemModel,
    AccessTokenModel
} from '../models/access-tokens';

const API_URL = 'api/access-tokens';

@Injectable()
export class AccessTokenService {
    constructor(private http: HttpClient) {
    }

    get(searchValue: string, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<AccessTokenListItemModel>>(API_URL, {params});
    }

    getById(id: number) {
        return this.http.get<AccessTokenModel>(`${API_URL}/${id}`);
    }

    create(model: AccessTokenCreateModel) {
        return this.http.post<AccessTokenCreateResponseModel>(API_URL, model);
    }

    revoke(id: number) {
        return this.http.patch(`${API_URL}/${id}/revoke`, null);
    }

    delete(id: number) {
        return this.http.delete(`${API_URL}/${id}`);
    }
}
