import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../models/configurations';
import { Observable } from 'rxjs';

const API_URL = 'api/configurations';

@Injectable()
export class ConfigurationService {
    constructor(private http: HttpClient) { }

    get(): Observable<Configuration> {
        return this.http.get<Configuration>(API_URL);
    }
}
