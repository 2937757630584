import {of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {BaseDataSource} from '../../../../common/data/base-data-source';

import {ProductListItem} from '../../../../api/models/products';
import {TemplateService} from '../../../../api/services';
import {PagedResponse} from '../../../../api/models/paged-response.interface';
import {TemplateProductListItem} from '../../../../api/models/templates/template-product-list-item.interface';

export class TemplateProductDataSource extends BaseDataSource<TemplateProductListItem> {

    constructor(private templateService: TemplateService) {
        super();
    }

    load(templateId: string,
         searchValue: string,
         languageId: number,
         directionId: number,
         isEnabled: boolean,
         isDeleted: boolean,
         sortField: string,
         sortDirection: string,
         pageIndex: number,
         pageSize: number) {
        this.loadingSubject.next(true);
        this.subscriptions.push(this.templateService.getProducts(
            templateId,
            searchValue,
            languageId,
            directionId,
            isEnabled,
            isDeleted,
            sortField,
            sortDirection,
            pageIndex,
            pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<ProductListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            }));
    }
}
