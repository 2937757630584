import {of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {BaseDataSource} from '../../../../common/data/base-data-source';

import {InstructionListItem} from '../../../../api/models/instructions';
import {InstructionService} from '../../../../api/services';
import {PagedResponse} from '../../../../api/models/paged-response.interface';

export class InstructionDataSource extends BaseDataSource<InstructionListItem> {

    constructor(private instructionService: InstructionService) {
        super();
    }

    load(searchValue: string,
         instructionId: string,
         languageId: number,
         directionId: number,
         isEnabled: boolean,
         isDeleted: boolean,
         sortField: string,
         sortDirection: string,
         pageIndex: number,
         pageSize: number) {
        this.loadingSubject.next(true);
        this.instructionService.get(searchValue,
            instructionId,
            languageId,
            directionId,
            isEnabled,
            isDeleted,
            sortField,
            sortDirection,
            pageIndex,
            pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<InstructionListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
