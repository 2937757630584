import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';
import { each } from 'lodash';

import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { CountryListItem } from '../../../../../api/models/countries';
import { CountryService } from '../../../../../api/services';
import { CountryDataSource } from '../../data-sources';

import { CountryEditComponent } from '../country-edit/country-edit.component';

@Component({
  selector: 'kt-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private countryService: CountryService) { }

  private subscriptions: Subscription[] = [];

  dataSource: CountryDataSource;
  displayedColumns = ['name', 'code', 'language', '_phoneCodes', 'isDeleted', 'created', 'modified', 'actions'];

  isDeleted = 'false';

  ngOnInit() {
    this.dataSource = new CountryDataSource(this.countryService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  add() {
    this.edit(null);
  }

  edit(countryId: number) {
    const saveMessage = countryId ? 'Страна обновлена' : 'Страна добавлена';
    const messageType = countryId ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(CountryEditComponent, { data: { countryId }, width: '600px' });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.layoutUtilsService.showActionNotification(saveMessage, messageType, 1000, true, false);
      this.load();
    });
  }


  restore(countryId: number) {
    this.countryService.restore(countryId)
      .subscribe(
        response => {
          this.layoutUtilsService.showActionNotification('Страна была восстановлена.', MessageType.Update, 3000, true, false);
          this.load();
        },
        error => {
          this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении страны.', MessageType.Update, 3000, true, false);
        }
      );
  }

  delete(countryId: number) {
    const dialogRef = this.layoutUtilsService.deleteElement('Удаление', 'Вы действительно хотите удалить страну?', 'Удаление страны...');
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.countryService.delete(countryId)
          .subscribe(
            response => {
              this.layoutUtilsService.showActionNotification('Страна была удалена.', MessageType.Delete, 3000, true, false);
              this.load();
            },
            error => {
              this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении страны.', MessageType.Update, 3000, true, false);
            }
          );
      });
  }

  getPhoneCodesStr(country: CountryListItem): string {
    const phoneCodes: string[] = [];
    each(country.phoneCodes, (phoneCode: string) => {
      phoneCodes.push(phoneCode);
    });
    return phoneCodes.join(' ');
  }
}
