import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';

import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { UnitService } from '../../../../../api/services';
import { UnitDataSource } from '../../data-sources';

import { UnitEditComponent } from '../unit-edit/unit-edit.component';

@Component({
  selector: 'kt-unit-list',
  templateUrl: './unit-list.component.html',
  styleUrls: ['./unit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private unitService: UnitService) { }

  private subscriptions: Subscription[] = [];

  dataSource: UnitDataSource;
  displayedColumns = ['name', 'isDeleted', 'created', 'modified', 'actions'];

  isDeleted = 'false';

  ngOnInit() {
    this.dataSource = new UnitDataSource(this.unitService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  add() {
    this.edit(null);
  }

  edit(unitId: number) {
    const saveMessage = unitId ? 'Единица измерения обновлена' : 'Единица измерения добавлена';
    const messageType = unitId ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(UnitEditComponent, { data: { unitId }, width: '600px' });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.layoutUtilsService.showActionNotification(saveMessage, messageType, 1000, true, false);
      this.load();
    });
  }

  restore(unitId: number) {
    this.unitService.restore(unitId)
      .subscribe(
        response => {
          this.layoutUtilsService.showActionNotification('Единица измерения была восстановлена.', MessageType.Update, 3000, true, false);
          this.load();
        },
        error => {
          this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении единицы измерения.', MessageType.Update, 3000, true, false);
        }
      );
  }

  delete(unitId: number) {
    const dialogRef = this.layoutUtilsService.deleteElement('Удаление', 'Вы действительно хотите удалить единицу измерения?', 'Удаление единицы измерения...');
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.unitService.delete(unitId)
          .subscribe(
            response => {
              this.layoutUtilsService.showActionNotification('Единица измерения была удалена.', MessageType.Delete, 3000, true, false);
              this.load();
            },
            error => {
              this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении единицы измерения.', MessageType.Update, 3000, true, false);
            }
          );
      });
  }
}
