import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardChart } from '../models/dashboard';
import { Observable } from 'rxjs';

const API_URL = 'api/dashboard';

@Injectable()
export class DashboardService {
    constructor(private http: HttpClient) { }

    getUsersChart(): Observable<DashboardChart> {
        return this.http.get<DashboardChart>(`${API_URL}/charts/users`);
    }

    getDocumentsChart(): Observable<DashboardChart> {
        return this.http.get<DashboardChart>(`${API_URL}/charts/documents`);
    }

    getProjectsChart(): Observable<DashboardChart> {
        return this.http.get<DashboardChart>(`${API_URL}/charts/projects`);
    }
}
