import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Unit, UnitListItem } from '../models/units';
import { PagedResponse } from '../models/paged-response.interface';

const API_URL = 'api/units';

@Injectable()
export class UnitService {
    constructor(private http: HttpClient) { }

    get(searchValue: string, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<UnitListItem>>(API_URL, {  params });
    }

    getById(unitId: number) {
        return this.http.get<Unit>(`${API_URL}/${unitId}`);
    }

    add(unit: Unit) {
        return this.http.post(API_URL, {
            contents: unit.contents
        });
    }

    update(unit: Unit) {
        return this.http.put(API_URL, {
            id: unit.id,
            contents: unit.contents
        });
    }

    restore(unitId: number) {
        return this.http.patch(`${API_URL}/${unitId}/restore`, {});
    }

    delete(unitId: number) {
        return this.http.delete(`${API_URL}/${unitId}`);
    }
}
