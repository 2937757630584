import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Product, ProductContent, ProductListItem, ProductMaterialEdit} from '../models/products';
import {PagedResponse} from '../models/paged-response.interface';

const API_URL = 'api/products';

@Injectable()
export class ProductService {
    constructor(private http: HttpClient) {
    }

    get(searchValue: string,
        languageId: number,
        directionId: number,
        excludeTemplateId: string,
        isEnabled: boolean,
        isDeleted: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('languageId', languageId.toString())
            .set('directionId', directionId ? directionId.toString() : '')
            .set('excludeTemplateId', excludeTemplateId ? excludeTemplateId.toString() : '')
            .set('isEnabled', isEnabled === true ? 'true' : isEnabled === false ? 'false' : '')
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<ProductListItem>>(API_URL, {params});
    }

    getById(productId: string) {
        return this.http.get<Product>(`${API_URL}/${productId}`);
    }

    add(directionId: number, contents: ProductContent[], materials: ProductMaterialEdit[], templates: string[]) {
        return this.http.post<Product>(`${API_URL}/`, {
            directionId,
            contents,
            materials,
            templates
        });
    }

    update(productId: string, directionId: number, contents: ProductContent[], materials: ProductMaterialEdit[], templates: string[]) {
        return this.http.put(`${API_URL}`, {
            id: productId,
            directionId,
            contents,
            materials,
            templates
        });
    }

    updateImage(productId: string, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put(`${API_URL}/${productId}/image`, formData);
    }

    updateInstruction(productId: string, languageId: number, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('languageId', languageId.toString());
        return this.http.put(`${API_URL}/${productId}/instruction`, formData);
    }

    deleteInstruction(productId: string, languageId: number) {
        const params = new HttpParams()
            .set('languageId', languageId.toString());
        return this.http.delete(`${API_URL}/${productId}/instruction`, {params});
    }

    restore(productId: string) {
        return this.http.patch(`${API_URL}/${productId}/restore`, {});
    }

    delete(productId: string) {
        return this.http.delete(`${API_URL}/${productId}`);
    }
}
