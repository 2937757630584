// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// Modules
import {ApiModule} from '../../../api/api.module';
import {CoreModule} from '../../../core/core.module';
import {PartialsModule} from '../../partials/partials.module';
import {MaterialModule} from '../../../common/modules/material.module';
// Services
import {
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService
} from '../../../core/_base/crud';
import {
    ActionNotificationComponent,
    ConfirmationDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../../views/partials/content/crud';
// Components
import {UnitListComponent} from './units/unit-list/unit-list.component';
import {UnitEditComponent} from './units/unit-edit/unit-edit.component';
import {LanguageListComponent} from './languages/language-list/language-list.component';
import {LanguageEditComponent} from './languages/language-edit/language-edit.component';
import {DirectionListComponent} from './directions/direction-list/direction-list.component';
import {DirectionEditComponent} from './directions/direction-edit/direction-edit.component';
import {DocumentTypeListComponent} from './document-types/document-type-list/document-type-list.component';
import {DocumentTypeEditComponent} from './document-types/document-type-edit/document-type-edit.component';
import {AccessTokenListComponent} from './access-tokens/access-token-list/access-token-list.component';
import {AccessTokenEditComponent} from './access-tokens/access-token-edit/access-token-edit.component';

@NgModule({
    declarations: [
        UnitListComponent,
        UnitEditComponent,
        LanguageListComponent,
        LanguageEditComponent,
        DirectionListComponent,
        DirectionEditComponent,
        DocumentTypeListComponent,
        DocumentTypeEditComponent,
        AccessTokenListComponent,
        AccessTokenEditComponent
    ],
    exports: [],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,

        ApiModule,
        CoreModule,
        PartialsModule,

        MaterialModule
    ],
    providers: [
        TypesUtilsService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService
    ],
    entryComponents: [
        ActionNotificationComponent,
        ConfirmationDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,

        DirectionEditComponent,
        LanguageEditComponent,
        UnitEditComponent,
        DocumentTypeEditComponent,
        AccessTokenListComponent,
        AccessTokenEditComponent
    ]
})
export class DictionariesModule {
}
