export class User {
    id: number;
    username: string;
    fullname: string;
    firstName: string;
    lastName: string;
    middleName: string;
    position: string;
    phoneNumber: string;
    email: string;
}
