// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {catchError, finalize, takeUntil, tap} from 'rxjs/operators';
// NGRX
import {Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthNoticeService, AuthService, Register} from '../../../../core/auth/';
import {of, Subject} from 'rxjs';
import {ConfirmPasswordValidator} from './confirm-password.validator';
import {isFormGroupControlHasError, markFormGroupTouched} from '../../../../common/validation/validation-utils';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'kt-register',
    templateUrl: './register.component.html',
    encapsulation: ViewEncapsulation.None
})
export class RegisterComponent implements OnInit, OnDestroy {
    form: FormGroup;
    loading = false;

    private unsubscribe: Subject<any>;

    constructor(
        private authNoticeService: AuthNoticeService,
        private router: Router,
        private auth: AuthService,
        private store: Store<AppState>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef
    ) {
        this.unsubscribe = new Subject();
    }

    ngOnInit() {
        this.createForm();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.loading = false;
    }

    submit() {
        if (this.form.invalid) {
            markFormGroupTouched(this.form);
            return;
        }

        this.loading = true;

        const controls = this.form.controls;
        const request = {
            firstName: controls.firstName.value,
            lastName: controls.lastName.value,
            middleName: controls.middleName.value,
            position: controls.position.value,
            languageId: 1,
            phoneNumber: controls.phoneNumber.value,
            email: controls.email.value,
            password: controls.password.value,
        };
        this.auth.register(request).pipe(
            tap(response => {
                if (response) {
                    this.store.dispatch(new Register({authToken: response.token}));
                    this.authNoticeService.setNotice(
                        'Аккаунт успешно зарегистрирован. Обратитесь к администратору для дальнейшей работы.',
                        'success');
                    this.router.navigateByUrl('/auth/login');
                } else {
                    this.authNoticeService.setNotice('В процессе регистрации возникла ошибка.', 'danger');
                }
            }),
            catchError((response: HttpErrorResponse) => {
                if (response.error) {
                    switch (response.error.code) {
                        case 'EmailAlreadyExists':
                            this.authNoticeService.setNotice('Пользователь с таким Email уже существует', 'danger');
                            break;
                        case 'PhoneNumberAlreadyExists':
                            this.authNoticeService.setNotice('Пользователь с таким номер телефона уже существует', 'danger');
                            break;
                        default:
                            this.authNoticeService.setNotice('В процессе регистрации возникла ошибка.', 'danger');
                            break;
                    }
                    return of(response);
                }
            }),
            takeUntil(this.unsubscribe),
            finalize(() => {
                this.loading = false;
                this.cdr.markForCheck();
            })
        ).subscribe();
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        return isFormGroupControlHasError(this.form, controlName, validationType);
    }

    private createForm() {
        this.form = this.fb.group({
            lastName: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(50)]
            )],
            firstName: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(50)]
            )],
            middleName: [null, Validators.maxLength(50)],
            position: [null, Validators.maxLength(250)],
            phoneNumber: [null, Validators.compose([
                Validators.required,
                Validators.maxLength(50)]
            )],
            email: [null, Validators.compose([
                Validators.required,
                Validators.email,
                Validators.maxLength(100)]
            )],
            password: [null, Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)]
            )],
            confirmPassword: [null, Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(100)]
            )]
        }, {
            validator: ConfirmPasswordValidator.MatchPassword
        });
    }
}
