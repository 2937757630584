import { Component, Input } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material';

import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { ExportDialogComponent } from './export-dialog.component';
import { ExportOptions } from './export-options';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'kt-export-button',
  templateUrl: './export-button.component.html',
})
export class ExportButtonComponent {

  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private layoutUtilsService: LayoutUtilsService
  ) { }

  @Input() source: string;

  export() {
    const dialogRef = this.dialog.open<ExportDialogComponent, any, ExportOptions>(ExportDialogComponent, { width: '600px' });
    dialogRef.afterClosed().subscribe(options => {
      if (!options) {
        return;
      }

      const params = new HttpParams()
        .set('from', options.from)
        .set('to', options.to)
        .set('includeDeleted', options.includeDeleted === true ? 'true' : 'false');
      this.http.get(`api/${this.source}/export/csv`, { observe: 'response', responseType: 'text', params: params }).subscribe(response => {
        const data = new Blob([response.body], { type: response.headers.get('content-type') });
        FileSaver.saveAs(data, response.headers.get('content-disposition').split('filename=')[1].split(';')[0]);
      }, error => {
        this.layoutUtilsService.showActionNotification('Произошла ошибка при выгрузке данных.', MessageType.Update, 3000, true, false);
      });
    });
  }
}
