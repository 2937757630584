import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ListItem } from '../models/dictionaries/list-item.interface';

const API_URL = 'api/lists';

@Injectable()
export class DictionaryService {
    constructor(private http: HttpClient) { }

    getLanguages() {
        return this.http.get<ListItem[]>(`${API_URL}/languages`);
    }

    getUnits() {
        return this.http.get<ListItem[]>(`${API_URL}/units`);
    }

    getDirections() {
        return this.http.get<ListItem[]>(`${API_URL}/directions`);
    }

    getCountries() {
        return this.http.get<ListItem[]>(`${API_URL}/countries`);
    }

    getAdministrativeAreas() {
        return this.http.get<ListItem[]>(`${API_URL}/administrative-areas`);
    }
}
