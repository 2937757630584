import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BaseDataSource } from '../../../../common/data/base-data-source';

import { MaterialListItem } from '../../../../api/models/materials'
import { MaterialService } from '../../../../api/services';
import { PagedResponse } from '../../../../api/models/paged-response.interface';

export class MaterialDataSource extends BaseDataSource<MaterialListItem> {

    constructor(private materialService: MaterialService) {
        super();
    }

    load(searchValue: string, materialId: string, unitId: number, isDeleted: boolean, languageId: number, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.materialService.get(searchValue, materialId, unitId, isDeleted, languageId, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<MaterialListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
