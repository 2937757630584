import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Instruction, InstructionListItem} from '../models/instructions';
import {PagedResponse} from '../models/paged-response.interface';

const API_URL = 'api/instructions';

@Injectable()
export class InstructionService {
    constructor(private http: HttpClient) {
    }

    get(searchValue: string,
        instructionId: string,
        languageId: number,
        directionId: number,
        isEnabled: boolean,
        isDeleted: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('instructionId', instructionId)
            .set('languageId', languageId.toString())
            .set('directionId', directionId != null ? directionId.toString() : '')
            .set('isEnabled', isEnabled === true ? 'true' : isEnabled === false ? 'false' : '')
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<InstructionListItem>>(API_URL, {params});
    }

    getById(instructionId: string) {
        return this.http.get<Instruction>(`${API_URL}/${instructionId}`);
    }

    delete(instructionId: string) {
        return this.http.delete(`${API_URL}/${instructionId}`);
    }

    add(name: string, languageId: number, directionId: number | '') {
        return this.http.post<Instruction>(`${API_URL}/`, {
            name,
            languageId,
            directionId,
        });
    }

    update(instructionId: string, name: string, languageId: number, directionId: number | '') {
        return this.http.put<Instruction>(`${API_URL}`, {
            id: instructionId,
            name,
            languageId,
            directionId,
        });
    }

    updateContent(instructionId: string, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put(`${API_URL}/${instructionId}/content`, formData);
    }

    updateCover(instructionId: string, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put(`${API_URL}/${instructionId}/cover`, formData);
    }

    disable(instructionId: string) {
        return this.http.patch(`${API_URL}/${instructionId}/disable`, {});
    }

    enable(instructionId: string) {
        return this.http.patch(`${API_URL}/${instructionId}/enable`, {});
    }
}
