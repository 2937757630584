import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {BehaviorSubject, forkJoin, Observable, Subscription} from 'rxjs';
import {concatMap} from 'rxjs/operators';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {ListItem} from '../../../../../api/models/dictionaries/list-item.interface';
import {Story} from '../../../../../api/models/stories';
import {DictionaryService, StoryService} from '../../../../../api/services';
import {SlideListItem} from '../models/slide-list-item.interface';

@Component({
    selector: 'kt-story-edit',
    templateUrl: './story-edit.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./story-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoryEditComponent implements OnInit {

    loading$: Observable<boolean>;
    titleFormControl = new FormControl('', Validators.maxLength(150));
    descriptionFormControl = new FormControl('', Validators.maxLength(500));
    languages: ListItem[] = [];
    languageId = 1;
    imageUrl: string | ArrayBuffer;
    isPublished = false;
    slides: SlideListItem[] = [];
    selectedTabIndex = 0;
    private subscriptions: Subscription[] = [];
    private loadingSubject = new BehaviorSubject<boolean>(true);
    private storyId: string = null;
    private story: Story = null;
    private imageFile: File = null;
    private slidesToDelete: string[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private layoutUtilsService: LayoutUtilsService,
        private storyService: StoryService,
        private dictionaryService: DictionaryService
    ) {
    }

    ngOnInit() {
        const routeSubscription = this.route.params.subscribe(params => {
            const storyId = params['storyId'];
            if (storyId !== 'new') {
                this.storyId = storyId;
            }
            this.load();
        });

        this.subscriptions.push(routeSubscription);
    }

    onImageChanged(files: FileList) {
        if (files.length === 0) {
            return;
        }

        const file = files[0];
        if (file.type.match(/image\/*/) == null) {
            return;
        }

        this.imageFile = file;

        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            this.imageUrl = reader.result;
            this.cdr.markForCheck();
        };
    }

    onSlideImageChanged(slide: SlideListItem, files: FileList) {
        if (files.length === 0) {
            return;
        }

        const file = files[0];
        if (file.type.match(/video\/*/) !== null) {
            slide.imageFile = file;
            slide.imageUrl = '../../../../../../assets/media/misc/video-icon.png';
            this.cdr.markForCheck();
        } else if (file.type.match(/image\/*/) !== null) {

            slide.imageFile = file;

            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
                slide.imageUrl = reader.result;
                this.cdr.markForCheck();
            };
        }
    }

    addSlide() {
        this.slides.push({
            id: null,
            title: null,
            description: null,
            buttonTitle: null,
            link: null,
            textColor: '#ffffff',
            backgroundColor: '#000000',
            imageUrl: null,
            order: this.slides.length,
            created: null,
            modified: null,
            imageFile: null,
        });
        this.cdr.markForCheck();
    }

    deleteSlide(slide: SlideListItem) {
        if (slide.id) {
            this.slidesToDelete.push(slide.id);
        }

        const slideIndex = this.slides.indexOf(slide);
        this.slides.splice(slideIndex, 1);
        this.cdr.markForCheck();
    }

    save() {
        if (this.titleFormControl.invalid) {
            this.titleFormControl.markAsTouched();
            this.selectedTabIndex = 0;
            return;
        }

        if (this.descriptionFormControl.invalid) {
            this.descriptionFormControl.markAsTouched();
            this.selectedTabIndex = 0;
            return;
        }

        if (this.storyId) {
            this.update();
        } else {
            this.create();
        }
    }

    private load() {
        this.loading$ = this.loadingSubject.asObservable();
        this.loadingSubject.next(true);
        if (this.storyId) {
            forkJoin([
                this.storyService.getById(this.storyId),
                this.dictionaryService.getLanguages()
            ])
                .subscribe(data => {
                    this.story = data[0];
                    this.languages = data[1];
                    this.fill();
                    this.loadingSubject.next(false);
                });
        } else {
            this.dictionaryService.getLanguages()
                .subscribe(languages => {
                    this.languages = languages;
                    this.fill();
                    this.loadingSubject.next(false);
                });
        }
    }

    private fill() {
        if (!this.story) {
            return;
        }

        this.imageUrl = this.story.imageUrl;
        this.titleFormControl.setValue(this.story.title);
        this.descriptionFormControl.setValue(this.story.description);
        this.languageId = this.story.languageId;
        this.isPublished = this.story.isPublished;

        const slides: SlideListItem[] = [];
        this.story.slides.sort((a, b) => a.order - b.order).forEach(slide => {
            slides.push({
                id: slide.id,
                title: slide.title,
                description: slide.description,
                buttonTitle: slide.buttonTitle,
                link: slide.link,
                textColor: slide.textColor,
                backgroundColor: slide.backgroundColor,
                imageUrl: slide.imageUrl,
                order: slide.order,
                created: slide.created,
                modified: slide.modified,
                imageFile: null,
            });
        });
        this.slides = slides;

        this.cdr.markForCheck();
    }

    private create() {
        this.loadingSubject.next(true);
        this.storyService.add(this.titleFormControl.value, this.descriptionFormControl.value, this.languageId)
            .subscribe(story => {
                this.updateFiles(story.id);
            });
    }

    private update() {
        this.loadingSubject.next(true);
        this.storyService.update(this.story.id, this.titleFormControl.value, this.descriptionFormControl.value, this.languageId)
            .subscribe(() => {
                this.updateFiles(this.story.id);
            });
    }

    private updateFiles(storyId: string) {
        const items: any[] = [];

        if (this.imageFile) {
            items.push(this.storyService.updateImage(storyId, this.imageFile));
        }

        if (!this.story || this.story.isPublished !== this.isPublished) {
            if (this.isPublished) {
                items.push(this.storyService.publish(storyId));
            } else {
                items.push(this.storyService.unpublish(storyId));
            }
        }

        this.slidesToDelete.forEach(slideToDelete => {
            items.push(this.storyService.deleteSlide(storyId, slideToDelete));
        });

        for (let slideIndex = 0; slideIndex < this.slides.length; slideIndex++) {
            const slide = this.slides[slideIndex];
            const slideImageFile = slide.imageFile;
            slide.order = slideIndex;

            if (slide.id) {
                items.push(this.storyService.updateSlide(storyId, slide));
                if (slideImageFile != null) {
                    items.push(this.storyService.updateSlideImage(storyId, slide.id, slideImageFile));
                }
            } else {
                if (slideImageFile != null) {
                    items.push(this.storyService.addSlide(storyId, slide).pipe(
                        concatMap((slide) => this.storyService.updateSlideImage(storyId, slide.id, slideImageFile))
                    ));
                } else {
                    items.push(this.storyService.addSlide(storyId, slide));
                }
            }
        }

        forkJoin(items)
            .subscribe({
                complete: () => {
                    this.loadingSubject.next(false);
                    this.reset();
                    this.redirect(storyId);
                }
            });
    }

    private reset() {
        this.imageFile = null;
    }

    private redirect(storyId: string) {
        if (!this.story) {
            this.layoutUtilsService.showActionNotification('История добавлена', MessageType.Create, 3000, true, false);
            this.router.navigate(['../', storyId], {relativeTo: this.route});
        } else {
            this.layoutUtilsService.showActionNotification('История обновлена', MessageType.Update, 3000, true, false);
            this.router.navigate(['../'], {relativeTo: this.route});
        }
    }
}
