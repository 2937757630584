import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BaseDataSource } from '../../../../common/data/base-data-source';

import { PromoCodeListItem } from '../../../../api/models/promo-codes'
import { PromoCodeService } from '../../../../api/services';
import { PagedResponse } from '../../../../api/models/paged-response.interface';

export class PromoCodeDataSource extends BaseDataSource<PromoCodeListItem> {

    constructor(private promoCodeService: PromoCodeService) {
        super();
    }

    load(code: string, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.promoCodeService.get(code, null, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<PromoCodeListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
