import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable, BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { MessageType, LayoutUtilsService } from '../../../../../core/_base/crud';
import { each, find, remove } from 'lodash';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { Contact } from '../../../../../api/models/contacts/contact.interface';
import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { ContactService, DictionaryService } from '../../../../../api/services';

@Component({
  selector: 'kt-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private contactService: ContactService,
    private dictionaryService: DictionaryService) {
  }

  administrativeAreas: ListItem[];
  unselectedAdministrativeAreas: ListItem[] = [];
  selectedAdministrativeAreas: ListItem[] = [];
  administrativeAreaIdToSelect: number;
  countries: ListItem[];

  contactId: number = null;
  form: FormGroup;
  hasFormErrors = false;
  errorMessage = '';
  loading$: Observable<boolean>;
  photoUrl: any;
  photoHasError = false;

  private subscriptions: Subscription[] = [];
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private contact: Contact = null;
  private photoFile: File = null;

  ngOnInit() {
    this.createForm();

    const routeSubscription = this.route.params.subscribe(params => {
      const contactId = params.contactId;
      if (contactId !== 'new')
        this.contactId = contactId;
      this.load();
    });
    this.subscriptions.push(routeSubscription);
  }

  private createForm() {
    this.form = this.fb.group({
      lastName: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(50)]
      )],
      firstName: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(50)]
      )],
      middleName: [null, Validators.maxLength(50)],
      company: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(250)]
      )],
      position: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(250)]
      )],
      phoneNumber: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(20)]
      )],
      email: [null, Validators.compose([
        Validators.required,
        Validators.maxLength(100)]
      )],
      countryId: [null],
    });
  }

  private load() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);
    if (this.contactId) {
      forkJoin([
        this.contactService.getById(this.contactId),
        this.dictionaryService.getAdministrativeAreas(),
        this.dictionaryService.getCountries(),
      ]).subscribe(result => {
        this.contact = result[0];
        this.administrativeAreas = result[1];
        this.countries = result[2];
        this.fill();
        this.loadingSubject.next(false);
      });
    } else {
      forkJoin([
        this.dictionaryService.getAdministrativeAreas(),
        this.dictionaryService.getCountries(),
      ]).subscribe(result => {
        this.contact = {
          id: null,
          firstName: null,
          lastName: null,
          middleName: null,
          company: null,
          position: null,
          phoneNumber: null,
          email: null,
          countryIdentifiers: null,
          administrativeAreaIdentifiers: null,
          photo: null,
          isDeleted: null,
          created: null,
          modified: null,
        };
        this.administrativeAreas = result[0];
        this.countries = result[1];
        this.fill();
        this.loadingSubject.next(false);
      });
    }
  }

  private fill() {
    this.photoUrl = this.contact.photo ? `/api/contacts/${this.contact.id}/photo` : null;

    const controls = this.form.controls;
    controls.lastName.setValue(this.contact.lastName);
    controls.firstName.setValue(this.contact.firstName);
    controls.middleName.setValue(this.contact.middleName);
    controls.company.setValue(this.contact.company);
    controls.position.setValue(this.contact.position);
    controls.phoneNumber.setValue(this.contact.phoneNumber);
    controls.email.setValue(this.contact.email);
    if (this.contact.countryIdentifiers) {
      controls.countryId.setValue(this.contact.countryIdentifiers[0]);
    }

    this.unselectedAdministrativeAreas = this.administrativeAreas;
    each(this.contact.administrativeAreaIdentifiers, (administrativeAreaId: number) => {
      const administrativeArea = find(this.administrativeAreas, (_administrativeArea: ListItem) => {
        return _administrativeArea.id === administrativeAreaId;
      });

      if (administrativeArea) {
        this.selectedAdministrativeAreas.push(administrativeArea);
        remove(this.unselectedAdministrativeAreas, (el) => el.id === administrativeArea.id);
      }
    });

    this.cdr.markForCheck();
  }

  onPhotoChanged(files: FileList) {
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (file.type.match(/image\/*/) == null) {
      return;
    }

    this.photoFile = file;
    this.photoHasError = false;

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.photoUrl = reader.result;
      this.cdr.markForCheck();
    }
  }

  save() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    const contact = this.prepare();
    if (contact.id) {
      this.update(contact);
    } else {
      this.create(contact);
    }
  }

  private prepare(): Contact {
    const controls = this.form.controls;
    return {
      id: this.contact.id,
      firstName: controls.firstName.value,
      lastName: controls.lastName.value,
      middleName: controls.middleName.value,
      company: controls.company.value,
      position: controls.position.value,
      phoneNumber: controls.phoneNumber.value,
      email: controls.email.value,
      countryIdentifiers: controls.countryId.value ? [controls.countryId.value] : null,
      administrativeAreaIdentifiers: this.contact.administrativeAreaIdentifiers,
      photo: null,
      isDeleted: null,
      created: null,
      modified: null,
    };
  }

  private create(contact: Contact) {
    this.loadingSubject.next(true);
    this.contactService.add(contact)
      .subscribe(
        contact => {
          this.updateFiles(contact.id);
        },
        errorResponse => {
          this.loadingSubject.next(false);
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          } else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  private update(contact: Contact) {
    this.loadingSubject.next(true);
    this.contactService.update(contact)
      .subscribe(
        response => {
          this.updateFiles(this.contact.id);
        },
        errorResponse => {
          this.loadingSubject.next(false);
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          } else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  private updateFiles(contactId: number) {
    const items: any[] = [];

    if (this.photoFile) {
      items.push(this.contactService.updatePhoto(contactId, this.photoFile));
    }

    forkJoin(items)
      .subscribe({
        complete: () => {
          this.loadingSubject.next(false);
          this.reset();
          this.redirect(contactId);
        }
      });
  }

  private reset() {
    this.photoFile = null;
  }

  private redirect(contactId: number) {
    if (!this.contactId) {
      this.layoutUtilsService.showActionNotification('Контакт добавлен', MessageType.Create, 3000, true, false);
      this.router.navigate(['../', contactId], { relativeTo: this.route });
    } else {
      this.layoutUtilsService.showActionNotification('Контакт обновлён', MessageType.Update, 3000, true, false);
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  selectAdministrativeArea() {
    if (this.administrativeAreaIdToSelect === 0) {
      return;
    }

    const administrativeArea = find(this.administrativeAreas, (_administrativeArea: ListItem) => {
      return _administrativeArea.id === this.administrativeAreaIdToSelect;
    });

    if (administrativeArea) {
      this.selectedAdministrativeAreas.push(administrativeArea);
      remove(this.unselectedAdministrativeAreas, (el) => el.id === administrativeArea.id);
      this.administrativeAreaIdToSelect = 0;
      this.updateAdministrativeAreas();
    }
  }

  unselectAdministrativeArea(administrativeArea: ListItem) {
    this.administrativeAreaIdToSelect = 0;
    this.unselectedAdministrativeAreas.push(administrativeArea);
    remove(this.selectedAdministrativeAreas, el => el.id === administrativeArea.id);
    this.updateAdministrativeAreas();
  }

  updateAdministrativeAreas() {
    const _administrativeAreas = [];
    each(this.selectedAdministrativeAreas, elem => _administrativeAreas.push(elem.id));
    this.contact.administrativeAreaIdentifiers = _administrativeAreas;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(this.form, controlName, validationType);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.errorMessage = '';
  }
}
