import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BaseDataSource } from '../../../../common/data/base-data-source';

import { AdministrativeArea } from '../../../../api/models/administrative-areas/administrative-area.interface'
import { AdministrativeAreaService } from '../../../../api/services';
import { PagedResponse } from '../../../../api/models/paged-response.interface';

export class AdministrativeAreaDataSource extends BaseDataSource<AdministrativeArea> {

    constructor(private administrativeAreaService: AdministrativeAreaService) {
        super();
    }

    load(searchValue: string, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.administrativeAreaService.get(searchValue, isDeleted, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<AdministrativeArea>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
