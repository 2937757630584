import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { fromEvent, merge, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { SuggestionService } from '../../../../../api/services';
import { SuggestionDataSource } from '../../data-sources';
import { PersistentComponent, RestoredState, SavingState, StateService } from '../../../../../common/data/state.service';

@Component({
  selector: 'kt-suggestion-list',
  templateUrl: './suggestion-list.component.html',
  styleUrls: ['./suggestion-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionListComponent implements OnInit, AfterViewInit, OnDestroy, PersistentComponent {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private suggestionService: SuggestionService,
    private stateService: StateService) { }

  private subscriptions: Subscription[] = [];

  dataSource: SuggestionDataSource;
  displayedColumns = [
	'authorName',
	'comment',
	'isDeleted',
	'created',
	'modified',
	'actions'
  ];

  isDeleted = 'false';
  sortActive = 'authorName';
  sortDirection = 'asc';
  pageIndex = 0;
  pageSize = 10;

  ngOnInit() {
    this.dataSource = new SuggestionDataSource(this.suggestionService);

    this.stateService.restore(this);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.stateService.save(this);
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  saveState(state: SavingState) {
    state
      .set('searchValue', this.searchInput.nativeElement.value)
      .set('isDeleted', this.isDeleted)
      .set('sortActive', this.sort.active)
      .set('sortDirection', this.sort.direction)
      .set('pageIndex', this.paginator.pageIndex)
      .set('pageSize', this.paginator.pageSize);
  }

  restoreState(state: RestoredState) {
    this.searchInput.nativeElement.value = state.get('searchValue');
    this.isDeleted = state.get('isDeleted');
    this.sortActive = state.get('sortActive');
    this.sortDirection = state.get('sortDirection');
    this.pageIndex = state.get('pageIndex');
    this.pageSize = state.get('pageSize');
  }
}
