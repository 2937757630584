import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {fromEvent, merge, Subject} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';
import {debounceTime, distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import {AccessTokenService} from '../../../../../api/services/access-token.service';
import {AccessTokenDataSource} from '../../data-sources/access-token-data-source';
import {AccessTokenEditComponent} from '../access-token-edit/access-token-edit.component';
import {AccessTokenListItemModel} from '../../../../../api/models/access-tokens';

@Component({
    selector: 'kt-access-token-list',
    templateUrl: './access-token-list.component.html',
    styleUrls: ['./access-token-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessTokenListComponent implements OnInit, AfterViewInit, OnDestroy {
    private destroy$ = new Subject<void>();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;

    dataSource: AccessTokenDataSource;
    displayedColumns = [
        'id',
        'name',
        'description',
        'isRevoked',
        'isDeleted',
        'validUntil',
        'created',
        'modified',
        'actions'
    ];

    isDeleted = 'false';

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private reportsApiTokenService: AccessTokenService) {
    }

    ngOnInit() {
        this.dataSource = new AccessTokenDataSource(this.reportsApiTokenService);

        merge(this.sort.sortChange, this.paginator.page).pipe(
            takeUntil(this.destroy$),
            tap(() => this.load())
        ).subscribe();

        fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.filter();
                })
            ).subscribe();
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        this.dataSource.load(
            this.searchInput.nativeElement.value,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    create() {
        const dialogRef = this.dialog.open(AccessTokenEditComponent, {data: {}, width: '400px'});
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(result => result ? this.load() : null);
    }

    revoke(item: AccessTokenListItemModel) {
        const dialogRef = this.layoutUtilsService.confirmAction('Отзыв',
            'Вы действительно хотите отозвать токен?', 'Отзыв токена...', 'Отозвать токен');
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.reportsApiTokenService.revoke(item.id)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        () => {
                            this.layoutUtilsService.showActionNotification('Токен был отозван.',
                                MessageType.Delete, 3000, true, false);
                            this.load();
                        },
                        () => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при отзыве токена.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });
    }

    delete(id: number) {
        const dialogRef = this.layoutUtilsService.deleteElement('Удаление',
            'Вы действительно хотите удалить токен?', 'Удаление токена...');
        dialogRef.afterClosed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.reportsApiTokenService.delete(id)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(
                        () => {
                            this.layoutUtilsService.showActionNotification('Токен был удалён.',
                                MessageType.Delete, 3000, true, false);
                            this.load();
                        },
                        () => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении токена.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });
    }
}
