import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BaseDataSource } from '../../../../common/data/base-data-source';

import { StoryListItem } from '../../../../api/models/stories'
import { StoryService } from '../../../../api/services';
import { PagedResponse } from '../../../../api/models/paged-response.interface';

export class StoryDataSource extends BaseDataSource<StoryListItem> {

    constructor(private storyService: StoryService) {
        super();
    }

    load(searchValue: string, languageId: number, isPublished: boolean, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.storyService.get(searchValue, languageId, isPublished, isDeleted, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<StoryListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
