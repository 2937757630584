import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountryPhoneCode } from '../models/countries';

const API_URL = 'api/country-phone-codes';

@Injectable()
export class CountryPhoneCodeService {
    constructor(private http: HttpClient) { }

    add(countryPhoneCode: CountryPhoneCode) {
        return this.http.post(API_URL, {
            code: countryPhoneCode.code,
            countryId: countryPhoneCode.countryId
        });
    }

    delete(countryPhoneCodeId: number) {
        return this.http.delete(`${API_URL}/${countryPhoneCodeId}`);
    }
}
