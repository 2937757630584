import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BaseDataSource } from '../../../../common/data/base-data-source';

import { UnitListItem } from '../../../../api/models/units'
import { UnitService } from '../../../../api/services';
import { PagedResponse } from '../../../../api/models/paged-response.interface';

export class UnitDataSource extends BaseDataSource<UnitListItem> {

    constructor(private unitService: UnitService) {
        super();
    }

    load(searchValue: string, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.unitService.get(searchValue, isDeleted, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<UnitListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
