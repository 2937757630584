import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';

import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {fromEvent, merge, Subscription} from 'rxjs';

import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {ProjectService} from '../../../../../api/services';
import {ProjectDataSource} from '../../data-sources';

@Component({
    selector: 'kt-project-list',
    templateUrl: './project-list.component.html',
    styleUrls: ['./project-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    @ViewChild('searchByPhoneNumberInput', {static: true}) searchByPhoneNumberInput: ElementRef;
    dataSource: ProjectDataSource;
    displayedColumns = ['name', 'address', 'latitude', 'longitude', 'user', 'isEngineering', 'domOnlineId',
        'crmNumber', 'isArchived', 'isDeleted', 'created', 'modified', 'actions'];
    userId: string = null;
    isDeleted = 'false';
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private projectService: ProjectService) {
    }

    ngOnInit() {
        this.dataSource = new ProjectDataSource(this.projectService);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.load())
            ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.filter();
                })
            ).subscribe();
        this.subscriptions.push(searchSubscription);

        const searchByPhoneNumberSubscription = fromEvent(this.searchByPhoneNumberInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    if (this.searchByPhoneNumberInput.nativeElement.value.length >= 5 ||
                        this.searchByPhoneNumberInput.nativeElement.value.length === 0) {
                        this.filter();
                    }
                })
            ).subscribe();
        this.subscriptions.push(searchByPhoneNumberSubscription);
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        this.dataSource.load(
            this.searchInput.nativeElement.value,
            this.searchByPhoneNumberInput.nativeElement.value,
            this.userId,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    restore(projectId: string) {
        const dialogRef = this.layoutUtilsService.confirmAction('Восстановление',
            'Вы действительно хотите восстановить объект?',
            'Восстановление объекта...',
            'Восстановить');
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.projectService.restore(projectId)
                    .subscribe(
                        () => {
                            this.layoutUtilsService.showActionNotification('Объект был восстановлен.',
                                MessageType.Update, 3000, true, false);
                            this.load();
                        },
                        () => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении объекта.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });
    }

    delete(projectId: string) {
        const dialogRef = this.layoutUtilsService.deleteElement('Удаление',
            'Вы действительно хотите удалить объект?', 'Удаление объекта...');
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.projectService.delete(projectId)
                    .subscribe(
                        () => {
                            this.layoutUtilsService.showActionNotification('Объект был удалён.',
                                MessageType.Delete, 3000, true, false);
                            this.load();
                        },
                        () => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении объекта.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });
    }
}
