import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConversationListComponent} from './components/conversation-list/conversation-list.component';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {ApiModule} from '../../../api/api.module';
import {CoreModule} from '../../../core/core.module';
import {PartialsModule} from '../../partials/partials.module';
import {MaterialModule} from '../../../common/modules/material.module';
import {HttpUtilsService, LayoutUtilsService, TypesUtilsService} from '../../../core/_base/crud';
import {
    ActionNotificationComponent,
    ConfirmationDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../partials/content/crud';
import {ConversationAssignSpecialistComponent} from './components/conversation-assign-specialist/conversation-assign-specialist.component';


@NgModule({
    declarations: [ConversationListComponent, ConversationAssignSpecialistComponent],
    imports: [
        RouterModule,
        CommonModule,
        HttpClientModule,
        FormsModule,

        ApiModule,
        CoreModule,
        PartialsModule,

        MaterialModule
    ],
    providers: [
        TypesUtilsService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService
    ],
    entryComponents: [
        ActionNotificationComponent,
        ConfirmationDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        ConversationAssignSpecialistComponent,
    ]
})
export class ConversationsModule {
}
