import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {
    getErrorMessage,
    hasErrorCode,
    isFormGroupControlHasError,
    markFormGroupTouched,
    setFormError
} from '../../../../../common/validation/validation-utils';
import {AccessTokenService} from '../../../../../api/services/access-token.service';
import {Subject} from 'rxjs';
import {AccessTokenCreateModel} from '../../../../../api/models/access-tokens';

function toUtc(date: Date) {
    const nDate = new Date(date);
    nDate.setTime(nDate.getTime() - nDate.getTimezoneOffset() * 60000);
    return nDate;
}

@Component({
    selector: 'kt-access-token-edit',
    templateUrl: './access-token-edit.component.html',
    styleUrls: ['./access-token-edit.component.scss']
})
export class AccessTokenEditComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();

    form: FormGroup;
    hasFormErrors = false;
    errorMessage = '';
    viewLoading = false;
    hasToken = false;
    token: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AccessTokenEditComponent>,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private reportsApiTokenService: AccessTokenService) {
    }

    ngOnInit() {
        this.createForm();
    }

    onAlertClose() {
        this.hasFormErrors = false;
        this.errorMessage = '';
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    createForm() {
        this.form = this.fb.group({
            name: [null, Validators.required],
            description: [null],
            validUntil: [null, Validators.required]
        });
    }

    submit() {
        this.hasFormErrors = false;

        if (this.form.invalid) {
            markFormGroupTouched(this.form);
            return;
        }

        const model: AccessTokenCreateModel = {
            name: this.form.controls.name.value,
            description: this.form.controls.description.value,
            validUntil: toUtc(this.form.controls.validUntil.value)
        };

        this.viewLoading = true;

        this.reportsApiTokenService.create(model)
            .pipe(takeUntil(this.destroy$))
            .subscribe((response) => {
                    this.viewLoading = false;
                    this.hasToken = true;
                    this.token = response.token;
                }, errorResponse => {
                    this.viewLoading = false;
                    if (hasErrorCode(errorResponse)) {
                        this.hasFormErrors = true;
                        this.errorMessage = getErrorMessage(errorResponse);
                    } else {
                        setFormError(this.form, errorResponse);
                    }
                    this.cdr.markForCheck();
                }
            );
    }

    close() {
        this.dialogRef.close(true);
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        return isFormGroupControlHasError(this.form, controlName, validationType);
    }

    validUntilDateFilter = (day: Date): boolean => day > new Date();
}
