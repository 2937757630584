import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
    selector: 'kt-stats-widget',
    templateUrl: './stats-widget.component.html',
    styleUrls: ['./stats-widget.component.scss']
})
export class StatsWidgetComponent implements OnInit {

    @Input() title: string;
    @Input() totalTitle: string;
    @Input() totalValue: string;
    @Input() exportSource: string;
    @Input() period: 'month' | 'last12month' = 'month';
    @Input() data: number[];
    @ViewChild('chart', {static: true}) chart: ElementRef;
    chartOptions: any = {};
    colorsThemeBase = '';
    private monthCategories: string[] = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'];
    private categoryPeriod: string[] = null;

    constructor() {
    }

    ngOnInit() {
        this.colorsThemeBase = 'rgba(93, 120, 255, 1)';

        switch (this.period) {
            case 'last12month':
                const index = new Date().getMonth();
                if (index === 0) {
                    this.categoryPeriod = this.monthCategories;
                } else {
                    this.categoryPeriod = this.monthCategories.slice(index)
                        .concat(this.monthCategories.slice(0, index));
                }
                break;
            case 'month':
            default:
                this.categoryPeriod = this.monthCategories;
                break;
        }

        this.chartOptions = this.getChartOptions();
    }

    getChartOptions() {
        return {
            series: [{
                name: this.title,
                data: this.data
            }],
            chart: {
                type: 'area',
                height: '150px',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                sparkline: {
                    enabled: true
                }
            },
            plotOptions: {},
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
            },
            xaxis: {
                categories: this.categoryPeriod,
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    style: {
                        fontSize: '12px',
                    }
                },
                crosshairs: {
                    show: false,
                    position: 'front',
                    stroke: {
                        width: 1,
                        dashArray: 3
                    }
                },
                tooltip: {
                    enabled: true,
                    formatter: undefined,
                    offsetY: 0,
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            yaxis: {
                labels: {
                    show: false,
                    style: {
                        fontSize: '12px',
                    }
                }
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: (value) => {
                        return value;
                    }
                }
            },
            markers: {
                strokeColor: [this.colorsThemeBase],
                strokeWidth: 3
            }
        };
    }
}
