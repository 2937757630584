import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Template, TemplateContent, TemplateListItem} from '../models/templates';
import {PagedResponse} from '../models/paged-response.interface';
import {TemplateProductListItem} from '../models/templates/template-product-list-item.interface';

const API_URL = 'api/templates';

@Injectable()
export class TemplateService {
    constructor(private http: HttpClient) {
    }

    get(searchValue: string,
        isEngineering: boolean,
        isDeleted: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('isEngineering', isEngineering === true ? 'true' : isEngineering === false ? 'false' : '')
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<TemplateListItem>>(API_URL, {params});
    }

    getProducts(
        templateId: string,
        searchValue: string,
        languageId: number,
        directionId: number,
        isEnabled: boolean,
        isDeleted: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        const params = new HttpParams()
            .set('templateId', templateId)
            .set('searchValue', searchValue)
            .set('languageId', languageId.toString())
            .set('directionId', directionId ? directionId.toString() : '')
            .set('isEnabled', isEnabled === true ? 'true' : isEnabled === false ? 'false' : '')
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<TemplateProductListItem>>(`${API_URL}/${templateId}/products`, {params});
    }

    getById(templateId: string) {
        return this.http.get<Template>(`${API_URL}/${templateId}`);
    }

    delete(templateId: string) {
        return this.http.delete(`${API_URL}/${templateId}`);
    }

    restore(templateId: string) {
        return this.http.patch(`${API_URL}/${templateId}/restore`, {});
    }

    addProduct(templateId: string, productId: string) {
        return this.http.patch(`${API_URL}/${templateId}/products/${productId}/add`, {});
    }

    removeProduct(templateId: string, productId: string) {
        return this.http.patch(`${API_URL}/${templateId}/products/${productId}/remove`, {});
    }

    add(title: string, documentTypeId: number, isEngineering: boolean) {
        return this.http.post<Template>(`${API_URL}/`, {
            title,
            documentTypeId,
            isEngineering
        });
    }

    update(templateId: string, title: string, documentTypeId: number, isEngineering: boolean) {
        return this.http.put<Template>(`${API_URL}`, {
            id: templateId,
            title,
            documentTypeId,
            isEngineering
        });
    }

    getContents(templateId: string) {
        return this.http.get<TemplateContent[]>(`${API_URL}/${templateId}/contents`);
    }

    getContentById(templateId: string, contentId: string) {
        return this.http.get(`${API_URL}/${templateId}/contents/${contentId}`);
    }

    addContent(templateId: string, languageId: number, file: any) {
        const formData = new FormData();
        formData.append('languageId', languageId.toString());
        formData.append('file', file);
        return this.http.post(`${API_URL}/${templateId}/contents`, formData);
    }

    publishContent(templateId: string, contentId: string) {
        return this.http.patch(`${API_URL}/${templateId}/contents/${contentId}/publish`, {});
    }

    allowContentTesting(templateId: string, contentId: string) {
        return this.http.patch(`${API_URL}/${templateId}/contents/${contentId}/allow-testing`, {});
    }

    updateCover(templateId: string, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put(`${API_URL}/${templateId}/cover`, formData);
    }

    updateIllustrations(templateId: string, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put(`${API_URL}/${templateId}/illustrations`, formData);
    }
}
