import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';

import {fromEvent, merge, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';

import {PretensionService} from '../../../../../api/services';
import {PretensionDataSource} from '../../data-sources';
import {PersistentComponent, RestoredState, SavingState, StateService} from '../../../../../common/data/state.service';
import {SortDirection} from '@angular/material/sort/typings/sort-direction';

@Component({
    selector: 'kt-pretension-list',
    templateUrl: './pretension-list.component.html',
    styleUrls: ['./pretension-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PretensionListComponent implements OnInit, AfterViewInit, OnDestroy, PersistentComponent {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    dataSource: PretensionDataSource;
    displayedColumns = [
        'coverUrl',
        'authorName',
        'materialName',
        'comment',
        'isDeleted',
        'created',
        'modified',
        'actions'
    ];
    isDeleted = 'false';
    sortActive = 'authorName';
    sortDirection: SortDirection = 'asc';
    pageIndex = 0;
    pageSize = 10;
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private pretensionService: PretensionService,
        private stateService: StateService) {
    }

    ngOnInit() {
        this.dataSource = new PretensionDataSource(this.pretensionService);

        this.stateService.restore(this);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.load())
            ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.filter();
                })
            ).subscribe();
        this.subscriptions.push(searchSubscription);
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.stateService.save(this);
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        this.dataSource.load(
            this.searchInput.nativeElement.value,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    saveState(state: SavingState) {
        state
            .set('searchValue', this.searchInput.nativeElement.value)
            .set('isDeleted', this.isDeleted)
            .set('sortActive', this.sort.active)
            .set('sortDirection', this.sort.direction)
            .set('pageIndex', this.paginator.pageIndex)
            .set('pageSize', this.paginator.pageSize);
    }

    restoreState(state: RestoredState) {
        this.searchInput.nativeElement.value = state.get('searchValue');
        this.isDeleted = state.get('isDeleted');
        this.sortActive = state.get('sortActive');
        this.sortDirection = state.get('sortDirection');
        this.pageIndex = state.get('pageIndex');
        this.pageSize = state.get('pageSize');
    }
}
