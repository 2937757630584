import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {User, UserListItem, UserPhoneNumber} from '../models/users';
import {PagedResponse} from '../models/paged-response.interface';

const API_URL = 'api/users';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) {
    }

    get(searchValue: string,
        isTestingAllowed: boolean | undefined,
        isEngineer: boolean | undefined,
        isDeleted: boolean | undefined,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        let params = new HttpParams()
            .set('searchValue', encodeURIComponent(searchValue))
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        if (isTestingAllowed !== undefined) {
            params = params.set('isTestingAllowed', isTestingAllowed ? 'true' : 'false');
        }

        if (isEngineer !== undefined) {
            params = params.set('isEngineer', isEngineer ? 'true' : 'false');
        }

        if (isDeleted !== undefined) {
            params = params.set('isDeleted', isDeleted ? 'true' : 'false');
        }

        return this.http.get<PagedResponse<UserListItem>>(API_URL, {params});
    }

    getById(userId: string) {
        return this.http.get<User>(`${API_URL}/${userId}`);
    }

    update(user: User) {
        return this.http.put(API_URL, {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            middleName: user.middleName ? user.middleName : null,
            company: user.company ? user.company : null,
            position: user.position ? user.position : null,
            languageId: user.languageId,
            email: user.email ? user.email : null,
            theme: user.theme ? user.theme : null,
            userTheme: user.userTheme ? user.userTheme : null,
            type: user.type ? user.type : null,
            isTestingAllowed: user.isTestingAllowed,
            isEngineer: user.isEngineer
        });
    }

    unblock(userId: string) {
        return this.http.patch(`${API_URL}/${userId}/unblock`, {});
    }

    block(userId: string) {
        return this.http.patch(`${API_URL}/${userId}/block`, {});
    }

    restore(userId: string) {
        return this.http.patch(`${API_URL}/${userId}/restore`, {});
    }

    delete(userId: string) {
        return this.http.delete(`${API_URL}/${userId}`);
    }

    getPhoneHistory(userId: string) {
        return this.http.get<UserPhoneNumber[]>(`${API_URL}/${userId}/phone-history`);
    }

    updatePhone(userId: string, phoneNumber: string) {
        return this.http.patch(`${API_URL}/${userId}/phone`, {
            userId,
            phoneNumber,
        });
    }

    allowTesting(userId: string) {
        return this.http.patch(`${API_URL}/${userId}/allow-testing`, {});
    }

    denyTesting(userId: string) {
        return this.http.patch(`${API_URL}/${userId}/deny-testing`, {});
    }
}
