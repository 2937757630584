import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';

import { Observable, BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { find } from 'lodash';

import { MessageType, LayoutUtilsService } from '../../../../../core/_base/crud';

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { Product, ProductContent, ProductMaterial, ProductTemplate, ProductMaterialEdit } from '../../../../../api/models/products';
import { Material } from '../../../../../api/models/materials';
import { ProductService, DictionaryService, MaterialService } from '../../../../../api/services';
import { ProductContentListItem } from '../models/product-content-list-item.interface';
import { ProductContentEditComponent } from '../product-content-edit/product-content-edit.component';
import { MaterialSearchComponent } from '../material-search/material-search.component';
import { MaterialEditComponent } from '../../materials/material-edit/material-edit.component';
import { TemplateSearchComponent } from '../template-search/template-search.component';

const nonlocalizedValue = 'ВНИМАНИЕ: Нет локализации';

@Component({
  selector: 'kt-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductEditComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private productService: ProductService,
    private dictionaryService: DictionaryService,
    private materialService: MaterialService
  ) { }

  private productId: string = null;
  private subscriptions: Subscription[] = [];
  private loadingSubject = new BehaviorSubject<boolean>(true);

  private productImageChanged = false;
  private productDirectionChanged = false;
  private productContentChanged = false;
  private productMaterialsChanged = false;
  private productTemplatesChanged = false;

  loading$: Observable<boolean>;
  contentColumns = ['isEnabled', 'language', 'name', 'description', 'instruction', 'actions'];

  directionHasError = false;
  contentHasError = false;
  materialsHasError = false;

  selectedTabIndex = 0;

  productImageUrl: any;

  directionId = null;
  productImageFile: any = null;
  languageId = 1;

  product: Product = null;
  directions: ListItem[] = [];
  languages: ListItem[] = [];

  productContents: ProductContentListItem[] = [];
  productMaterials: ProductMaterial[] = [];
  productTemplates: ProductTemplate[] = [];

  ngOnInit() {
    const routeSubscription = this.route.params.subscribe(params => {
      const productId = params.productId;
      if (productId !== 'new') {
        this.productId = productId;
      }
      this.load();
    });
    this.subscriptions.push(routeSubscription);
  }

  private load() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);
    if (this.productId) {
      forkJoin([
        this.productService.getById(this.productId),
        this.dictionaryService.getDirections(),
        this.dictionaryService.getLanguages()
      ])
        .subscribe(data => {
          this.product = data[0];
          this.directions = data[1];
          this.languages = data[2];
          this.fill();
          this.loadingSubject.next(false);
        });
    } else {
      forkJoin([
        this.dictionaryService.getDirections(),
        this.dictionaryService.getLanguages()
      ])
        .subscribe(data => {
          this.directions = data[0];
          this.languages = data[1];
          this.fill();
          this.loadingSubject.next(false);
        });
    }
  }

  private fill() {
    const productContents: ProductContentListItem[] = [];
    this.languages.forEach(language => {
      const languageContent = this.product
        ? this.product.contents.filter(content => content.languageId === language.id)[0]
        : null;
      const languageInstruction = this.product
        ? this.product.instructions.filter(instruction => instruction.languageId === language.id)[0]
        : null;
      productContents.push({
        language,
        title: languageContent ? languageContent.name : '',
        description: languageContent ? languageContent.description : '',
        isEnabled: languageContent ? languageContent.isEnabled : false,
        instructionExist: languageInstruction != null,
        instructionUrl: languageInstruction != null
          ? `/api/products/${this.productId}/instruction?languageId=${language.id}`
          : null,
        instructionFile: null,
        instructionDeleted: false
      });
    });
    this.productContents = productContents;
    if (this.product) {
      this.productImageUrl = this.product.image
        ? `/api/products/${this.product.id}/image`
        : null;
      this.directionId = this.product.directionId;
      this.productMaterials = this.product.materials;
      this.productTemplates = this.product.templates;
    }
  }

  onProductImageChanged(files: any) {
    if (files.length === 0) {
      return;
    }
    const file = files[0];
    if (file.type.match(/image\/*/) == null) {
      return;
    }
    this.productImageChanged = true;
    this.productImageFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.productImageUrl = reader.result;
      this.cdr.markForCheck();
    }
  }

  onDirectionChanged() {
    if (this.product) {
      this.productDirectionChanged = this.product.directionId != this.directionId;
    }
    this.directionHasError = false;
  }

  onLanguageChanged() {
    const materialIds = new Set<string>();
    this.productMaterials.forEach(productMaterial => {
      materialIds.add(productMaterial.id);
      productMaterial.alternate.forEach(alternateProductMaterial => materialIds.add(alternateProductMaterial.id));
    });

    const items: Observable<Material>[] = [];
    materialIds.forEach(materialId => items.push(this.materialService.getById(materialId)));

    forkJoin(items)
      .subscribe(
        materials => {
          this.productMaterials.forEach(productMaterial => {
            this.localizeProductMaterial(productMaterial, materials);
            productMaterial.alternate.forEach(alternateProductMaterial =>
              this.localizeProductMaterial(alternateProductMaterial, materials));
          });
          this.cdr.markForCheck();
        },
      );
  }

  private localizeProductMaterial(productMaterial: ProductMaterial, materials: Material[]) {
    const material = find(materials, (material: Material) => material.id === productMaterial.id);
    const languageContent = material.contents.filter(o => o.languageId === this.languageId)[0];
    productMaterial.name = languageContent ? languageContent.name : nonlocalizedValue;
    productMaterial.description = languageContent ? languageContent.description : nonlocalizedValue;
  }

  editContent(languageContent: ProductContentListItem) {
    const saveMessage = languageContent.title ? 'Описание обновлено' : 'Описание добавлено';
    const messageType = languageContent.title ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(ProductContentEditComponent, {
      data: {
        language: languageContent.language,
        title: languageContent.title,
        description: languageContent.description
      }, width: '600px'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        languageContent.title = result.title;
        languageContent.description = result.description;
        this.productContentChanged = true;
        this.contentHasError = false;
        this.cdr.markForCheck();
        this.layoutUtilsService.showActionNotification(saveMessage, messageType, 1000, true, false);
      });
  }

  publishContent(languageContent: ProductContentListItem) {
    languageContent.isEnabled = !languageContent.isEnabled;
    this.productContentChanged = true;
  }

  downloadInstruction(languageContent: ProductContentListItem) {
    window.open(languageContent.instructionUrl, '_blank');
  }

  addInstruction(languageContent: ProductContentListItem, files: any) {
    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (file.type.match(/application\/pdf/) == null) {
      return;
    }

    languageContent.instructionUrl = null;
    languageContent.instructionFile = file;
    languageContent.instructionDeleted = false;
  }

  deleteInstruction(languageContent: ProductContentListItem) {
    if (languageContent.instructionExist) {
      languageContent.instructionDeleted = true;
    }
    languageContent.instructionUrl = null;
    languageContent.instructionFile = null;
  }

  addMaterial() {
    const dialogRef = this.dialog.open(MaterialSearchComponent, {
      data: {
        parentId: null,
        languageId: this.languageId
      }, width: '1000px'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.productMaterials.push({
          id: result.material.id,
          name: result.material.name,
          description: result.material.description,
          unit: result.material.unit,
          layer: 0,
          alternate: []
        });
        this.productMaterialsChanged = true;
        this.materialsHasError = false;
        this.cdr.markForCheck();
      });
  }

  addAlternateMaterial(parent: ProductMaterial) {
    const dialogRef = this.dialog.open(MaterialSearchComponent, {
      data: {
        parentId: parent.id,
        languageId: this.languageId
      }, width: '1000px'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        parent.alternate.push({
          id: result.material.id,
          name: result.material.name,
          description: result.material.description,
          unit: result.material.unit,
          layer: 0,
          alternate: []
        });
        this.productMaterialsChanged = true;
        this.cdr.markForCheck();
      });
  }

  moveUpMaterial(item: ProductMaterial) {
    const index = this.productMaterials.indexOf(item);
    this.productMaterials.splice(index - 1, 0, this.productMaterials.splice(index, 1)[0]);
    this.productMaterialsChanged = true;
  }

  moveDownMaterial(item: ProductMaterial) {
    const index = this.productMaterials.indexOf(item);
    this.productMaterials.splice(index + 1, 0, this.productMaterials.splice(index, 1)[0]);
    this.productMaterialsChanged = true;
  }

  moveUpAlternateMaterial(parent: ProductMaterial, item: ProductMaterial) {
    const index = parent.alternate.indexOf(item);
    parent.alternate.splice(index - 1, 0, parent.alternate.splice(index, 1)[0]);
    this.productMaterialsChanged = true;
  }

  moveDownAlternateMaterial(parent: ProductMaterial, item: ProductMaterial) {
    const index = parent.alternate.indexOf(item);
    parent.alternate.splice(index + 1, 0, parent.alternate.splice(index, 1)[0]);
    this.productMaterialsChanged = true;
  }

  replaceMaterial(item: ProductMaterial) {
    const dialogRef = this.dialog.open(MaterialSearchComponent, {
      data: {
        parentId: item.id,
        languageId: this.languageId
      }, width: '1000px'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        const newMaterial = result.material;
        item.id = newMaterial.id;
        item.name = newMaterial.name;
        item.description = newMaterial.description;
        item.unit = newMaterial.unit;
        this.productMaterialsChanged = true;
        this.materialsHasError = false;
        this.cdr.markForCheck();
      });
  }

  editMaterial(item: ProductMaterial) {
    const dialogRef = this.dialog.open(MaterialEditComponent, { data: { materialId: item.id }, width: '600px' });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      const languageContent = result.material.contents.filter(o => o.languageId === this.languageId)[0];
      const name = languageContent ? languageContent.name : nonlocalizedValue;
      const description = languageContent ? languageContent.description : nonlocalizedValue;
      this.productMaterials.forEach(productMaterial => {
        if (productMaterial.id === item.id) {
          productMaterial.name = name;
          productMaterial.description = description;
        }
        productMaterial.alternate.forEach(alternateProductMaterial => {
          if (alternateProductMaterial.id !== item.id) return;

          alternateProductMaterial.name = name;
          alternateProductMaterial.description = description;
        });
      });
      this.cdr.markForCheck();
    });
  }

  deleteMaterial(item: ProductMaterial) {
    const index = this.productMaterials.indexOf(item);
    this.productMaterials.splice(index, 1);
    this.productMaterialsChanged = true;
  }

  deleteAlternateMaterial(parent: ProductMaterial, item: ProductMaterial) {
    const index = parent.alternate.indexOf(item);
    parent.alternate.splice(index, 1);
    this.productMaterialsChanged = true;
  }

  addTemplate() {
    const dialogRef = this.dialog.open(TemplateSearchComponent, {
      data: {}, width: '1000px'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }
        this.productTemplates.push({
          id: result.template.id,
          title: result.template.title,
          documentType: result.template.documentType,
          version: result.template.version,
          isEngineering: result.template.isEngineering
        });
        this.productTemplatesChanged = true;
        this.cdr.markForCheck();
      });
  }

  deleteTemplate(item: ProductTemplate) {
    const index = this.productTemplates.indexOf(item);
    this.productTemplates.splice(index, 1);
    this.productTemplatesChanged = true;
  }

  save() {
    if (!this.directionId) {
      this.directionHasError = true;
      this.selectedTabIndex = 0;
      return;
    }

    if (!this.productContents.filter(content => content.title)[0]) {
      this.contentHasError = true;
      this.selectedTabIndex = 0;
      return;
    }

    if (this.productMaterials.length === 0) {
      this.materialsHasError = true;
      this.selectedTabIndex = 1;
      return;
    }

    const productContents: ProductContent[] = [];
    const materials: any[] = [];
    const templates: string[] = [];

    this.productContents.forEach(content => {
      if (content.title) {
        productContents.push({
          name: content.title,
          description: content.description,
          languageId: content.language.id,
          isEnabled: content.isEnabled
        });
      }
    });

    this.productMaterials.forEach((material, index) => {
      materials.push({
        materialId: material.id,
        layer: index + 1,
        isAlternate: false
      });

      material.alternate.forEach(alternateMaterial => {
        materials.push({
          materialId: alternateMaterial.id,
          layer: index + 1,
          isAlternate: true
        });
      });
    });

    this.productTemplates.forEach(template => {
      templates.push(template.id);
    });

    if (this.productId) {
      this.update(productContents, materials, templates);
    } else {
      this.create(productContents, materials, templates);
    }
  }

  private create(contents: ProductContent[], materials: ProductMaterialEdit[], templates: string[]) {
    this.loadingSubject.next(true);
    this.productService.add(this.directionId, contents, materials, templates)
      .subscribe(product => {
        this.updateFiles(product.id);
      });
  }

  private update(contents: ProductContent[], materials: ProductMaterialEdit[], templates: string[]) {
    this.loadingSubject.next(true);
    this.productService.update(this.product.id, this.directionId, contents, materials, templates)
      .subscribe(() => {
        this.updateFiles(this.product.id);
      });
  }

  private updateFiles(productId: string) {
    const items: any[] = [];

    if (this.productImageChanged) {
      items.push(this.productService.updateImage(productId, this.productImageFile));
    }

    this.productContents.forEach(content => {
      if (content.instructionDeleted) {
        items.push(this.productService.deleteInstruction(productId, content.language.id));
      } else if (content.instructionFile) {
        items.push(this.productService.updateInstruction(productId, content.language.id, content.instructionFile));
      }
    });

    if (items.length > 0) {
      forkJoin(items)
        .subscribe(() => {
          this.loadingSubject.next(false);
          this.redirect(productId);
        });
    } else {
      this.redirect(productId);
    }
  }

  private redirect(productId: string) {
    if (!this.product) {
      this.layoutUtilsService.showActionNotification('Система добавлена', MessageType.Create, 3000, true, false);
      this.router.navigate(['../', productId], { relativeTo: this.route });
    } else {
      this.layoutUtilsService.showActionNotification('Система обновлена', MessageType.Update, 3000, true, false);
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
}
