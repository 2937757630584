import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { TreeControl } from '@angular/cdk/tree';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';

import { plainToClass } from 'class-transformer';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 } from 'uuid';

import { TemplateService } from '../../../../../api/services';
import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';
import * as models from '../models/template-content.interface';
import { OptionItem } from '../models/template-content.interface';

class Node {
  readonly id: string = v4();
  readonly name: string;
  children: Node[] = [];
  readonly onChanged?: (value: any) => any = null;
  content: any;
  private readonly parent?: any;
  private readonly index?: number;
  readonly newItem?: () => any;
  level: number = 0;
  expandable: boolean = false;
  readonly childOptions: ChildOption[];
  readonly canBeCloned: boolean = false;
  readonly canBeRemoved: boolean = false;

  constructor(
    {
      content,
      name,
      parent,
      index,
      newItem,
    }: {
      content: any,
      name?: string,
      parent?: any,
      index?: number,
      newItem?: () => any,
    },
  ) {
    this.content = content;
    if (name) {
      this.name = name;
    } else {
      this.name = content.constructor.name;
    }
    this.parent = parent;
    this.index = index;
    this.newItem = newItem;

    if (this.parent) {
      this.onChanged = (value: any) => {
        this.parent[this.index] = value;
        this.content = value;
      };
    }
    this.loadChildren();
    this.childOptions = this.getChildOptions();
    this.canBeCloned = this.content instanceof models.Position;
    this.canBeRemoved = !(this.content instanceof models.Template || Array.isArray(this.content));
  }

  get description(): any {
    const content = this.content;
    if (models.isString(content) || models.isNumber(content)) {
      return content;
    } else if (content instanceof models.Option && content.type) {
      return content.type;
    } else if (content instanceof models.OptionAttribute && content.option) {
      return content.option;
    }

    const contentTitle = content.title;
    if (contentTitle) {
      return contentTitle;
    }

    return content.value;
  }

  private refreshExpandable() {
    this.expandable = this.children.length > 0;
  }

  private loadChildren() {
    const children: Node[] = [];
    const content = this.content;
    if (content instanceof models.Template) {
      content.sections.forEach((section) => children.push(new Node({ content: section })));
    } else if (content instanceof models.CheckListSection) {
      if (content.groups && content.groups.length > 0) {
        content.groups.forEach((group) => children.push(new Node({ content: group })));
      } else {
        content.positions.forEach((position) => children.push(new Node({ content: position })));
      }
    } else if (content instanceof models.FieldsSection) {
      content.fields.forEach((field) => children.push(new Node({ content: field })));
    } else if (content instanceof Array) {
      content.forEach((item) => {
        if (models.isString(item) || models.isNumber(item)) {
          const itemName = this.name.substring(0, this.name.length - 1);
          const index = content.indexOf(item);
          children.push(new Node({ content: item, name: itemName, parent: content, index: index }));
        } else if (item) {
          children.push(new Node({ content: item }));
        }
      });
    } else if (content instanceof models.Group) {
      content.positions.forEach((position) => children.push(new Node({ content: position })));
    } else if (content instanceof models.Position) {
      children.push(new Node({ content: content.notes, name: 'Notes', newItem: () => new String() }));
      children.push(new Node({ content: content.links, name: 'Links', newItem: () => new models.Link() }));
      children.push(new Node({ content: content.illustrations, name: 'Illustrations', newItem: () => new String() }));
      children.push(new Node({ content: content.parameters, name: 'Parameters', newItem: () => new models.Parameter() }));
      children.push(new Node({ content: content.optionAttributes, name: 'OptionAttributes', newItem: () => new models.OptionAttribute() }));
    } else if (content instanceof models.OptionAttribute) {
      children.push(new Node({ content: content.suggestions, name: 'Рекомендации на пункт', newItem: () => new String() }));
      children.push(new Node({ content: content.comments, name: 'Comments', newItem: () => new models.Comment() }));
    } else if (content instanceof models.Comment) {
      children.push(new Node({ content: content.suggestions, name: 'Авторекомендации', newItem: () => new models.Suggestion() }));
    } else if (content instanceof models.TotalAttribute) {
      children.push(new Node({ content: content.suggestions, name: 'Suggestions', newItem: () => new String() }));
    } else if (content instanceof models.Requirement) {
      children.push(new Node({ content: content.materials, name: 'Materials', newItem: () => new String() }));
    }
    this.children = children;
    this.refreshExpandable();
  }

  canAddChild(childContent: any): boolean {
    if (!childContent) return false;

    const content = this.content;
    if (content instanceof models.CheckListSection) {
      if (childContent instanceof models.Group) {
        if (content.positions && content.positions.length > 0) return false;

        return true;
      } else if (childContent instanceof models.Position) {
        if (content.groups && content.groups.length > 0) return false;

        return true;
      }
    }

    return this.childOptions.some((childOption) => childOption.content.constructor.name === childContent.constructor.name);
  }

  addChild(childContent: any): boolean {
    const content = this.content;

    let items: any[];
    if (content instanceof models.Template && childContent instanceof models.Section) {
      items = content.sections;
    } else if (content instanceof models.FieldsSection && childContent instanceof models.Field) {
      items = content.fields;
    } else if (content instanceof models.CheckListSection && childContent instanceof models.Group) {
      if (content.positions && content.positions.length > 0) return false;

      items = content.groups;
    } else if (content instanceof models.CheckListSection && childContent instanceof models.Position) {
      if (content.groups && content.groups.length > 0) return false;

      items = content.positions;
    } else if (content instanceof models.Group && childContent instanceof models.Position) {
      items = content.positions;
    } else if (content instanceof Array && childContent) {
      items = content;
    }
    if (!items) return false;

    const addedItemIndex = items.push(childContent) - 1;

    let nodeToAdd: Node;
    if (content instanceof Array && (models.isString(childContent) || models.isNumber(childContent))) {
      const itemName = this.name.substring(0, this.name.length - 1);
      nodeToAdd = new Node({ content: childContent, name: itemName, parent: content, index: addedItemIndex });
    } else {
      nodeToAdd = new Node({ content: childContent });
    }
    this.children.push(nodeToAdd);
    this.refreshExpandable();
    return true;
  }

  cloneChild(childId: string): boolean {
    const childIndex = this.children.findIndex(child => child.id === childId);
    if (childIndex === -1) return false;

    const child = this.children[childIndex];
    const childContent = cloneDeep(child.content);
    const content = this.content;

    let items: any[];

    if (content instanceof models.Template && childContent instanceof models.Section) {
      items = content.sections;
    } else if (content instanceof models.FieldsSection && childContent instanceof models.Field) {
      items = content.fields;
    } else if (content instanceof models.CheckListSection && childContent instanceof models.Group) {
      items = content.groups;
    } else if (content instanceof models.CheckListSection && childContent instanceof models.Position) {
      items = content.positions;
    } else if (content instanceof models.Group && childContent instanceof models.Position) {
      items = content.positions;
    } else if (content instanceof Array && childContent) {
      items = content;
    }
    if (!items) return false;

    const addedChildIndex = childIndex + 1
    items.splice(addedChildIndex, 0, childContent);

    let nodeToAdd: Node;
    if (content instanceof Array && (models.isString(childContent) || models.isNumber(childContent))) {
      const itemName = this.name.substring(0, this.name.length - 1);
      nodeToAdd = new Node({ content: childContent, name: itemName, parent: content, index: addedChildIndex });
    } else {
      nodeToAdd = new Node({ content: childContent });
    }
    this.children.splice(addedChildIndex, 0, nodeToAdd);
    return true;
  }

  removeChild(childId: string): boolean {
    const childIndex = this.children.findIndex(child => child.id === childId);
    if (childIndex === -1) return false;

    const child = this.children[childIndex];
    const childContent = child.content;
    const content = this.content;

    let items: any[];
    if (content instanceof models.Template && childContent instanceof models.Section) {
      items = content.sections;
    } else if (content instanceof models.FieldsSection && childContent instanceof models.Field) {
      items = content.fields;
    } else if (content instanceof models.CheckListSection && childContent instanceof models.Group) {
      items = content.groups;
    } else if (content instanceof models.CheckListSection && childContent instanceof models.Position) {
      items = content.positions;
    } else if (content instanceof models.Group && childContent instanceof models.Position) {
      items = content.positions;
    } else if (content instanceof Array) {
      items = content;
    }
    if (!items) return false;

    items.splice(childIndex, 1);
    this.children.splice(childIndex, 1);
    this.refreshExpandable();
    return true;
  }

  moveChild(childId: string, newIndex: number): boolean {
    const childIndex = this.children.findIndex(child => child.id === childId);
    if (childIndex === -1) return false;

    const child = this.children[childIndex];
    const childContent = child.content;
    const content = this.content;

    let items: any[];
    if (content instanceof models.Template && childContent instanceof models.Section) {
      items = content.sections;
    } else if (content instanceof models.FieldsSection && childContent instanceof models.Field) {
      items = content.fields;
    } else if (content instanceof models.CheckListSection && childContent instanceof models.Group) {
      items = content.groups;
    } else if (content instanceof models.CheckListSection && childContent instanceof models.Position) {
      items = content.positions;
    } else if (content instanceof models.Group && childContent instanceof models.Position) {
      items = content.positions;
    } else if (content instanceof Array && childContent) {
      items = content;
    }
    if (!items) return false;

    items.splice(childIndex, 1);
    this.children.splice(childIndex, 1);

    items.splice(newIndex, 0, childContent);
    this.children.splice(newIndex, 0, child);
    return true;
  }

  private getChildOptions(): ChildOption[] {
    const content = this.content;
    if (content instanceof models.Template) {
      return [
        new ChildOption(new models.FieldsSection()),
        new ChildOption(new models.CheckListSection()),
        new ChildOption(new models.MembersSection())
      ];
    } else if (content instanceof models.FieldsSection) {
      return [
        new ChildOption(new models.TextField()),
        new ChildOption(new models.NumberField()),
        new ChildOption(new models.FileField()),
        new ChildOption(new models.DateField()),
        new ChildOption(new models.SelectField()),
        new ChildOption(new models.CheckboxField()),
        new ChildOption(new models.VariantsField())
      ];
    } else if (content instanceof models.CheckListSection) {
      return [
        new ChildOption(new models.Position()),
        new ChildOption(new models.Group())
      ];
    } else if (content instanceof models.Group) {
      return [
        new ChildOption(new models.Position())
      ];
    } else if (Array.isArray(content) && this.newItem) {
      return [
        new ChildOption(this.newItem())
      ];
    }

    return [];
  }
}

export class FlatTreeControl<T> implements TreeControl<T> {
  constructor(
    public readonly getLevel: (dataNode: T) => number,
    public readonly isExpandable: (dataNode: T) => boolean,
    public readonly trackBy: (dataNode: T) => T,
  ) { }

  dataNodes: T[];

  expansionModel: SelectionModel<T> = new SelectionModel<T>(true);

  getChildren: (dataNode: T) => Observable<T[]> | T[] | undefined | null;

  isExpanded(dataNode: T): boolean {
    return this.expansionModel.isSelected(this._trackByValue(dataNode));
  }

  toggle(dataNode: T): void {
    this.expansionModel.toggle(this._trackByValue(dataNode));
  }

  expand(dataNode: T): void {
    this.expansionModel.select(this._trackByValue(dataNode));
  }

  collapse(dataNode: T): void {
    this.expansionModel.deselect(this._trackByValue(dataNode));
  }

  getDescendants(dataNode: T): T[] {
    const startIndex = this.dataNodes.indexOf(dataNode);
    const results: T[] = [];

    for (
      let i = startIndex + 1;
      i < this.dataNodes.length && this.getLevel(dataNode) < this.getLevel(this.dataNodes[i]);
      i++
    ) {
      results.push(this.dataNodes[i]);
    }
    return results;
  }

  toggleDescendants(dataNode: T): void {
    this.expansionModel.isSelected(this._trackByValue(dataNode))
      ? this.collapseDescendants(dataNode)
      : this.expandDescendants(dataNode);
  }

  expandDescendants(dataNode: T): void {
    let toBeProcessed = [dataNode];
    toBeProcessed.push(...this.getDescendants(dataNode));
    this.expansionModel.select(...toBeProcessed.map(value => this._trackByValue(value)));
  }

  collapseDescendants(dataNode: T): void {
    let toBeProcessed = [dataNode];
    toBeProcessed.push(...this.getDescendants(dataNode));
    this.expansionModel.deselect(...toBeProcessed.map(value => this._trackByValue(value)));
  }

  expandAll(): void {
    this.expansionModel.select(...this.dataNodes.map(node => this._trackByValue(node)));
  }

  collapseAll(): void {
    this.expansionModel.clear();
  }

  protected _trackByValue(value: T): T {
    return this.trackBy(value as T);
  }
}

class ChildOption {
  readonly name: string;

  constructor(public readonly content: any) {
    this.name = content.constructor.name;
  }
}

interface Selection {
  readonly content: any;
  readonly onChanged?: (value: any) => any;
}

interface NodeStackItem {
  readonly node: Node;
  readonly scrollTop: number;
  readonly selectedNodeId?: string;
}

@Component({
  selector: 'kt-template-content-edit',
  templateUrl: './template-content-edit.component.html',
  styleUrls: ['./template-content-edit.component.scss']
})
export class TemplateContentEditComponent implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private templateService: TemplateService,
  ) {
    this.dataSource.data = [];
  }

  private transformer = (node: Node, level: number) => {
    node.level = level;
    return node;
  };
  private treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private subscriptions: Subscription[] = [];
  private templateId: string = null;
  private contentId: string = null;
  private content: models.Template = null;
  private languageId: number;

  treeControl = new FlatTreeControl<Node>(
    node => node.level,
    node => node.expandable,
    node => node.id as any,
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  trackBy: TrackByFunction<Node> = (_, node) => node.id;
  loading$: Observable<boolean>;
  title: string = 'Редактор шаблона';
  private _selectedNode: Node = null;
  get selectedNode() { return this._selectedNode; }
  set selectedNode(value: Node) {
    this._selectedNode = value;
    if (!value) return;

    this._selectedContent = {
      content: value.content,
      onChanged: value.onChanged,
    };
  }
  private _selectedContent: Selection = null;
  get selectedContent() { return this._selectedContent; }
  dictionary: Map<string, Function> = new Map([
    ['refs', () => this.getInstructionIds()],
    ['checkListOptions', () => this.getCheckListOptions()],
  ]);

  private nodeStack: NodeStackItem[] = [];
  nodeIndex(): number {
    return this.nodeStack.length - 1;
  }

  hasChild = (node: Node) => node.expandable;

  ngOnInit() {
    const routeParamsSubscription = this.route.params.subscribe(params => {
      this.templateId = params['templateId'];
      const contentId = params['contentId'];
      if (contentId !== 'new')
        this.contentId = contentId;
      this.load();
    });
    this.subscriptions.push(routeParamsSubscription);

    const routeQueryParamsSubscription = this.route.queryParams.subscribe(queryParams => {
      this.languageId = queryParams['languageId'];
    });
    this.subscriptions.push(routeQueryParamsSubscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private load() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);
    forkJoin([
      this.templateService.getById(this.templateId),
      this.getTemplateContent()
    ]).subscribe(data => {
      this.title = data[0].title;
      this.content = data[1];
      this.fill();
      this.loadingSubject.next(false);
    })
  }

  private fill() {
    if (this.content) {
      this.nodeStack = [];
      this.removeTemplateDefaults();
      this.addToStack(new Node({ content: this.content }));
      this.buildTree();
      this.expandRootNodes();
    }
  }

  private removeTemplateDefaults() {
    for (const section of this.content.sections) {
      if (!(section instanceof models.CheckListSection)) continue;

      for (const group of section.groups) {
        this.removePositionsDefaults(group.positions);
      }
      this.removePositionsDefaults(section.positions);
    }
  }

  private removePositionsDefaults(positions: models.Position[]) {
    for (const position of positions) {
      this.removeRefDefaults(position);
      this.removeWeightDefault(position);
      for (const optionAttribute of position.optionAttributes) {
        this.removeWeightDefault(optionAttribute);
      }
      this.fixRequirementMaterials(position);
    }
  }

  private removeRefDefaults(position: models.Position) {
    if (!position.links) return;

    for (const link of position.links) {
      if (link.ref === '') {
        link.ref = null;
      }
    }
  }

  private removeWeightDefault(item: models.Position | models.OptionAttribute) {
    if (item.weight === 0) {
      item.weight = null;
    }
  }

  private fixRequirementMaterials(position: models.Position) {
    if (!position.requirement) return;

    const materials = position.requirement.materials;
    if (materials && models.isString(materials)) {
      position.requirement.materials = [`${materials}`];
    }
  }

  private getTemplateContent(): Observable<models.Template> {
    if (this.contentId) {
      return this.templateService.getContentById(this.templateId, this.contentId).pipe(
        map(content => {
          return plainToClass(models.Template, content, { excludeExtraneousValues: true, exposeDefaultValues: true });
        }));
    } else {
      return of(this.createTemplateContent());
    }
  }

  private createTemplateContent(): models.Template {
    const content = new models.Template();
    content.sections = [
      new models.FieldsSection(),
      new models.CheckListSection(),
      new models.MembersSection()
    ];
    return content;
  }

  private buildTree() {
    //clearing previous level info
    this.dataSource.data = [];

    this.dataSource.data = [this.nodeStack[this.nodeStack.length - 1].node];
    this.cdr.markForCheck();
  }

  private expandRootNodes() {
    if (this.treeControl.dataNodes) {
      this.treeControl.dataNodes.forEach(node => {
        if (node.level < 2 && this.treeControl.isExpandable(node)) {
          this.treeControl.expand(node);
        }
      });
    }
  }

  addNode(parentNode: Node, childOption: ChildOption) {
    const added = parentNode.addChild(cloneDeep(childOption.content));
    if (!added) return;

    this.buildTree();
    if (!this.treeControl.isExpanded(parentNode)) {
      this.treeControl.expand(parentNode);
    }
  }

  clone(node: Node) {
    const parentNode = this.getParent(node);
    const cloned = parentNode.cloneChild(node.id);
    if (!cloned) return;

    this.buildTree();
  }

  addSelectedNodeClone(parentNode: Node) {
    const selectedNode = this.selectedNode;
    if (!selectedNode) return;

    const added = parentNode.addChild(cloneDeep(selectedNode.content));
    if (!added) return;

    this.buildTree();
  }

  removeNode(node: Node) {
    const dialogRef = this.layoutUtilsService.deleteElement('Удаление', 'Вы действительно хотите удалить выбранный элемент?');
    dialogRef.afterClosed()
      .subscribe(remove => {
        if (!remove) return;

        const parentNode = this.getParent(node);
        if (!parentNode) return;

        const removed = parentNode.removeChild(node.id);
        if (!removed) return;

        this.buildTree();
        if (this.selectedNode === node) {
          this.selectedNode = null;
        }
      });
  }

  private getParent(node: Node): Node {
    const currentLevel = this.treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }

  selectNode(node: Node) {
    this.selectedNode = node;
  }

  toggleNode(node: Node) {
    this.treeControl.toggle(node);
  }

  private addToStack(node: Node) {
    this.nodeStack.push({
      node,
      scrollTop: this.getScrollTop(),
      selectedNodeId: this.selectedNode ? this.selectedNode.id : null,
    });
  }

  private getScrollTop(): number {
    return document.querySelector('#scrollContainer').scrollTop;
  }

  private setScrollTop(scrollTop: number) {
    document.querySelector('#scrollContainer').scrollTop = scrollTop;
  }

  goBack() {
    const stackItem = this.nodeStack.pop();
    this.buildTree();
    this.setScrollTop(stackItem.scrollTop);
    if (stackItem.selectedNodeId) {
      const selectCandidate = this.treeControl.dataNodes.find((node) => node.id === stackItem.selectedNodeId);
      if (!selectCandidate) return;

      this.selectedNode = selectCandidate;
    }
  }

  onCollectionOpen(obj: any) {
    if (this.selectedNode) {
      const childNode = this.selectedNode.children.find(node => node.content === obj.value);
      if (childNode) {
        this.addToStack(childNode);
        this.selectedNode = null;
        this.buildTree();
        this.expandRootNodes();
        return;
      }
    }

    if (Array.isArray(obj.value)) {
      this.addToStack(new Node({ content: obj.value, name: obj.name, newItem: () => new (obj.arrayType as any)() }));
    } else {
      this.addToStack(new Node({ content: obj.value, name: obj.name }));
    }
    this.selectedNode = null;
    this.buildTree();
    this.expandRootNodes();
  }

  dragStarted(event: CdkDragStart) {
    const matTreeNode = event.source.element.nativeElement;
    matTreeNode.setAttribute('dragging', '');
  }

  dragEnded(event: CdkDragEnd) {
    const matTreeNode = event.source.element.nativeElement;
    matTreeNode.removeAttribute('dragging');

    const matRipple = matTreeNode.children.item(0);
    if (matRipple && matRipple instanceof HTMLElement) {
      matRipple.dispatchEvent(new MouseEvent('mouseup'));
    }
  }

  drop(event: CdkDragDrop<any>) {
    if (!event.isPointerOverContainer) return;

    const node = event.item.data;
    const newFlatIndex = event.currentIndex;

    const parentNode = this.getParent(node);
    if (!parentNode) return;

    const visibleNodes = this.getVisibleNodes();
    if (newFlatIndex >= visibleNodes.length) return;

    const destinationNode = visibleNodes[newFlatIndex];
    const newIndex = parentNode.children.findIndex(child => child.id === destinationNode.id);
    if (newIndex === -1) return;

    const moved = parentNode.moveChild(node.id, newIndex);
    if (!moved) return;

    this.buildTree();
  }

  private getVisibleNodes(): Node[] {
    const nodes: any = [];

    function addExpandedChildren(node: Node, treeControl: FlatTreeControl<Node>) {
      nodes.push(node);
      if (treeControl.isExpanded(node)) {
        node.children.map((child) => addExpandedChildren(child, treeControl));
      }
    }

    this.dataSource.data.forEach((node) => addExpandedChildren(node, this.treeControl));
    return nodes;
  }

  private getInstructionIds() {
    if (!this.content) return [];

    const ids: OptionItem[] = [];
    for (const instruction of this.content.instructions) {
      if (!instruction.id) continue;

      ids.push(new OptionItem(instruction.title, instruction.id));
    }
    return ids;
  }

  private getCheckListOptions() {
    if (!this.content) return [];

    const checkListOptions: OptionItem[] = [];
    for (const section of this.content.sections) {
      if (!(section instanceof models.CheckListSection)) continue;

      section.options.forEach((option) => checkListOptions.push(new OptionItem(option.type, option.type)));
    }
    return checkListOptions;
  }

  close() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  save(close: boolean = false) {
    const jsonFile = new File([JSON.stringify(this.content)], 'template.json', { type: 'application/json' });
    this.loadingSubject.next(true);
    this.templateService.addContent(this.templateId, this.languageId, jsonFile)
      .subscribe(() => {
        this.loadingSubject.next(false);
        this.layoutUtilsService.showActionNotification('Файл шаблона сохранён', MessageType.Update, 3000, true, false);
        if (close) {
          this.close();
        }
      });
  }
}