import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { isFormGroupControlHasError } from '../../../../../common/validation/validation-utils'
import { ExportOptions } from './export-options';

@Component({
    selector: 'kt-export-dialog',
    templateUrl: './export-dialog.component.html'
})
export class ExportDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ExportDialogComponent, ExportOptions>,
        private fb: FormBuilder,
    ) { }

    form: FormGroup;
    contents = new FormArray([]);
    viewLoading = false;

    ngOnInit() {
        this.createForm();
    }

    createForm() {
        const endDate = new Date();
        const startDate = new Date(
            endDate.getFullYear(),
            endDate.getMonth() - 1,
            endDate.getDate(),
        );

        this.form = this.fb.group({
            startDate: [startDate, Validators.required],
            endDate: [endDate, Validators.required],
            includeDeleted: [false, Validators.required],
        });
    }

    startDateFilter = (d: Date | null): boolean => {
        const day = d || new Date();
        const endDate: Date = this.form.controls.endDate.value;
        if (endDate) {
            return day <= endDate;
        }
        return true;
    };

    endDateFilter = (d: Date | null): boolean => {
        const day = d || new Date();
        const startDate: Date = this.form.controls.startDate.value;
        if (startDate) {
            return day >= startDate;
        }
        return true;
    };

    save() {
        const controls = this.form.controls;
        const options = {
            from: controls.startDate.value.toISOString(),
            to: controls.endDate.value.toISOString(),
            includeDeleted: controls.includeDeleted.value,
        }
        this.dialogRef.close(options);
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        return isFormGroupControlHasError(this.form, controlName, validationType);
    }
}
