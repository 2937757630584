import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DocumentType, DocumentTypeListItem } from '../models/document-types';
import { PagedResponse } from '../models/paged-response.interface';

const API_URL = 'api/document-types';

@Injectable()
export class DocumentTypeService {
    constructor(private http: HttpClient) { }

    get(searchValue: string, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<DocumentTypeListItem>>(API_URL, { params: params });
    }

    getById(documentTypeId: number) {
        return this.http.get<DocumentType>(`${API_URL}/${documentTypeId}`);
    }

    update(documentType: DocumentType) {
        return this.http.put(API_URL, {
            id: documentType.id,
            contents: documentType.contents
        });
    }
}
