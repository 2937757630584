import { of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { BaseDataSource } from '../../../../common/data/base-data-source';

import { DocumentTypeListItem } from '../../../../api/models/document-types'
import { DocumentTypeService } from '../../../../api/services';
import { PagedResponse } from '../../../../api/models/paged-response.interface';

export class DocumentTypeDataSource extends BaseDataSource<DocumentTypeListItem> {

    constructor(private documentTypeService: DocumentTypeService) {
        super();
    }

    load(searchValue: string, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        this.loadingSubject.next(true);
        this.documentTypeService.get(searchValue, isDeleted, sortField, sortDirection, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<DocumentTypeListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
