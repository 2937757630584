import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {
    AdministrativeAreaService,
    AdministratorService,
    ConfigurationService,
    ContactService, ConversationService,
    CountryPhoneCodeService,
    CountryService,
    DashboardService,
    DictionaryService,
    DirectionService,
    DocumentService,
    DocumentTypeService,
    InstructionService,
    LanguageService,
    MaterialService,
    PretensionService,
    ProductService,
    ProjectService,
    PromoCodeService,
    ServiceService,
    StoryService,
    SuggestionService,
    TemplateService,
    UnitService,
    UserService
} from './services';
import {AccessTokenService} from './services/access-token.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        AdministrativeAreaService,
        AdministratorService,
        ContactService,
        CountryPhoneCodeService,
        CountryService,
        ConversationService,
        DictionaryService,
        DirectionService,
        DocumentService,
        DocumentTypeService,
        InstructionService,
        LanguageService,
        MaterialService,
        ProductService,
        ProjectService,
        PromoCodeService,
        ServiceService,
        StoryService,
        TemplateService,
        UnitService,
        UserService,
        SuggestionService,
        PretensionService,
        ConfigurationService,
        DashboardService,
        AccessTokenService
    ]
})
export class ApiModule {
}
