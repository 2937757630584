import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Contact} from '../models/contacts/contact.interface';
import {PagedResponse} from '../models/paged-response.interface';

const API_URL = 'api/contacts';

@Injectable()
export class ContactService {
    constructor(private http: HttpClient) {
    }

    get(searchValue: string,
        countryId: number,
        administrativeAreaId: string,
        isDeleted: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('countryId', countryId ? countryId.toString() : '')
            .set('administrativeAreaId', administrativeAreaId ? administrativeAreaId : '')
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<Contact>>(API_URL, {params});
    }

    getById(contactId: number) {
        return this.http.get<Contact>(`${API_URL}/${contactId}`);
    }

    add(contact: Contact) {
        return this.http.post<Contact>(API_URL, {
            firstName: contact.firstName,
            lastName: contact.lastName,
            middleName: contact.middleName,
            company: contact.company,
            position: contact.position,
            phoneNumber: contact.phoneNumber,
            email: contact.email,
            countryIdentifiers: contact.countryIdentifiers,
            administrativeAreaIdentifiers: contact.administrativeAreaIdentifiers,
        });
    }

    update(contact: Contact) {
        return this.http.put(API_URL, {
            id: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            middleName: contact.middleName,
            company: contact.company,
            position: contact.position,
            phoneNumber: contact.phoneNumber,
            email: contact.email,
            countryIdentifiers: contact.countryIdentifiers,
            administrativeAreaIdentifiers: contact.administrativeAreaIdentifiers,
        });
    }

    updatePhoto(contactId: number, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put(`${API_URL}/${contactId}/photo`, formData);
    }

    restore(contactId: number) {
        return this.http.patch(`${API_URL}/${contactId}/restore`, {});
    }

    delete(contactId: number) {
        return this.http.delete(`${API_URL}/${contactId}`);
    }
}
