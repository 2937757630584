import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';

import { TemplateListItem } from '../../../../../api/models/templates';
import { TemplateService } from '../../../../../api/services';
import { TemplateDataSource } from '../../data-sources';

@Component({
  selector: 'kt-template-search',
  templateUrl: './template-search.component.html',
  styleUrls: ['./template-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateSearchComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TemplateSearchComponent>,
    private templateService: TemplateService) {
  }

  private subscriptions: Subscription[] = [];

  viewLoading = false;

  dataSource: TemplateDataSource;
  displayedColumns = ['title', 'documentType', 'version', 'isEngineering', 'actions'];

  ngOnInit() {
    this.dataSource = new TemplateDataSource(this.templateService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.load();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      null,
      false,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  select(item: TemplateListItem) {
    this.dialogRef.close({
      template: {
        id: item.id,
        title: item.title,
        documentType: item.documentType,
        version: item.version
      }
    });
  }
}
