// Angular
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, Output, SimpleChanges, TemplateRef,
    ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material';
import { OptionItem, PropertyItemMeta, isNumber, isString } from '../models/template-content.interface';

export class PropertyDefinition {
    constructor(
        private content: any,
        public key: string,
        public description: string,
        public type: string,
        public required: boolean,
        public options?: OptionItem[],
        public arrayType?: any,
        public self?: boolean,
        public onChanged?: (value: any) => any,
    ) {
        this._value = this.self === true ? content : content[key];
    }

    private _value: any;

    get value() { return this._value; }
    set value(newValue: any) {
        this._value = newValue;
        if (this.self === true) {
            this.content = newValue;
        } else {
            this.content[this.key] = newValue;
        }

        if (this.onChanged) {
            this.onChanged(newValue);
        }
    }
}

@Component({
    selector: 'property-list',
    templateUrl: './property-list.component.html',
    styleUrls: ['./property-list.component.scss']
})
export class PropertyListComponent {

    constructor(
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef
    ) { }

    @ViewChild('string', { static: false }) string: TemplateRef<any>;
    @ViewChild('boolean', { static: false }) boolean: TemplateRef<any>;
    @ViewChild('number', { static: false }) number: TemplateRef<any>;
    @ViewChild('array', { static: false }) array: TemplateRef<any>;
    @ViewChild('options', { static: false }) options: TemplateRef<any>;
    @ViewChild('object', { static: false }) object: TemplateRef<any>;

    @Input() content: any;
    @Input() dictionary: Map<string, Function>;
    @Output() readonly collectionOpen: EventEmitter<any> = new EventEmitter<any>();

    propertyDefinitions: PropertyDefinition[] = [];

    ngOnChanges(changes: SimpleChanges) {
        this.fill(changes['content'].currentValue);
    }

    private toPascalCase(name: string): string {
        return name.charAt(0).toUpperCase() + name.substring(1);
    }

    private fill(data: any) {
        const definitions: PropertyDefinition[] = [];
        const content = data.content;
        if (content && content.__meta__) {
            const meta = content.__meta__;
            for (const i in meta) {
                if (!meta.hasOwnProperty(i)) {
                    continue;
                }

                const v: PropertyItemMeta = meta[i];
                const required = v.required;
                let description = v.description;
                if (description && required) {
                    description += '\r\n(Обязательное поле)';
                }
                const type = v.type;
                let arrayType: any = null;
                if (type === 'array' || type === 'object') {
                    arrayType = v.arrayType;
                    if (!arrayType) {
                        arrayType = String;
                    }
                }
                let options: OptionItem[];
                if (v.options) {
                    options = v.options.map(e => new OptionItem(e, e));
                } else if (v.optionsKey && this.dictionary.has(v.optionsKey)) {
                    options = this.dictionary.get(v.optionsKey)();
                }
                definitions.push(new PropertyDefinition(content, i, description, type, required, options, arrayType));
            }
        } else if (isString(content)) {
            definitions.push(new PropertyDefinition(content, 'value', 'Значение\r\n(Обязательное поле)', 'string', true, null, null, true, data.onChanged));
        } else if (isNumber(content)) {
            definitions.push(new PropertyDefinition(content, 'value', 'Значение\r\n(Обязательное поле)', 'number', true, null, null, true, data.onChanged));
        }
        this.propertyDefinitions = definitions;
        this.cdr.detectChanges();
    }

    openCollectionEditor(propertyDefinition: PropertyDefinition) {
        if (!Array.isArray(propertyDefinition.value) && !propertyDefinition.value) {
            let object = new (propertyDefinition.arrayType as any)();
            propertyDefinition.value = object;
        }
        this.collectionOpen.emit({
            key: propertyDefinition.key,
            name: this.toPascalCase(propertyDefinition.key),
            value: propertyDefinition.value,
            arrayType: propertyDefinition.arrayType,
        });
    }

    clearValue(propertyDefinition: PropertyDefinition) {
        propertyDefinition.value = undefined;
        this.cdr.detectChanges();
    }
}
