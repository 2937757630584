import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
    selector: '[textareaAutosize]'
})
export class TextareaAutosizeDirective implements OnInit {

    constructor(private elementRef: ElementRef) { }

    ngOnInit() {
        setTimeout(() => this.resize());
    }

    @HostListener(':input')
    onInput() {
        this.resize();
    }

    private resize() {
        const nativeElement = this.elementRef.nativeElement;
        if (!nativeElement.scrollHeight) return;

        nativeElement.style.height = "19px";
        nativeElement.style.height = nativeElement.scrollHeight + 'px';
    }
}