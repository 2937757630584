import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { MessageType, LayoutUtilsService } from '../../../../../core/_base/crud';

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { Service } from '../../../../../api/models/services';
import { ServiceService, DictionaryService } from '../../../../../api/services';

@Component({
  selector: 'kt-service-edit',
  templateUrl: './service-edit.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./service-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceEditComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
    private serviceService: ServiceService,
    private dictionaryService: DictionaryService
  ) { }

  loading$: Observable<boolean>;
  titleFormControl = new FormControl('', [
	  Validators.required,
	  Validators.maxLength(150)
  ]);
  descriptionFormControl = new FormControl('', Validators.maxLength(500));
  urlFormControl = new FormControl('', [
	  Validators.required,
	  Validators.maxLength(2048)
  ]);
  orderIndex: number;
  languages: ListItem[] = [];
  languageId = 1;
  coverUrl: string | ArrayBuffer;
  isPublished = false;
  selectedTabIndex = 0;

  private subscriptions: Subscription[] = [];
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private serviceId: string = null;
  private service: Service = null;
  private coverFile: File = null;

  ngOnInit() {
    const routeSubscription = this.route.params.subscribe(params => {
      const serviceId = params['serviceId'];
      if (serviceId !== 'new')
        this.serviceId = serviceId;
      this.load();
    });

    this.subscriptions.push(routeSubscription);
  }

  private load() {
    this.loading$ = this.loadingSubject.asObservable();
    this.loadingSubject.next(true);
    if (this.serviceId) {
      forkJoin([
        this.serviceService.getById(this.serviceId),
        this.dictionaryService.getLanguages()
      ])
        .subscribe(data => {
          this.service = data[0];
          this.languages = data[1];
          this.fill();
          this.loadingSubject.next(false);
        })
    }
    else {
      this.dictionaryService.getLanguages()
        .subscribe(languages => {
          this.languages = languages;
          this.fill();
          this.loadingSubject.next(false);
        });
    }
  }

  private fill() {
    if (!this.service) {
      return;
    }

    this.coverUrl = this.service.coverUrl;
    this.titleFormControl.setValue(this.service.title);
    this.descriptionFormControl.setValue(this.service.description);
    this.languageId = this.service.languageId;
    this.isPublished = this.service.isPublished;
	this.urlFormControl.setValue(this.service.url);
	this.orderIndex = this.service.orderIndex;

    this.cdr.markForCheck();
  }

  onCoverChanged(files: FileList) {
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    if (file.type.match(/image\/*/) == null) {
      return;
    }

    this.coverFile = file;

    var reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.coverUrl = reader.result;
      this.cdr.markForCheck();
    }
  }

  save() {
    if (this.titleFormControl.invalid) {
      this.titleFormControl.markAsTouched();
      this.selectedTabIndex = 0;
      return;
    }

    if (this.descriptionFormControl.invalid) {
      this.descriptionFormControl.markAsTouched();
      this.selectedTabIndex = 0;
      return;
    }

	if (this.urlFormControl.invalid) {
		this.urlFormControl.markAsTouched();
		this.selectedTabIndex = 0;
		return;
	}

    if (this.serviceId) {
      this.update();
    } else {
      this.create();
    }
  }

  private create() {
    this.loadingSubject.next(true);
    this.serviceService.add(
		this.titleFormControl.value,
		this.descriptionFormControl.value,
		this.languageId,
		this.urlFormControl.value,
		this.orderIndex)
      .subscribe(service => {
        this.updateFiles(service.id);
      });
  }

  private update() {
    this.loadingSubject.next(true);
    this.serviceService.update(
		this.service.id,
		this.titleFormControl.value,
		this.descriptionFormControl.value,
		this.languageId,
		this.urlFormControl.value,
		this.orderIndex)
      .subscribe(() => {
        this.updateFiles(this.service.id);
      });
  }

  private updateFiles(serviceId: string) {
    const items: any[] = [];

    if (this.coverFile) {
      items.push(this.serviceService.updateCover(serviceId, this.coverFile));
    }

    if (!this.service || this.service.isPublished !== this.isPublished) {
      if (this.isPublished) {
        items.push(this.serviceService.publish(serviceId));
      } else {
        items.push(this.serviceService.unpublish(serviceId));
      }
    }

    forkJoin(items)
      .subscribe({
        complete: () => {
          this.loadingSubject.next(false);
          this.reset();
          this.redirect(serviceId);
        }
      });
  }

  private reset() {
    this.coverFile = null;
  }

  private redirect(serviceId: string) {
    if (!this.service) {
      this.layoutUtilsService.showActionNotification('Услуга добавлена', MessageType.Create, 3000, true, false);
      this.router.navigate(['../', serviceId], { relativeTo: this.route });
    } else {
      this.layoutUtilsService.showActionNotification('Услуга обновлена', MessageType.Update, 3000, true, false);
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
}
