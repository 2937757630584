import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {Pretension} from '../../../../../api/models/pretensions';
import {PretensionService} from '../../../../../api/services';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'kt-pretension-detail',
    templateUrl: './pretension-detail.component.html',
    styleUrls: ['./pretension-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PretensionDetailComponent implements OnInit, OnDestroy {
    protected pretension: Pretension = null;
    protected pretensionId: string;
    private readonly destroy$ = new Subject<void>();
    private readonly loadingSubject = new BehaviorSubject<boolean>(true);
    protected readonly loading$: Observable<boolean> = this.loadingSubject.asObservable();

    constructor(
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private pretensionService: PretensionService
    ) {
    }

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(params => {
                if (params.pretensionId !== 'new') {
                    this.pretensionId = params.pretensionId;
                    this.load();
                }
            });
    }

    ngOnDestroy(): void {
        this.loadingSubject.complete();

        this.destroy$.complete();
        this.destroy$.next();
    }

    protected openImageInNewTab(image: string) {
        window.open('', '_blank').document.write(`
		<html lang="">
			<body>
				<img src="${window.location.origin + image}" alt="image" width="100%">
			</body>
		</html>
	`);
    }

    private load() {
        this.loadingSubject.next(true);

        if (this.pretensionId) {
            this.pretensionService.getById(this.pretensionId)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    this.pretension = data;
                    this.cdr.markForCheck();
                    this.loadingSubject.next(false);
                });
        }
    }
}
