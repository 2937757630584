// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
//Modules
import { ApiModule } from '../../../api/api.module';
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { MaterialModule } from '../../../common/modules/material.module';
// Services
import {
  HttpUtilsService,
  TypesUtilsService,
  LayoutUtilsService
} from '../../../core/_base/crud';
import {
  ActionNotificationComponent,
  ConfirmationDialogComponent,
  FetchEntityDialogComponent,
  UpdateStatusDialogComponent
} from '../../../views/partials/content/crud';
// Components
import { UserListComponent } from './users/user-list/user-list.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserPhoneEditComponent } from './users/user-phone-edit/user-phone-edit.component';
import { AdministratorListComponent } from './administrators/administrator-list/administrator-list.component';
import { ProfileEditComponent } from './profiles/profile-edit/profile-edit.component';
import { ProfilePasswordEditComponent } from './profiles/profile-password-edit/profile-password-edit.component';
import { PromoCodeListComponent } from './promo-codes/promo-code-list/promo-code-list.component';
import { PromoCodeEditComponent } from './promo-codes/promo-code-edit/promo-code-edit.component';
import { PromoCodeUploadComponent } from './promo-codes/promo-code-upload/promo-code-upload.component';

@NgModule({
  declarations: [
    UserListComponent,
    UserEditComponent,
    UserPhoneEditComponent,
    AdministratorListComponent,
    ProfileEditComponent,
    ProfilePasswordEditComponent,
    PromoCodeListComponent,
    PromoCodeEditComponent,
    PromoCodeUploadComponent
  ],
  exports: [],
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    FormsModule,

    ApiModule,
    CoreModule,
    PartialsModule,

    MaterialModule
  ],
  providers: [
    TypesUtilsService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService
  ],
  entryComponents: [
    ActionNotificationComponent,
    ConfirmationDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent,
    UserPhoneEditComponent,
    ProfilePasswordEditComponent,
    PromoCodeEditComponent,
    PromoCodeUploadComponent
  ]
})
export class UsersModule { }
