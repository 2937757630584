import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SuggestionListItem, Suggestion } from '../models/suggestions';
import { PagedResponse } from '../models/paged-response.interface';

const API_URL = 'api/suggestions';

@Injectable()
export class SuggestionService {
    constructor(private http: HttpClient) { }

    get(
		searchValue: string,
		isDeleted: boolean,
		sortField: string,
		sortDirection: string,
		pageIndex: number,
		pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<SuggestionListItem>>(API_URL, { params: params });
    }

    getById(suggestionId: string) {
        return this.http.get<Suggestion>(`${API_URL}/${suggestionId}`);
    }
}
