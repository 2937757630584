import {of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {BaseDataSource} from '../../../../common/data/base-data-source';
import {PagedResponse} from '../../../../api/models/paged-response.interface';
import {ConversationListItemModel} from '../../../../api/models/conversations';
import {ConversationService} from '../../../../api/services';

export class ConversationDataSource extends BaseDataSource<ConversationListItemModel> {

    constructor(private conversationService: ConversationService) {
        super();
    }

    load(documentId: string,
         isDeleted: boolean,
         sortField: string,
         sortDirection: string,
         pageIndex: number,
         pageSize: number) {
        this.loadingSubject.next(true);

        this.conversationService.get(
            documentId,
            isDeleted,
            sortField,
            sortDirection,
            pageIndex,
            pageSize
        ).pipe(
            catchError(() => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe((response: PagedResponse<ConversationListItemModel>) => {
            this.paginatorTotalSubject.next(response.total);
            this.itemsSubject.next(response.items);
        });
    }
}
