import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PagedResponse} from '../models/paged-response.interface';
import {ConversationListItemModel, AssignSpecialistModel} from '../models/conversations';

const API_URL = 'api/conversations';

@Injectable()
export class ConversationService {
    constructor(private http: HttpClient) {
    }

    get(documentId: string,
        isDeleted: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        const params = new HttpParams()
            .set('documentId', documentId)
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<ConversationListItemModel>>(API_URL, {params});
    }

    assign(id: number, specialist: AssignSpecialistModel) {
        return this.http.patch(`${API_URL}/${id}/assign`, specialist);
    }

    close(id: number) {
        return this.http.patch(`${API_URL}/${id}/close`, {});
    }

    restore(id: number) {
        return this.http.patch(`${API_URL}/${id}/restore`, {});
    }

    delete(id: number) {
        return this.http.delete(`${API_URL}/${id}`);
    }
}
