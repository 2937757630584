import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Story, StoryListItem, StorySlide } from '../models/stories';
import { PagedResponse } from '../models/paged-response.interface';

const API_URL = 'api/stories';

@Injectable()
export class StoryService {
    constructor(private http: HttpClient) { }

    get(searchValue: string, languageId: number, isPublished: boolean, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('languageId', languageId.toString())
            .set('isPublished', isPublished === true ? 'true' : isPublished === false ? 'false' : '')
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<StoryListItem>>(API_URL, { params: params });
    }

    getById(storyId: string) {
        return this.http.get<Story>(`${API_URL}/${storyId}`);
    }

    restore(storyId: string) {
        return this.http.patch(`${API_URL}/${storyId}/restore`, {});
    }

    delete(storyId: string) {
        return this.http.delete(`${API_URL}/${storyId}`);
    }

    add(title: string, description: string, languageId: number) {
        return this.http.post<Story>(`${API_URL}/`, {
            title,
            description,
            languageId
        });
    }

    update(storyId: string, title: string, description: string, languageId: number) {
        return this.http.put(`${API_URL}`, {
            id: storyId,
            title,
            description,
            languageId
        });
    }

    updateImage(storyId: string, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put(`${API_URL}/${storyId}/image`, formData);
    }

    publish(storyId: string) {
        return this.http.patch(`${API_URL}/${storyId}/publish`, {});
    }

    unpublish(storyId: string) {
        return this.http.patch(`${API_URL}/${storyId}/unpublish`, {});
    }

    addSlide(storyId: string, slide: StorySlide) {
        return this.http.post<StorySlide>(`${API_URL}/${storyId}/slides`, {
            storyId,
            title: slide.title,
            description: slide.description,
			buttonTitle: slide.buttonTitle,
            link: slide.link,
            textColor: slide.textColor,
            backgroundColor: slide.backgroundColor,
            order: slide.order,
        });
    }

    updateSlide(storyId: string, slide: StorySlide) {
        return this.http.put(`${API_URL}/${storyId}/slides`, {
            id: slide.id,
            storyId,
            title: slide.title,
            description: slide.description,
			buttonTitle: slide.buttonTitle,
            link: slide.link,
            textColor: slide.textColor,
            backgroundColor: slide.backgroundColor,
            order: slide.order,
        });
    }

    deleteSlide(storyId: string, slideId: string) {
        return this.http.delete(`${API_URL}/${storyId}/slides/${slideId}`);
    }

    updateSlideImage(storyId: string, slideId: string, file: any) {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.put(`${API_URL}/${storyId}/slides/${slideId}/image`, formData);
    }
}
