import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';

import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {fromEvent, merge, Subscription} from 'rxjs';

import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {ListItem} from '../../../../../api/models/dictionaries/list-item.interface';
import {DictionaryService, ProductService} from '../../../../../api/services';
import {ProductDataSource} from '../../data-sources';
import {PersistentComponent, RestoredState, SavingState, StateService} from '../../../../../common/data/state.service';

@Component({
    selector: 'kt-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductListComponent implements OnInit, AfterViewInit, OnDestroy, PersistentComponent {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    dataSource: ProductDataSource;
    displayedColumns = ['image', 'name', 'direction', 'isEnabled', 'isDeleted', 'created', 'modified', 'actions'];
    languages: ListItem[] = [];
    directions: ListItem[] = [];
    languageId = 1;
    directionId: number = null;
    isEnabled = '';
    isDeleted = 'false';
    sortActive = 'modified';
    sortDirection = 'desc';
    pageIndex = 0;
    pageSize = 10;
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private productService: ProductService,
        private dictionaryService: DictionaryService,
        private stateService: StateService) {
    }

    ngOnInit() {
        this.dataSource = new ProductDataSource(this.productService);

        this.stateService.restore(this);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.load())
            ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.filter();
                })
            ).subscribe();
        this.subscriptions.push(searchSubscription);

        this.dictionaryService.getLanguages()
            .subscribe(languages => {
                this.languages = languages;
            });

        this.dictionaryService.getDirections()
            .subscribe(directions => {
                this.directions = directions;
            });
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.stateService.save(this);
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        this.dataSource.load(
            this.searchInput.nativeElement.value,
            this.languageId,
            this.directionId,
            null,
            this.isEnabled === 'true' ? true : this.isEnabled === 'false' ? false : null,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    restore(productId: string) {
        const dialogRef = this.layoutUtilsService.confirmAction('Восстановление',
            'Вы действительно хотите восстановить систему?',
            'Восстановление системы...',
            'Восстановить');
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.productService.restore(productId)
                    .subscribe(
                        _ => {
                            this.layoutUtilsService.showActionNotification('Система была восстановлена.',
                                MessageType.Update, 3000, true, false);
                            this.load();
                        },
                        _ => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении системы.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });

    }

    delete(productId: string) {
        const dialogRef = this.layoutUtilsService.deleteElement('Удаление',
            'Вы действительно хотите удалить систему?', 'Удаление системы...');
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.productService.delete(productId)
                    .subscribe(
                        _ => {
                            this.layoutUtilsService.showActionNotification('Система была удалён.',
                                MessageType.Delete, 3000, true, false);
                            this.load();
                        },
                        _ => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении системы.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });
    }

    saveState(state: SavingState) {
        state
            .set('name', this.searchInput.nativeElement.value)
            .set('languageId', this.languageId)
            .set('directionId', this.directionId)
            .set('isEnabled', this.isEnabled)
            .set('isDeleted', this.isDeleted)
            .set('sortActive', this.sort.active)
            .set('sortDirection', this.sort.direction)
            .set('pageIndex', this.paginator.pageIndex)
            .set('pageSize', this.paginator.pageSize);
    }

    restoreState(state: RestoredState) {
        this.searchInput.nativeElement.value = state.get('name');
        this.languageId = state.get('languageId');
        this.directionId = state.get('directionId');
        this.isEnabled = state.get('isEnabled');
        this.isDeleted = state.get('isDeleted');
        this.sortActive = state.get('sortActive');
        this.sortDirection = state.get('sortDirection');
        this.pageIndex = state.get('pageIndex');
        this.pageSize = state.get('pageSize');
    }
}
