import {of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {BaseDataSource} from '../../../../common/data/base-data-source';

import {TemplateListItem} from '../../../../api/models/templates';
import {TemplateService} from '../../../../api/services';
import {PagedResponse} from '../../../../api/models/paged-response.interface';

export class TemplateDataSource extends BaseDataSource<TemplateListItem> {

    constructor(private templateService: TemplateService) {
        super();
    }

    load(searchValue: string,
         isEngineering: boolean,
         isDeleted: boolean,
         sortField: string,
         sortDirection: string,
         pageIndex: number,
         pageSize: number) {
        this.loadingSubject.next(true);
        this.subscriptions.push(this.templateService.get(searchValue,
            isEngineering,
            isDeleted,
            sortField,
            sortDirection,
            pageIndex,
            pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<TemplateListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            }));
    }
}
