import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DocumentListItem} from '../models/documents';
import {PagedResponse} from '../models/paged-response.interface';

const API_URL = 'api/documents';

@Injectable()
export class DocumentService {
    constructor(private http: HttpClient) {
    }

    get(searchValue: string,
        searchProjectValue: string,
        documentId: string,
        productId: string,
        projectId: string,
        userId: string,
        userPhoneNumber: string,
        isDeleted: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('searchProjectValue', searchProjectValue)
            .set('documentId', documentId)
            .set('productId', productId ? productId : '')
            .set('projectId', projectId ? projectId : '')
            .set('userId', userId ? userId : '')
            .set('userPhoneNumber', userPhoneNumber ? encodeURIComponent(userPhoneNumber) : '')
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<DocumentListItem>>(API_URL, {params});
    }

    restore(documentId: string) {
        return this.http.patch(`${API_URL}/${documentId}/restore`, {});
    }

    delete(documentId: string) {
        return this.http.delete(`${API_URL}/${documentId}`);
    }
}
