import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material';

import {BehaviorSubject, forkJoin, Observable, Subject} from 'rxjs';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {
    getErrorMessage,
    hasErrorCode,
    isFormGroupControlHasError,
    markFormGroupTouched,
    setUserFormError
} from '../../../../../common/validation/validation-utils';

import {User, UserPhoneNumber} from '../../../../../api/models/users';
import {ListItem} from '../../../../../api/models/dictionaries/list-item.interface';
import {DictionaryService, UserService} from '../../../../../api/services';

import {UserPhoneEditComponent} from '../user-phone-edit/user-phone-edit.component';
import {filter, finalize, takeUntil} from 'rxjs/operators';
import {UserTypes} from '../../user-types';

@Component({
    selector: 'kt-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy {
    protected readonly UserTypes = UserTypes;

    private readonly destroy$ = new Subject<void>();

    private loadingSubject = new BehaviorSubject<boolean>(true);
    protected readonly loading$: Observable<boolean> = this.loadingSubject.asObservable();

    private userId: string = null;
    private user: User = null;

    form: FormGroup;
    hasFormErrors = false;
    errorMessage = '';
    languages: ListItem[];
    phoneHistory: UserPhoneNumber[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private layoutUtilsService: LayoutUtilsService,
        private userService: UserService,
        private dictionaryService: DictionaryService
    ) {
    }

    ngOnInit() {
        this.createForm();

        this.route.params
            .pipe(takeUntil(this.destroy$))
            .subscribe(params => {
                this.userId = params.userId;
                this.load();
            });
    }

    ngOnDestroy(): void {
        this.destroy$.complete();
        this.destroy$.next();
    }

    save() {
        this.hasFormErrors = false;

        if (this.form.invalid) {
            markFormGroupTouched(this.form);
            return;
        }

        const user = this.prepare();
        this.update(user);
    }

    editPhone() {
        const dialogRef = this.dialog.open(UserPhoneEditComponent, {
            data: {userId: this.userId},
            width: '600px'
        });

        dialogRef.afterClosed().pipe(
            takeUntil(this.destroy$),
            filter(dialogResult => !!dialogResult)
        ).subscribe(() => {
            this.layoutUtilsService.showActionNotification(
                'Номер телефона изменён',
                MessageType.Update,
                1000,
                true,
                false);

            this.load();
        });
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        return isFormGroupControlHasError(this.form, controlName, validationType);
    }

    onAlertClose() {
        this.hasFormErrors = false;
        this.errorMessage = '';
    }

    private createForm() {
        this.form = this.fb.group({
            lastName: [null, Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50)]
            )],
            firstName: [null, Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50)]
            )],
            middleName: [null, Validators.maxLength(50)],
            company: [null, Validators.compose([
                Validators.minLength(2),
                Validators.maxLength(250)]
            )],
            position: [null, Validators.compose([
                Validators.minLength(3),
                Validators.maxLength(250)]
            )],
            languageId: [null, Validators.required],
            type: [null, Validators.required],
            phoneNumber: [null],
            email: [null, Validators.maxLength(100)],
            isTestingAllowed: [false, Validators.required],
            isEngineer: [false, Validators.required]
        });
    }

    private load() {
        this.loadingSubject.next(true);

        forkJoin([
            this.userService.getById(this.userId),
            this.dictionaryService.getLanguages(),
            this.userService.getPhoneHistory(this.userId),
        ]).subscribe(result => {
            this.user = result[0];
            this.languages = result[1];
            this.phoneHistory = result[2];
            this.fill();

            this.loadingSubject.next(false);
        });
    }

    private fill() {
        const controls = this.form.controls;

        controls.lastName.setValue(this.user.lastName);
        controls.firstName.setValue(this.user.firstName);
        controls.middleName.setValue(this.user.middleName);
        controls.company.setValue(this.user.company);
        controls.position.setValue(this.user.position);
        controls.languageId.setValue(this.user.languageId);
        controls.languageId.disable();
        controls.type.setValue(this.user.type);
        controls.phoneNumber.setValue(this.user.phoneNumber);
        controls.phoneNumber.disable();
        controls.email.setValue(this.user.email);
        controls.isTestingAllowed.setValue(this.user.isTestingAllowed);
        controls.isEngineer.setValue(this.user.isEngineer);

        this.cdr.markForCheck();
    }

    private prepare(): User {
        const controls = this.form.controls;
        return {
            id: this.user.id,
            firstName: controls.firstName.value,
            lastName: controls.lastName.value,
            middleName: controls.middleName.value,
            company: controls.company.value,
            position: controls.position.value,
            languageId: controls.languageId.value,
            phoneNumber: null,
            email: controls.email.value,
            theme: this.user.theme,
            userTheme: this.user.userTheme,
            type: controls.type.value,
            isTestingAllowed: controls.isTestingAllowed.value,
            isEngineer: controls.isEngineer.value,
        };
    }

    private update(user: User) {
        this.loadingSubject.next(true);

        this.userService.update(user)
            .pipe(
                takeUntil(this.destroy$),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe({
                next: () => {
                    this.redirect();
                },
                error: (response) => {
                    if (hasErrorCode(response)) {
                        this.hasFormErrors = true;
                        this.errorMessage = getErrorMessage(response);
                    } else {
                        setUserFormError(this.form, response);
                    }

                    this.cdr.markForCheck();
                }
            });
    }

    private redirect() {
        this.layoutUtilsService.showActionNotification(
            'Пользователь обновлён',
            MessageType.Update,
            3000,
            true,
            false);

        this.router.navigate(['../'], {relativeTo: this.route}).finally();
    }
}

