// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Store
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, Login } from '../../../../core/auth';
import { isFormGroupControlHasError, markFormGroupTouched } from '../../../../common/validation/validation-utils';
import { SignInResponse } from '../../../../core/auth/_models/sign-in-response';

@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	form: FormGroup;
	loading = false;

	private unsubscribe: Subject<any>;

	private returnUrl: any;

	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute
	) {
		this.unsubscribe = new Subject();
	}

	ngOnInit(): void {
		this.createForm();

		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/';
		});
	}

	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	private createForm() {
		this.form = this.fb.group({
			email: [null, Validators.compose([
				Validators.required,
				Validators.email,
				Validators.maxLength(320)]
			)],
			password: [null, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)]
			)]
		});
	}

	submit() {
		if (this.form.invalid) {
			markFormGroupTouched(this.form);
			return;
		}

		this.loading = true;

		const controls = this.form.controls;
		const email = controls.email.value;
		const password = controls.password.value;
		this.auth
			.login(email, password)
			.pipe(
				tap(response => {
					if (response) {
						this.store.dispatch(new Login({ authToken: response.token }));
						this.router.navigateByUrl(this.returnUrl);
					} else {
						this.authNoticeService.setNotice('В процессе входа позникла ошибка.', 'danger');
					}
				}),
				takeUntil(this.unsubscribe),
				finalize(() => {
					this.loading = false;
					this.cdr.markForCheck();
				})
			)
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                })
            )
            .subscribe((user: SignInResponse) => {
                if (user.token) {
                    this.store.dispatch(new Login({ authToken: user.token}));
                    this.router.navigateByUrl(this.returnUrl);
                }
            }, (error: HttpErrorResponse) => {
				if(error.status == 401){
					this.authNoticeService.setNotice('Неверный пароль.', 'danger');
					return;
				}

				if(error.status == 404){
					this.authNoticeService.setNotice('Пользователь не найден.', 'danger');
					return;
				}

                this.authNoticeService.setNotice('В процессе входа позникла ошибка.', 'danger');
            });
	}

	isControlHasError(controlName: string, validationType: string): boolean {
		return isFormGroupControlHasError(this.form, controlName, validationType);
	}
}
