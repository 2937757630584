// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Modules
import { ApiModule } from '../../../api/api.module';
import { CoreModule } from '../../../core/core.module';
import { PartialsModule } from '../../partials/partials.module';
import { MaterialModule } from '../../../common/modules/material.module';
import { ColorPickerModule } from 'ngx-color-picker';
// Services
import {
	HttpUtilsService,
	TypesUtilsService,
	LayoutUtilsService
} from '../../../core/_base/crud';
import {
	ActionNotificationComponent,
	ConfirmationDialogComponent,
	FetchEntityDialogComponent,
	UpdateStatusDialogComponent
} from '../../partials/content/crud';
// Components
import { ServiceListComponent } from './services/service-list/service-list.component';
import { ServiceEditComponent } from './services/service-edit/service-edit.component';

@NgModule({
	declarations: [
		ServiceListComponent,
		ServiceEditComponent
	],
	exports: [],
	imports: [
		RouterModule,
		CommonModule,
		HttpClientModule,
		FormsModule,

		ApiModule,
		CoreModule,
		PartialsModule,

		MaterialModule,
		ColorPickerModule
	],
	providers: [
		TypesUtilsService,
		HttpUtilsService,
		TypesUtilsService,
		LayoutUtilsService
	],
	entryComponents: [
		ActionNotificationComponent,
		ConfirmationDialogComponent,
		FetchEntityDialogComponent,
		UpdateStatusDialogComponent,

		ServiceEditComponent
	]
})
export class ServicesModule {
}
