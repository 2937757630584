import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription, forkJoin } from 'rxjs';

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { MaterialListItem } from '../../../../../api/models/materials';
import { MaterialService, DictionaryService } from '../../../../../api/services';
import { MaterialDataSource } from '../../data-sources';

@Component({
  selector: 'kt-material-search',
  templateUrl: './material-search.component.html',
  styleUrls: ['./material-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialSearchComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MaterialSearchComponent>,
    private materialService: MaterialService,
    private dictionaryService: DictionaryService) {
  }

  private subscriptions: Subscription[] = [];
  private parentId: string = null;
  private languageId: number = null;

  viewLoading = false;

  dataSource: MaterialDataSource;
  displayedColumns = ['name', 'unit', 'actions'];

  units: ListItem[] = [];
  unitId: number = null;
  unitDisabled = false;

  ngOnInit() {
    this.parentId = this.data.parentId;
    this.languageId = this.data.languageId;

    this.dataSource = new MaterialDataSource(this.materialService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);

    this.viewLoading = true;
    if (this.parentId) {
      forkJoin([
        this.dictionaryService.getUnits(),
        this.materialService.getById(this.parentId)
      ]).subscribe(result => {
        this.units = result[0];
        this.unitId = result[1].unitId;
        this.unitDisabled = true;

        this.viewLoading = false;
        this.load();
      });
    } else {
      this.dictionaryService.getUnits()
        .subscribe(units => {
          this.units = units;

          this.viewLoading = false;
          this.load();
        });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      '',
      this.unitId,
      false,
      this.languageId,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  select(item: MaterialListItem) {
    this.dialogRef.close({
      parentId: this.parentId,
      material: {
        id: item.id,
        name: item.name,
        description: item.description,
        unit: item.unit
      }
    });
  }
}
