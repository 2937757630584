import {of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {BaseDataSource} from '../../../../common/data/base-data-source';

import {DocumentListItem} from '../../../../api/models/documents';
import {DocumentService} from '../../../../api/services';
import {PagedResponse} from '../../../../api/models/paged-response.interface';

export class DocumentDataSource extends BaseDataSource<DocumentListItem> {

    constructor(private documentService: DocumentService) {
        super();
    }

    load(searchValue: string,
         searchProjectValue: string,
         documentId: string,
         productId: string,
         projectId: string,
         userId: string,
         userPhoneNumber: string,
         isDeleted: boolean,
         sortField: string,
         sortDocument: string,
         pageIndex: number,
         pageSize: number) {
        this.loadingSubject.next(true);
        this.documentService.get(searchValue, searchProjectValue,
            documentId, productId, projectId, userId,
            userPhoneNumber, isDeleted,
            sortField, sortDocument, pageIndex, pageSize)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response: PagedResponse<DocumentListItem>) => {
                this.paginatorTotalSubject.next(response.total);
                this.itemsSubject.next(response.items);
            });
    }
}
