import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';

import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { DocumentTypeService } from '../../../../../api/services';
import { DocumentTypeDataSource } from '../../data-sources';

import { DocumentTypeEditComponent } from '../document-type-edit/document-type-edit.component';

@Component({
  selector: 'kt-document-type-list',
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTypeListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private documentTypeService: DocumentTypeService) { }

  private subscriptions: Subscription[] = [];

  dataSource: DocumentTypeDataSource;
  displayedColumns = ['name', 'isDeleted', 'created', 'modified', 'actions'];

  isDeleted = 'false';

  ngOnInit() {
    this.dataSource = new DocumentTypeDataSource(this.documentTypeService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }

  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  edit(documentTypeId: number) {
    const dialogRef = this.dialog.open(DocumentTypeEditComponent, { data: { documentTypeId }, width: '600px' });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.layoutUtilsService.showActionNotification('Тип документа обновлён', MessageType.Update, 1000, true, false);
      this.load();
    });
  }
}
