import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import { forkJoin } from 'rxjs';

import {
  hasErrorCode,
  getErrorMessage,
  setFormError,
  markFormGroupTouched,
  isFormGroupControlHasError
} from '../../../../../common/validation/validation-utils'

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';
import { DocumentType } from '../../../../../api/models/document-types';
import { DocumentTypeService, DictionaryService } from '../../../../../api/services';

@Component({
  selector: 'kt-document-type-edit',
  templateUrl: './document-type-edit.component.html',
  styleUrls: ['./document-type-edit.component.scss']
})
export class DocumentTypeEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DocumentTypeEditComponent>,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private documentTypeService: DocumentTypeService,
    private dictionaryService: DictionaryService) {
  }

  private documentTypeId: number;
  private documentType: DocumentType;
  private languages: ListItem[];

  form: FormGroup;
  contents = new FormArray([]);
  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  ngOnInit() {
    this.documentTypeId = this.data.documentTypeId;
    this.createForm();
    this.load();
  }

  createForm() {
    this.form = this.fb.group({
      contents: this.contents
    });
  }

  addContents() {
    this.languages.forEach(language => {
      let content = null;
      if (this.documentType) {
        content = this.documentType.contents.filter(o => o.languageId === language.id)[0];
      }
      this.contents.push(this.fb.group({
        language: [language],
        name: [content ? content.name : '', Validators.compose([
          Validators.required,
          Validators.maxLength(50)]
        )]
      }))
    });
  }

  load() {
    this.viewLoading = true;
    if (this.documentTypeId) {
      forkJoin([
        this.dictionaryService.getLanguages(),
        this.documentTypeService.getById(this.documentTypeId)
      ]).subscribe(result => {
        this.languages = result[0];
        this.documentType = result[1];
        this.addContents();
        this.viewLoading = false;
      });
    }
    else {
      this.dictionaryService.getLanguages()
        .subscribe(languages => {
          this.languages = languages;
          this.addContents();
          this.viewLoading = false;
        });
    }
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    const documentType = this.prepare();
    this.update(documentType);
  }

  private prepare(): DocumentType {
    const contents = [];
    this.contents.controls.forEach((fromGroup: FormGroup) => {
      contents.push({
        languageId: fromGroup.controls.language.value.id,
        name: fromGroup.controls.name.value
      })
    })
    return {
      id: this.documentTypeId,
      contents: contents,
      isDeleted: null,
      created: null,
      modified: null
    };
  }

  private update(documentType: DocumentType) {
    this.viewLoading = true;
    this.documentTypeService.update(documentType)
      .subscribe(
        response => {
          this.viewLoading = false;
          this.dialogRef.close({ documentType, isEdit: true });
        },
        errorResponse => {
          this.viewLoading = false;
          if (hasErrorCode(errorResponse)) {
            this.hasFormErrors = true;
            this.errorMessage = getErrorMessage(errorResponse);
          }
          else {
            setFormError(this.form, errorResponse);
          }
          this.cdr.markForCheck();
        }
      );
  }

  isControlHasError(formGroup: FormGroup, controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(formGroup, controlName, validationType);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.errorMessage = '';
  }
}
