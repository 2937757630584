import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Pretension, PretensionListItem} from '../models/pretensions';
import {PagedResponse} from '../models/paged-response.interface';

const API_URL = 'api/pretensions';

@Injectable()
export class PretensionService {
    constructor(private http: HttpClient) {
    }

    get(
        searchValue: string,
        isDeleted: boolean,
        sortField: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<PretensionListItem>>(API_URL, {params});
    }

    getById(pretensionId: string) {
        return this.http.get<Pretension>(`${API_URL}/${pretensionId}`);
    }
}
