import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

import {
  isFormGroupControlHasError,
  markFormGroupTouched
} from '../../../../../common/validation/validation-utils'

import { ListItem } from '../../../../../api/models/dictionaries/list-item.interface';

@Component({
  selector: 'kt-product-content-edit',
  templateUrl: './product-content-edit.component.html',
  styleUrls: ['./product-content-edit.component.scss']
})
export class ProductContentEditComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProductContentEditComponent>,
    private fb: FormBuilder) {
  }

  language: ListItem;
  title: string;
  description: string;
  form: FormGroup;
  contents = new FormArray([]);
  hasFormErrors = false;
  errorMessage = '';
  viewLoading = false;

  ngOnInit() {
    this.language = this.data.language;
    this.title = this.data.title;
    this.description = this.data.description;
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      title: [this.title, Validators.compose([
        Validators.required,
        Validators.maxLength(200)
      ])],
      description: [this.description, Validators.compose([
        Validators.required,
        Validators.maxLength(1024)
      ])]
    });
  }

  onSubmit() {
    this.hasFormErrors = false;

    if (this.form.invalid) {
      markFormGroupTouched(this.form);
      return;
    }

    const controls = this.form.controls;

    this.dialogRef.close({ title: controls.title.value, description: controls.description.value });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    return isFormGroupControlHasError(this.form, controlName, validationType);
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.errorMessage = '';
  }
}
