import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, merge, Subscription } from 'rxjs';

import { LayoutUtilsService, MessageType } from '../../../../../core/_base/crud';

import { Language } from '../../../../../api/models/languages/language.interface';
import { LanguageService } from '../../../../../api/services';
import { LanguageDataSource } from '../../data-sources';

import { LanguageEditComponent } from '../language-edit/language-edit.component';

@Component({
  selector: 'kt-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageListComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort', { static: true }) sort: MatSort;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private layoutUtilsService: LayoutUtilsService,
    private languageService: LanguageService) { }

  private subscriptions: Subscription[] = [];

  dataSource: LanguageDataSource;
  displayedColumns = ['name', 'isoCode', 'isDeleted', 'created', 'modified', 'actions'];

  isDeleted = 'false';

  ngOnInit() {
    this.dataSource = new LanguageDataSource(this.languageService);

    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.load())
      ).subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.filter();
        })
      ).subscribe();
    this.subscriptions.push(searchSubscription);
  }

  ngAfterViewInit() {
    this.load();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  filter() {
    this.paginator.pageIndex = 0;
    this.load();
  }
  
  load() {
    this.dataSource.load(
      this.searchInput.nativeElement.value,
      this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }

  add() {
    this.edit({
      id: null,
      name: null,
      isoCode: null,
      isDeleted: null,
      created: null,
      modified: null
    });
  }

  edit(language: Language) {
    const saveMessage = language.id ? 'Язык обновлён' : 'Язык добавлен';
    const messageType = language.id ? MessageType.Update : MessageType.Create;
    const dialogRef = this.dialog.open(LanguageEditComponent, { data: { language }, width: '600px' });

    dialogRef.afterClosed().subscribe(res => {
      if (!res) {
        return;
      }
      this.layoutUtilsService.showActionNotification(saveMessage, messageType, 1000, true, false);
      this.load();
    });
  }

  restore(language: Language) {
    this.languageService.restore(language.id)
      .subscribe(
        response => {
          this.layoutUtilsService.showActionNotification('Язык был восстановлен.', MessageType.Update, 3000, true, false);
          this.load();
        },
        error => {
          this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении языка.', MessageType.Update, 3000, true, false);
        }
      );
  }

  delete(language: Language) {
    const dialogRef = this.layoutUtilsService.deleteElement('Удаление', 'Вы действительно хотите удалить язык?', 'Удаление языка...');
    dialogRef.afterClosed()
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.languageService.delete(language.id)
          .subscribe(
            response => {
              this.layoutUtilsService.showActionNotification('Язык был удалён.', MessageType.Delete, 3000, true, false);
              this.load();
            },
            error => {
              this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении языка.', MessageType.Update, 3000, true, false);
            }
          );
      });
  }
}
