import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AdministrativeArea } from '../models/administrative-areas/administrative-area.interface';
import { PagedResponse } from '../models/paged-response.interface';

const API_URL = 'api/administrative-areas';

@Injectable()
export class AdministrativeAreaService {
    constructor(private http: HttpClient) { }

    get(searchValue: string, isDeleted: boolean, sortField: string, sortDirection: string, pageIndex: number, pageSize: number) {
        const params = new HttpParams()
            .set('searchValue', searchValue)
            .set('isDeleted', isDeleted === true ? 'true' : isDeleted === false ? 'false' : '')
            .set('sortField', sortField)
            .set('sortDirection', sortDirection)
            .set('pageIndex', pageIndex.toString())
            .set('pageSize', pageSize.toString());

        return this.http.get<PagedResponse<AdministrativeArea>>(API_URL, { params });
    }

    getById(administrativeAreaId: number) {
        return this.http.get<AdministrativeArea>(`${API_URL}/${administrativeAreaId}`);
    }

    add(administrativeArea: AdministrativeArea) {
        return this.http.post(API_URL, {
            name: administrativeArea.name,
            title: administrativeArea.title,
            countryId: administrativeArea.countryId
        });
    }

    update(administrativeArea: AdministrativeArea) {
        return this.http.put(API_URL, {
            id: administrativeArea.id,
            name: administrativeArea.name,
            title: administrativeArea.title,
            countryId: administrativeArea.countryId,
        });
    }

    restore(administrativeAreaId: number) {
        return this.http.patch(`${API_URL}/${administrativeAreaId}/restore`, {});
    }

    delete(administrativeAreaId: number) {
        return this.http.delete(`${API_URL}/${administrativeAreaId}`);
    }
}
