import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';

import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {fromEvent, merge, Subscription} from 'rxjs';

import {GUID_PATTERN} from '../../../../../common/validation/validation-utils';
import {LayoutUtilsService, MessageType} from '../../../../../core/_base/crud';

import {DocumentService} from '../../../../../api/services';
import {DocumentDataSource} from '../../data-sources';

@Component({
    selector: 'kt-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort', {static: true}) sort: MatSort;
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    @ViewChild('searchProjectInput', {static: true}) searchProjectInput: ElementRef;
    @ViewChild('searchByPhoneNumberInput', {static: true}) searchByPhoneNumberInput: ElementRef;
    dataSource: DocumentDataSource;
    displayedColumns = [
        'id',
        'name',
        'title',
        'date',
        'language',
        'project',
        'product',
        'version',
        'status',
        'user',
        'isEngineering',
        'isDeleted',
        'created',
        'modified',
        'actions'
    ];
    documentId = new FormControl('', Validators.pattern(GUID_PATTERN));
    productId: string = null;
    projectId: string = null;
    userId: string = null;
    isDeleted = 'false';
    private subscriptions: Subscription[] = [];

    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private documentService: DocumentService) {
    }

    ngOnInit() {
        this.dataSource = new DocumentDataSource(this.documentService);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.load())
            ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.filter();
                })
            ).subscribe();
        this.subscriptions.push(searchSubscription);

        const searchProjectInputSubscription = fromEvent(this.searchProjectInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.filter();
                })
            ).subscribe();
        this.subscriptions.push(searchProjectInputSubscription);

        const searchByPhoneNumberSubscription = fromEvent(this.searchByPhoneNumberInput.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    if (this.searchByPhoneNumberInput.nativeElement.value.length >= 5) {
                        this.filter();
                    }
                })
            ).subscribe();
        this.subscriptions.push(searchByPhoneNumberSubscription);

        const documentIdSubscription = this.documentId.valueChanges
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    if (this.documentId.invalid) {
                        this.documentId.markAsTouched();
                        return;
                    }

                    this.filter();
                })
            ).subscribe();
        this.subscriptions.push(documentIdSubscription);
    }

    ngAfterViewInit() {
        this.load();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    filter() {
        this.paginator.pageIndex = 0;
        this.load();
    }

    load() {
        const documentId = this.documentId.valid ? this.documentId.value : '';
        this.dataSource.load(
            this.searchInput.nativeElement.value,
            this.searchProjectInput.nativeElement.value,
            documentId,
            this.productId,
            this.projectId,
            this.userId,
            this.searchByPhoneNumberInput.nativeElement.value,
            this.isDeleted === 'true' ? true : this.isDeleted === 'false' ? false : null,
            this.sort.active,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    downloadPdf(documentId: string) {
        window.open(`/api/printed-documents?documentId=${documentId}`, '_blank');
    }

    downloadContent(documentId: string) {
        window.open(`/api/documents/${documentId}/content`, '_blank');
    }

    restore(documentId: string) {
        this.documentService.restore(documentId)
            .subscribe(
                response => {
                    this.layoutUtilsService.showActionNotification('Документ был восстановлен.', MessageType.Update, 3000, true, false);
                    this.load();
                },
                error => {
                    this.layoutUtilsService.showActionNotification('Произошла ошибка при восстановлении документа.',
                        MessageType.Update, 3000, true, false);
                }
            );
    }

    delete(documentId: string) {
        const dialogRef = this.layoutUtilsService.deleteElement('Удаление',
            'Вы действительно хотите удалить документ?', 'Удаление документа...');
        dialogRef.afterClosed()
            .subscribe(res => {
                if (!res) {
                    return;
                }
                this.documentService.delete(documentId)
                    .subscribe(
                        response => {
                            this.layoutUtilsService.showActionNotification('Документ был удалён.',
                                MessageType.Delete, 3000, true, false);
                            this.load();
                        },
                        error => {
                            this.layoutUtilsService.showActionNotification('Произошла ошибка при удалении документа.',
                                MessageType.Update, 3000, true, false);
                        }
                    );
            });
    }
}
